import { GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_ERROR } from '../Actions';
import { Products } from '../Types/products';
const initialState: Products = {
  isLoading: false,
  errorData: null,
  data: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: action.data,
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
