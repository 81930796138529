import React, { useState } from 'react';
import { getApp } from 'firebase/app';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useSnackbar } from 'notistack';
import { MuiFunction, StyledTextField, styled } from '../../Css';
import { randomId, getFileExtension } from '../../Utils';

const StyledChooseFile = styled.span`
  border: 1px solid #c1c1c1;
  padding: 10px;
  margin-top: 5px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
`;

const StyledSaveFile = styled(MuiFunction.Button)`
  margin-top: 5px;
  float: right;
`;

export interface Props {
  panchyog_patient_id: string;
  getUploadedUrl: (url: string) => void;
}

const Upload = (props: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  // State to store uploaded file
  const [file, setFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');
  // progress
  const [percent, setPercent] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  // Handle file upload event and update state
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files.length) {
      setFile(event.target.files[0]);
    }
  }
  const handleUpload = () => {
    if (!file) {
      return enqueueSnackbar('Please choose an image or pdf first!', {
        variant: 'error',
      });
    }
    if (!fileName) {
      return enqueueSnackbar('Please add report name', {
        variant: 'error',
      });
    }
    if (file && fileName) {
      const extension = getFileExtension(file.name);
      const storageRef = ref(
        getStorage(getApp()),
        `/${props.panchyog_patient_id}/${randomId(10)}_${fileName.trim().replace(/ /g,"-")}.${extension}`,
      );
      // progress can be paused and resumed. It also exposes progress updates.
      // Receives the storage reference and the file to upload.
      setIsLoading(true);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          // update progress
          setPercent(percent);
        },
        (err) => {
          setIsLoading(false);
          return enqueueSnackbar('Something went wrong. Try again', {
            variant: 'error',
          });
        },
        () => {
          setFileName('');
          setIsLoading(false);
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            props.getUploadedUrl(url);
          });
          return enqueueSnackbar('Report file uploaded', {
            variant: 'success',
          });
        },
      );
    }
  };

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileName(event.target.value);
  };

  return (
    <div>
      <MuiFunction.Grid container direction="row" spacing={1}>
        <MuiFunction.Grid item xs>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              accept="image/jpeg,image/jpg,image/png,application/pdf"
              onChange={handleChange}
            />
            <StyledChooseFile>
              <MuiFunction.AttachFileRoundedIcon />
              {'Choose Files'}
            </StyledChooseFile>
          </label>
          <MuiFunction.Typography>{file && file.name ? file.name : ''}</MuiFunction.Typography>
        </MuiFunction.Grid>
        <MuiFunction.Grid item xs>
          <StyledTextField
            label="Report Name"
            id="filled-size-small-name-report"
            variant="filled"
            autoComplete="nope"
            value={fileName}
            onChange={handleFileNameChange}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </MuiFunction.Grid>
        <MuiFunction.Grid item xs>
          <StyledSaveFile
            variant="contained"
            color="secondary"
            onClick={handleUpload}
            disabled={isloading}
          >
            {isloading ? `${percent} % Uploading` : 'Save File'}
          </StyledSaveFile>
        </MuiFunction.Grid>
      </MuiFunction.Grid>
    </div>
  );
};

export default Upload;
