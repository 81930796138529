import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Typography, Toolbar, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { StyledComponentHeader, StyledDiv, StyledPaper, StyledFormControl } from '../Helpers';
import { SaveButton } from '../../Css';
import Constants from '../../Constants';
// selectores
import { patientDetailsForm, getLoginUserData, getPatientProblem } from '../../Stores/Selectors';
import {
  createPatientProblem,
  fetchPatientProblem,
  updatePatientProblem,
} from '../../Stores/Actions';
import { useSnackbar } from 'notistack';

interface LocalState {
  problem_name: string;
  status: string;
  reason: string;
  note: string;
  patient_problem_id?: number;
  patient_id?: number;
  user_id?: number;
  clinic_location_id?: number;
}

const PatientProblem: React.FC = () => {
  const { Config, Problem } = Constants;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);
  const patientProblem = useSelector(getPatientProblem);
  const [getComplaintsKeys, setComplaintsKeys] = useState<string[]>([]);
  const [getValue, setValue] = useState<LocalState>({
    problem_name: '',
    status: Config.Status.OPEN, // default is OPEN
    reason: '',
    note: '',
  });
  const saveInfo = () => {
    // check if no
    if (
      !patientInfo?.patient_id ||
      (!loginUserData?.user_id && !loginUserData?.clinic_location_id)
    ) {
      enqueueSnackbar('Please add (or) select a patient.', {
        variant: 'error',
      });
      return;
    }
    //
    if (getValue.problem_name && !getValue?.patient_problem_id) {
      // create new
      dispatch(
        createPatientProblem({
          problem_name: getValue.problem_name,
          status: getValue.status,
          patient_id: patientInfo?.patient_id,
          user_id: loginUserData?.user_id,
          clinic_location_id: loginUserData?.clinic_location_id,
        }),
      );
    } else if (getValue.problem_name && getValue?.patient_problem_id) {
      // update existing
      if (getValue.status === Config.Status.CLOSED && !getValue.reason) {
        // show error, that select a reason to close.
        enqueueSnackbar('Please select a reason to close.', {
          variant: 'error',
        });
      } else {
        dispatch(
          updatePatientProblem(getValue.patient_problem_id, {
            problem_name: getValue.problem_name,
            status: getValue.status,
            reason: getValue.reason,
            note: getValue.note,
          }),
        );
      }
    }
  };

  const handleChange = (prop: keyof LocalState) => (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setValue((prevState) => ({
      ...prevState,
      [prop]: event.target.value,
    }));
  };

  useEffect(() => {
    setComplaintsKeys(Object.keys(Problem.Complaints));
  }, [Problem]);

  useEffect(() => {
    if (!_.isEmpty(patientProblem.data)) {
      setValue((prevState) => ({
        ...prevState,
        ...patientProblem.data,
      }));
    } else {
      setValue({
        problem_name: '',
        status: Config.Status.OPEN, // default is OPEN
        reason: '',
        note: '',
      });
    }
  }, [patientProblem.data]);

  // default call
  useEffect(() => {
    if (patientInfo?.patient_id && !getValue?.patient_problem_id) {
      dispatch(fetchPatientProblem(patientInfo.patient_id));
    }
  }, [patientInfo]);

  return (
    <StyledDiv>
      <StyledComponentHeader variant="outlined" position="relative">
        <Toolbar style={{ minHeight: '35px' }} variant="dense">
          <Typography component="h2" style={{ flex: 1 }}>
            Problem
          </Typography>
          <SaveButton
            variant="outlined"
            color="primary"
            size="small"
            startIcon={patientProblem.isLoading ? '' : <SaveIcon />}
            onClick={saveInfo}
            disabled={patientProblem.isLoading}
          >
            {patientProblem.isLoading ? 'Saving...' : 'Save'}
          </SaveButton>
        </Toolbar>
      </StyledComponentHeader>
      <StyledPaper variant="outlined">
        <div>
          <Grid container spacing={1}>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-problem">Problem Related</InputLabel>
                <Select
                  labelId="filled-label-problem"
                  id="filled-select-1"
                  value={getValue.problem_name}
                  onChange={handleChange('problem_name')}
                  autoComplete="nope"
                  disableUnderline
                >
                  {getComplaintsKeys.map((item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-status">Status</InputLabel>
                <Select
                  labelId="filled-label-status"
                  id="filled-select-1"
                  value={getValue.problem_name ? getValue.status : ''}
                  onChange={handleChange('status')}
                  autoComplete="nope"
                  disableUnderline
                >
                  <MenuItem value="">
                    <em>{'None'}</em>
                  </MenuItem>
                  <MenuItem value={Config.Status.OPEN}>Open</MenuItem>
                  <MenuItem value={Config.Status.CLOSED}>Closed</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs>
              <StyledFormControl variant="filled" disabled={getValue.status === Config.Status.OPEN}>
                <InputLabel id="filled-label-reason">Reason</InputLabel>
                <Select
                  labelId="filled-label-reason"
                  id="filled-select-1"
                  value={getValue.reason === null ? '' : getValue.reason}
                  onChange={handleChange('reason')}
                  autoComplete="nope"
                  disableUnderline
                >
                  <MenuItem value="">
                    <em>{'None'}</em>
                  </MenuItem>
                  {Config.Reasons.map((item, i) => {
                    return (
                      <MenuItem value={item.key} key={`${item.key}_${i}`}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </StyledFormControl>
            </Grid>
          </Grid>
        </div>
      </StyledPaper>
    </StyledDiv>
  );
};

export default PatientProblem;
