import axios from 'axios';

// post data on api.
export const postData = async (url, payload, token) => {
  // console.log(url, payload, token);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let reqData = await axios.post(url, payload, config);
    // console.log(reqData);
    return reqData.data;
  } catch (e) {
    // send error.
    if (e.response) {
      return e.response.data;
    } else {
      return e;
    }
  }
};

// get Data from API
export const getData = async (url, token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let reqData = await axios.get(url, config);
    // console.log(reqData);
    return reqData.data;
  } catch (e) {
    // send error.
    if (e.response) {
      return e.response.data;
    } else {
      return e;
    }
  }
};

// post data on api.
export const updateData = async (url, payload, token) => {
  // console.log(url, payload, token);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let reqData = await axios.put(url, payload, config);
    // console.log(reqData);
    return reqData.data;
  } catch (e) {
    // send error.
    if (e.response) {
      return e.response.data;
    } else {
      return e;
    }
  }
};

// DELETE DATA
export const deleteData = async (url, payload, token) => {
  // console.log(url, payload, token);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let reqData = await axios.delete(url, payload, config);
    // console.log(reqData);
    return reqData.data;
  } catch (e) {
    // send error.
    if (e.response) {
      return e.response.data;
    } else {
      return e;
    }
  }
};
