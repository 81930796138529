import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Toolbar, Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
//
import {
  StyledComponentHeader,
  StyledDiv,
  StyledPaper,
  StyledFormControl,
  ButtonGrid,
  AddIconButton,
  RemoveIconButton,
} from '../Helpers';

import Constants from '../../Constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPatientDiagnosis,
  deletePatientDiagnosis,
  fetchPatientDiagnosis,
} from '../../Stores/Actions';
import {
  getLoginUserData,
  patientDetailsForm,
  getPatientProblem,
  getPatientDiagnosis,
} from '../../Stores/Selectors';
import { PatientDiagnosisData } from '../../Stores/Types/patientDiagnosis';
import { useSnackbar } from 'notistack';

const StyledDivShowOption = styled.div`
  display: inline-flex;
  background-color: #e8e8e8;
  font-weight: 400;
  border-radius: 4px 4px 0 0;
  width: 100%;
  position: relative;
  height: auto;
  & > div {
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #323232;
  }
`;

interface LocalState {
  system: string;
  symptom: string;
  how_long: number;
  time_unit: string;
  patient_diagnosis_id?: number;
}

const PatientDiagnosis: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);
  const patientProblem = useSelector(getPatientProblem);
  const patientDiagnosis = useSelector(getPatientDiagnosis);

  const [system, setSystem] = useState<string>('');
  const [symptom, setSymptoms] = useState<string>('');
  const [howLong, setHowLong] = useState<string>('');
  const [time_unit, setTime] = useState<string>('');
  // default state
  const [getDiagnosisKeys, setDiagnosisKeys] = useState<string[]>([]);
  const [getDiagnosisData, setDiagnosisData] = useState(Constants.Problem.Diagnoses);
  // selected final state
  const [diagnosisList, setDiagnosisList] = useState<PatientDiagnosisData[]>([]);
  // reset all when system changes
  useEffect(() => {
    setSymptoms('');
    setHowLong('');
    setTime('');
  }, [system]);

  // initially add constatin in local store
  useEffect(() => {
    setDiagnosisKeys(Object.keys(Constants.Problem.Diagnoses));
    setDiagnosisData(Constants.Problem.Diagnoses);
  }, [Constants]);

  useEffect(() => {
    if (patientDiagnosis && patientDiagnosis.data) {
      setDiagnosisList(patientDiagnosis.data);
    }
  }, [patientDiagnosis]);

  useEffect(() => {
    if (patientProblem && patientProblem.data && patientProblem.data?.patient_problem_id) {
      dispatch(fetchPatientDiagnosis(patientProblem.data.patient_problem_id));
    }
  }, [patientProblem]);

  const handleChange = (prop: keyof LocalState) => (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setMultiState(prop, event.target.value);
  };

  const setMultiState = (field: string, value: string | any) => {
    if (field === 'system' && value) {
      setSystem(value);
    } else if (field === 'symptom' && value) {
      setSymptoms(value);
    } else if (field === 'how_long' && value) {
      setHowLong(value);
    } else if (field === 'time_unit' && value) {
      setTime(value);
    }
  };

  const AddToList = () => {
    if (system && symptom && howLong && time_unit && patientProblem.data?.patient_problem_id) {
      // console.log('inside');
      setDiagnosisList([
        ...diagnosisList,
        {
          system: system,
          symptom: symptom,
          how_long: parseInt(howLong),
          time_unit: time_unit,
        },
      ]);
      // dispatch action
      dispatch(
        createPatientDiagnosis({
          system: system,
          symptom: symptom,
          how_long: parseInt(howLong),
          time_unit: time_unit,
          patient_problem_id: patientProblem.data.patient_problem_id,
          patient_id: patientInfo?.patient_id,
          user_id: loginUserData?.user_id,
        }),
      );
      // reset
      setSymptoms('');
      setHowLong('');
      setTime('');
    } else {
      // error
      if (system && symptom && howLong && time_unit) {
        enqueueSnackbar('Please add a system and symptom.', {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Please add a problem first.', {
          variant: 'error',
        });
      }
    }
  };

  //
  const RemoveFromList = (item: PatientDiagnosisData) => {
    if (item?.patient_diagnosis_id) {
      dispatch(deletePatientDiagnosis(item.patient_diagnosis_id));
    }
  };

  return (
    <StyledDiv>
      <StyledComponentHeader variant="outlined" position="relative">
        <Toolbar style={{ minHeight: '35px' }} variant="dense">
          <Typography component="h2" style={{ flex: 1 }}>
            Diagnosis
          </Typography>
        </Toolbar>
      </StyledComponentHeader>
      <StyledPaper variant="outlined">
        <div>
          <Grid container direction="row" spacing={1}>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-system-1">Systems</InputLabel>
                <Select
                  labelId="filled-label-system-1"
                  id="filled-select-1"
                  value={system}
                  onChange={handleChange('system')}
                  autoComplete="nope"
                  disableUnderline
                >
                  {getDiagnosisKeys.map((item) => {
                    return (
                      <MenuItem value={item} key={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-symptom-1">Symptoms</InputLabel>
                <Select
                  labelId="filled-label-symptom-1"
                  id="filled-select-1"
                  value={symptom}
                  onChange={handleChange('symptom')}
                  autoComplete="nope"
                  disableUnderline
                >
                  {getDiagnosisData[system] &&
                    getDiagnosisData[system].map((item) => {
                      return (
                        <MenuItem value={item} key={`w#${item}`}>
                          {item}
                        </MenuItem>
                      );
                    })}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-how-long-1">How Long</InputLabel>
                <Select
                  labelId="filled-label-how-long-1"
                  id="filled-select-1"
                  value={howLong}
                  onChange={handleChange('how_long')}
                  autoComplete="nope"
                  disableUnderline
                >
                  {[...Array(20)].map((e, i) => (
                    <MenuItem key={'how-long' + i} value={i + 1}>
                      {i + 1}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-time_unit-1">Time</InputLabel>
                <Select
                  labelId="filled-label-time_unit-1"
                  id="filled-select-1"
                  value={time_unit}
                  onChange={handleChange('time_unit')}
                  autoComplete="nope"
                  disableUnderline
                >
                  <MenuItem value={'DAY'}>Day</MenuItem>
                  <MenuItem value={'MONTH'}>Month</MenuItem>
                  <MenuItem value={'YEAR'}>Year</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <ButtonGrid item xs>
              <AddIconButton aria-label="add" onClick={AddToList}>
                <AddCircleSharpIcon />
              </AddIconButton>
            </ButtonGrid>
          </Grid>
        </div>
        {/* show selected */}
        {diagnosisList.map((itm, indx) => {
          return (
            <div key={'selected_show_#' + indx}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs>
                  <StyledDivShowOption>
                    <div>{itm.system}</div>
                  </StyledDivShowOption>
                </Grid>
                <Grid item xs>
                  <StyledDivShowOption>
                    <div>{itm.symptom}</div>
                  </StyledDivShowOption>
                </Grid>
                <Grid item xs>
                  <StyledDivShowOption>
                    <div>{itm.how_long}</div>
                  </StyledDivShowOption>
                </Grid>
                <Grid item xs>
                  <StyledDivShowOption>
                    <div>{itm.time_unit}</div>
                  </StyledDivShowOption>
                </Grid>
                <ButtonGrid item xs>
                  <RemoveIconButton aria-label="remove" onClick={() => RemoveFromList(itm)}>
                    <CancelSharpIcon />
                  </RemoveIconButton>
                </ButtonGrid>
              </Grid>
            </div>
          );
        })}
      </StyledPaper>
    </StyledDiv>
  );
};

export default PatientDiagnosis;
