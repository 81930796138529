import styled from 'styled-components';
import {
  AppBar,
  Grid,
  Paper,
  FormControl,
  IconButton,
  TextField,
} from '@material-ui/core';

export const StyledComponentHeader = styled(AppBar)`
  border-radius: 4px 4px 0 0;
  background: #ccebffa1 !important;
  border-bottom: none !important;
  color: #333 !important;
  z-index: 999 !important;
`;

export const StyledDiv = styled.div`
  margin: 10px 0;
`;
export const StyledPaper = styled(Paper)`
  padding: 10px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 100% !important;
  & > div > div {
    padding: 20px 12px 5px !important;
  }
`;

// button Grid
export const ButtonGrid = styled(Grid)`
  max-width: 65px !important;
`;

export const AddIconButton = styled(IconButton)`
  margin: 5px 0 !important;
  color: #52af4f !important;
`;

//
export const RemoveIconButton = styled(IconButton)`
  margin: 0px !important;
  color: #ec7063 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 100% !important;
  & > div > input {
    padding: 20px 12px 5px !important;
  }
`;
