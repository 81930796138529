import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { StyledComponentHeader, StyledDiv, StyledPaper } from '../Helpers';
import { styled, MuiFunction, TableSearchDiv, TableSearchIconDiv, TableSearchInputBase } from '../../Css';
import {Patient} from '../../Stores/Types/patient'

const StyledTableRow = styled(MuiFunction.TableRow)`
  & > :hover {
    background-color: #dfdfdf;
    cursor: pointer;
  }
`;

export interface Props {
  patientDataList: Patient[];
  showDetails: (patientData: Patient) => void;
  filterData: (searchKey: string|null) => void;
}

export default function ViewPatientList(props: Props): JSX.Element {
  const [getPatientList, setPatientList] = useState<Patient[]>([]);
  const [getSearchKeyword, setSearchKeyword] = useState<string|null>(null);

  const handleClick = (patientData: Patient) => {
    props.showDetails(patientData);
  };
  const filterData = () => props.filterData(getSearchKeyword);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      return filterData();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  useEffect(() => {
    const data = _.orderBy(props.patientDataList, (item) => item.patient_id, ['desc']);
    setPatientList(data);
  }, [props.patientDataList]);

  return (
    <React.Fragment>
      <StyledDiv>
        <StyledComponentHeader variant="outlined" position="relative">
          <MuiFunction.Toolbar style={{ minHeight: '35px' }} variant="dense">
            <MuiFunction.Typography component="h2" style={{ flex: 1 }}>
             {'Patients List'}
            </MuiFunction.Typography>
            <TableSearchDiv>
              <TableSearchInputBase 
                id='tableSearchInputBasePatient'
                value={getSearchKeyword || ''}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder="PA04657 (or) Name"
                inputProps={{ 'aria-label': 'search' }}
              />
              <TableSearchIconDiv onClick={() => filterData()}>
                <MuiFunction.SearchIcon />
              </TableSearchIconDiv>
            </TableSearchDiv>
          </MuiFunction.Toolbar>
        </StyledComponentHeader>
        <StyledPaper variant="outlined">
          <div>
            <MuiFunction.TableContainer component={MuiFunction.Paper}>
              <MuiFunction.Table aria-label="simple table">
                <MuiFunction.TableHead>
                  <MuiFunction.TableRow>
                    <MuiFunction.TableCell>
                      <strong>PatientID</strong>
                    </MuiFunction.TableCell>
                    <MuiFunction.TableCell align="left">
                      <strong>Name</strong>
                    </MuiFunction.TableCell>
                    <MuiFunction.TableCell align="left">
                      <strong>Gender</strong>
                    </MuiFunction.TableCell>
                    <MuiFunction.TableCell align="left">
                      <strong>Mobile</strong>
                    </MuiFunction.TableCell>
                    <MuiFunction.TableCell align="left">
                      <strong>Location</strong>
                    </MuiFunction.TableCell>
                    <MuiFunction.TableCell align="left">
                      <strong>JoinDate</strong>
                    </MuiFunction.TableCell>
                  </MuiFunction.TableRow>
                </MuiFunction.TableHead>
                <MuiFunction.TableBody>
                  {getPatientList.map((patientItem) => (
                    <StyledTableRow
                      key={patientItem.panchyog_id}
                      onClick={() => handleClick(patientItem)}
                    >
                      <MuiFunction.TableCell component="th" scope="row">
                        {patientItem.panchyog_id}
                      </MuiFunction.TableCell>
                      <MuiFunction.TableCell align="left">{patientItem.name}</MuiFunction.TableCell>
                      <MuiFunction.TableCell align="left">
                        {patientItem.gender}
                      </MuiFunction.TableCell>
                      <MuiFunction.TableCell align="left">
                        {patientItem.mobile.toString()}
                      </MuiFunction.TableCell>
                      <MuiFunction.TableCell align="left">
                        {patientItem.location_name}
                      </MuiFunction.TableCell>
                      <MuiFunction.TableCell align="left">
                        {moment(patientItem.created_datetime).format('DD/MM/YYYY')}
                      </MuiFunction.TableCell>
                    </StyledTableRow>
                  ))}
                </MuiFunction.TableBody>
              </MuiFunction.Table>
            </MuiFunction.TableContainer>
          </div>
        </StyledPaper>
      </StyledDiv>
    </React.Fragment>
  );
}
