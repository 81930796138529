import React from 'react';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useDispatch } from 'react-redux';
import { addNewPatient, setNavigation } from '../../Stores/Actions';
import Constants from '../../Constants';

export default function CreateNew(): JSX.Element {
  const dispatch = useDispatch();
  const addNew = () => {
    dispatch(addNewPatient());
    dispatch(setNavigation(Constants.Config.Navigation.DIAGNOSIS));
  };
  return (
    <div style={{ flex: 1 }}>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={addNew}
        startIcon={<AddIcon />}
      >
        Add New
      </Button>
    </div>
  );
}
