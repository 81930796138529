import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addCallbackReminder } from '../../Stores/Actions';
import {
  getLoginUserData,
  patientDetailsForm,
} from '../../Stores/Selectors';

import { styled, MuiFunction } from '../../Css';

const StyledDialog = styled(MuiFunction.Dialog)`
  & > div > div {
    position: absolute;
    top: 50px;
    bottom: 30px;
  }
`;

const StyledMuiDialogTitle = styled(MuiFunction.MuiDialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDialogContent = styled(MuiFunction.MuiDialogContent)`
  padding: 10px !important;
`;
const StyledDialogActions = styled(MuiFunction.MuiDialogActions)`
  margin: 0px !important;
  padding: 10px !important;
`;

export interface DialogTitleI {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleI): JSX.Element => {
  return (
    <StyledMuiDialogTitle disableTypography>
      <MuiFunction.Typography variant="h6">{props.children}</MuiFunction.Typography>
      {props.onClose ? (
        <MuiFunction.IconButton aria-label="close" onClick={props.onClose}>
          <MuiFunction.CloseIcon />
        </MuiFunction.IconButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};

export interface CreatePatientCallbackDialogI {
  openDialog: boolean;
  onCloseDialog: () => void;
}

const CreatePatientCallbacks = (props: CreatePatientCallbackDialogI): JSX.Element => {
  const now = moment().format('YYYY-MM-DD hh:mm').toString();
  const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);
  const [dateTime, setDateTime] = useState(now);
  const [remarks, setRemarks] = useState<string>('');
  const handleClose = () => {
    // setOpen(false);
    return props.onCloseDialog();
  };

  const handleSave = () => {
    // save and close popup
    const payload = {
      callback_datetime: dateTime,
      patient_id: patientInfo?.patient_id,
      user_id: loginUserData?.user_id,
      remarks: remarks===''? null : remarks
    }
    console.log(payload);
    dispatch(addCallbackReminder(payload));
    return props.onCloseDialog();
  };

  const handleChangeDate = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const utcDateTime = moment(event.target.value).utc().format().toString();
      setDateTime(utcDateTime);
    } catch (error) {
      // show error : 'Enter a valid input'
    }
  };

  const handleChangeRemarks = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setRemarks(event.target.value);
    } catch (error) {
      // show error : 'Enter a valid input'
    }
  };

  // useEffect(() => {
  //   setOpen(props.openDialog);
  // }, [props.openDialog]);

  return (
    <StyledDialog
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={props.openDialog}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Set a callback reminder
      </DialogTitle>
      <StyledDialogContent dividers>
        <MuiFunction.Grid container direction="row" spacing={1}>
          <MuiFunction.Grid item>
            <MuiFunction.FormControl component="fieldset">
              <MuiFunction.TextField
                id="callback-datetime-local"
                label="Next Call Reminder"
                type="datetime-local"
                defaultValue={now}
                onChange={handleChangeDate()}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </MuiFunction.FormControl>
          </MuiFunction.Grid>
        </MuiFunction.Grid>
        <MuiFunction.Grid container direction="row" spacing={1}>
          <MuiFunction.Grid item>
            <MuiFunction.FormControl component="fieldset">
              <MuiFunction.TextField
                id="callback-remarks"
                label="Remarks"
                rowsMax={5}
                defaultValue={remarks}
                onChange={handleChangeRemarks()}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
              />
            </MuiFunction.FormControl>
          </MuiFunction.Grid>
        </MuiFunction.Grid>
  
      </StyledDialogContent>
      <StyledDialogActions>
        <MuiFunction.Button onClick={handleSave} variant="contained" color="secondary">
          Save
        </MuiFunction.Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default CreatePatientCallbacks;
