import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Drawer, AppBar, Tabs, Tab, TextField } from '@material-ui/core';
import { PatientNotesData } from '../../Stores/Types/notes';
import {
  getNotes,
  getLoginUserData,
  patientDetailsForm,
  getPatientProblem,
} from '../../Stores/Selectors';
import { createNotes, fetchNotes } from '../../Stores/Actions';
import _ from 'lodash';
//
const StyledDrawerRight = styled(Drawer)`
  & > div {
    flex-shrink: 0 !important;
    width: 320px !important;
    overflow: hidden !important;
  }
`;

const StyledScrollDiv = styled.div`
  overflow-y: auto;
  /* height: 100vh; */
  height: calc(100vh - 160px);
  padding: 10px;
  line-height: 1.5;
`;

//
const StyledTextField = styled(TextField)`
  width: 95%;
  margin: 8px !important;
  & > div {
    padding: 10px !important;
  }
`;

const StyledSideAppBar = styled(AppBar)`
  background-color: #028ce1 !important;
`;

const StyledChatChatBubbleDiv = styled.div`
  padding: 5px;
  border-radius: 0 10px 10px;
  margin: 5px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
`;
const StyledChatChatBubbleHeaderDiv = styled.div`
  font-size: 10px;
  color: #5c5c5c;
  font-weight: 600;
  letter-spacing: 0.7px;
  font-style: italic;
`;
const StyledChatChatBubbleHeaderSpanRight = styled.span`
  float: right;
`;
const StyledChatChatBubbleTextDiv = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: #333;
  text-align: left;
  letter-spacing: 0.002rem;
`;

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);
  const patientProblem = useSelector(getPatientProblem);

  const [chatData, setChatData] = React.useState<string>('');
  const [notesData, setNotesData] = React.useState<string>('');

  const handleNotesChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotesData(event.target.value);
  };

  const handleChatsChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChatData(event.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (
        loginUserData &&
        loginUserData.user_id &&
        patientInfo &&
        patientInfo.patient_id &&
        patientProblem.data &&
        patientProblem.data.patient_problem_id
      ) {
        dispatch(
          createNotes({
            patient_problem_id: patientProblem.data.patient_problem_id,
            patient_id: patientInfo.patient_id,
            user_id: loginUserData.user_id,
            user_full_name: loginUserData.first_name + ' ' + loginUserData.last_name,
            notes: notesData,
          }),
        );
        setNotesData('');
      }
    }
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {/* Notes */}
      {value === 0 && (
        <React.Fragment>
          {children}
          <div>
            <StyledTextField
              id="outlined-multiline-static"
              placeholder="Notes"
              multiline
              rows={3}
              variant="outlined"
              value={notesData}
              onKeyPress={handleKeyPress}
              onChange={handleNotesChanges}
            />
          </div>
        </React.Fragment>
      )}
      {/* chats */}
      {value === 1 && (
        <React.Fragment>
          {children}
          <div>
            <StyledTextField
              id="outlined-multiline-static"
              placeholder="Chats"
              multiline
              rows={3}
              variant="outlined"
              value={chatData}
              onChange={handleChatsChanges}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Right() {
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const patientProblem = useSelector(getPatientProblem);
  const notesData = useSelector(getNotes);

  const [notesValue, setNotesValue] = React.useState<PatientNotesData[]>([]);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };

  const convertDatime = (datetime: any) => {
    let date = '';
    if (datetime) {
      date = new Date(datetime).toLocaleDateString('en-GB', {
        month: 'numeric',
        year: 'numeric',
        day: 'numeric',
      });
    }
    return date;
  };

  useEffect(() => {
    if (notesData.data && notesData.data.length) {
      const notesDataCopy = _.cloneDeep(notesData.data);
      const newCombinedNotesList: PatientNotesData[] = [];
      let userId = notesDataCopy[0].user_id;
      let date = convertDatime(notesDataCopy[0].created_datetime);
      let notes = '';
      for (const item of notesDataCopy) {
        const created_datetime = convertDatime(item.created_datetime);
        if (userId === item.user_id && date === created_datetime) {
          notes = `${notes} ${item.notes}`;
          item.notes = notes;
          if (newCombinedNotesList.length===0) {
            newCombinedNotesList.push(item);
          } else {
            const lastIndex = newCombinedNotesList.length - 1;
            newCombinedNotesList[lastIndex] = item;
          }
        } else {
          userId = item.user_id;
          date = created_datetime;
          notes = item.notes;
          newCombinedNotesList.push(item);
        } 
      }
      setNotesValue(newCombinedNotesList);
    }
  }, [notesData.data]);

  useEffect(() => {
    if (patientProblem.data && patientProblem.data?.patient_problem_id) {
      dispatch(fetchNotes(patientProblem.data.patient_problem_id));
    }
  }, [patientProblem.data]);

  return (
    <StyledDrawerRight variant="permanent" anchor="right">
      <StyledSideAppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Notes" {...a11yProps(0)} />
          <Tab label="Chats" {...a11yProps(1)} />
        </Tabs>
      </StyledSideAppBar>
      <TabPanel value={value} index={0}>
        <StyledScrollDiv>
          {notesValue.map((item, indx) => {
            return (
              <StyledChatChatBubbleDiv key={`notes-${indx}`}>
                <StyledChatChatBubbleHeaderDiv>
                  <span>{convertDatime(item.created_datetime)}</span>
                  <StyledChatChatBubbleHeaderSpanRight>
                    {`by Dr. ${item.user_full_name}`}
                  </StyledChatChatBubbleHeaderSpanRight>
                </StyledChatChatBubbleHeaderDiv>
                <StyledChatChatBubbleTextDiv>{item.notes}</StyledChatChatBubbleTextDiv>
              </StyledChatChatBubbleDiv>
            );
          })}
        </StyledScrollDiv>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Coming soon...
      </TabPanel>
    </StyledDrawerRight>
  );
}
