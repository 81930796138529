// import types
import { PatientDetails } from '../Types/patientDetails';
import {
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  CREATE_PATIENT_ERROR,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_ERROR,
  CREATE_PATIENT_GD_REQUEST,
  CREATE_PATIENT_GD_SUCCESS,
  CREATE_PATIENT_GD_ERROR,
  ADD_NEW,
} from '../Actions';

//
const initialState: PatientDetails = {
  isLoading: false,
  errorData: null,
  data: {},
};
//
export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: {},
      };
    case CREATE_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PATIENT_SUCCESS:
      // console.log(action.data);
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: action.data,
      };
    case CREATE_PATIENT_ERROR:
      // console.log(action.error);
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    case UPDATE_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: action.data,
      };
    case UPDATE_PATIENT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    case CREATE_PATIENT_GD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PATIENT_GD_SUCCESS:
      // const updatedData = state.patientDetails?.data?.general_details;
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: action.data,
      };
    case CREATE_PATIENT_GD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
