import {
  CREATE_NOTES_REQUEST,
  CREATE_NOTES_SUCCESS,
  CREATE_NOTES_ERROR,
  ADD_NEW,
} from '../Actions';

import { PatientNotes } from '../Types/notes';
import _ from 'lodash';

const initialState: PatientNotes = {
  isLoading: false,
  errorData: null,
  data: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: [],
      };
    case CREATE_NOTES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_NOTES_SUCCESS:
      const data = _.isArray(action.data) ? action.data : [...state.data, action.data];
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data,
      };
    case CREATE_NOTES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
