import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from './Stores/Selectors';
import { fetchProducts } from './Stores/Actions';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function App(): JSX.Element {
  const dispatch = useDispatch();
  const auth = getAuth();
  let isUserLoggedIn = useSelector(isLoggedIn);
  // verify with Firebase login.

  // fetch static data for application.
  if (isUserLoggedIn) dispatch(fetchProducts());
  // disable all logs in production

  useEffect(() => {
    const AuthCheck = async () => {
      onAuthStateChanged(auth, (user) => {
        if (!user) {
          isUserLoggedIn = false;
        }
      });
    }
    AuthCheck();
  }, [auth]);

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() =>
          // If loggedin show dashboard
          !isUserLoggedIn ? <Login /> : <Redirect to="/dashboard" />
        }
      />
      <Route
        path="/dashboard"
        render={() =>
          //
          !isUserLoggedIn ? <Redirect to="/" /> : <Dashboard />
        }
      />
    </Switch>
  );
}

export default App;
