import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  Toolbar,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  StyledComponentHeader,
  StyledDiv,
  StyledPaper,
  StyledTextField,
} from '../Helpers';
import { PatientTreatmentHistoryData } from '../../Stores/Types/patientTreatmentHistory';
import {
  getLoginUserData,
  patientDetailsForm,
  getPatientProblem,
  getPatientHistory,
} from '../../Stores/Selectors';
import {
  createPatientHistory,
  fetchPatientHistory,
  updatePatientHistory,
} from '../../Stores/Actions';

const PatientHistory: React.FC = () => {
  const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);
  const patientProblem = useSelector(getPatientProblem);
  const patientHistory = useSelector(getPatientHistory);

  const [getValue, setValue] = useState<PatientTreatmentHistoryData>({
    allopathic: false,
    surgery: false,
    ayurvedic: false,
    homeopathic: false,
    unani: false,
    remark: '',
  });

  const [getChanges, setChanges] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? event.target.value : event.target.checked;
    setValue({ ...getValue, [event.target.name]: value });
    //
    setChanges(Math.random().toString().substr(2, 5));
  };

  const saveData = () => {
    if (
      patientProblem.data?.patient_problem_id &&
      patientInfo?.patient_id &&
      loginUserData?.user_id
    ) {
      if (patientHistory.data && patientHistory.data?.patient_treatment_history_id) {
        // update same
        dispatch(
          updatePatientHistory(patientHistory.data.patient_treatment_history_id, {
            allopathic: getValue.allopathic,
            surgery: getValue.surgery,
            ayurvedic: getValue.ayurvedic,
            homeopathic: getValue.homeopathic,
            unani: getValue.unani,
            remark: !getValue.remark ? '' : getValue.remark,
          }),
        );
      } else {
        // create new
        dispatch(
          createPatientHistory({
            allopathic: getValue.allopathic,
            surgery: getValue.surgery,
            ayurvedic: getValue.ayurvedic,
            homeopathic: getValue.homeopathic,
            unani: getValue.unani,
            remark: !getValue.remark ? '' : getValue.remark,
            patient_problem_id: patientProblem.data.patient_problem_id,
            patient_id: patientInfo.patient_id,
            user_id: loginUserData.user_id,
          }),
        );
      }
    }
  };

  // dispatch action to save data on server with 500ms timeout.
  useEffect(() => {
    if (getChanges === '') return;
    const timeout = setTimeout(() => {
      saveData();
    }, 1000);
    // distroy previous timeout if user is typing...
    return () => {
      clearTimeout(timeout);
    };
  }, [getChanges]);

  useEffect(() => {
    if (patientHistory.data) {
      setValue(patientHistory.data);
    }
  }, [patientHistory.data]);

  useEffect(() => {
    if (patientProblem && patientProblem.data && patientProblem.data?.patient_problem_id) {
      dispatch(fetchPatientHistory(patientProblem.data.patient_problem_id));
    }
  }, [patientProblem]);

  return (
    <StyledDiv>
      <StyledComponentHeader variant="outlined" position="relative">
        <Toolbar style={{ minHeight: '35px' }} variant="dense">
          <Typography component="h2" style={{ flex: 1 }}>
            History
          </Typography>
        </Toolbar>
      </StyledComponentHeader>
      <StyledPaper variant="outlined">
        {/* <div>
          <Grid container spacing={1}>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-gender">Gender</InputLabel>
                <Select
                  labelId="filled-label-gender"
                  id="filled-select-1"
                  value={'Male'}
                  // onChange={handleChange}
                  autoComplete="nope"
                  disableUnderline
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-gender">Gender</InputLabel>
                <Select
                  labelId="filled-label-gender"
                  id="filled-select-1"
                  value={'Male'}
                  // onChange={handleChange}
                  autoComplete="nope"
                  disableUnderline
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs>
              <StyledFormControl variant="filled">
                <InputLabel id="filled-label-gender">Gender</InputLabel>
                <Select
                  labelId="filled-label-gender"
                  id="filled-select-1"
                  value={'Male'}
                  // onChange={handleChange}
                  autoComplete="nope"
                  disableUnderline
                >
                  <MenuItem value={'Male'}>Male</MenuItem>
                  <MenuItem value={'Female'}>Female</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <ButtonGrid item xs>
              <AddIconButton aria-label="add">
                <AddCircleSharpIcon />
              </AddIconButton>
            </ButtonGrid>
          </Grid>
        </div> */}
        <Typography component="h2">Past Treatment</Typography>
        <div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue.allopathic ? true : false}
                  onChange={handleChange}
                  name="allopathic"
                />
              }
              label="Allopathic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue.surgery ? true : false}
                  onChange={handleChange}
                  name="surgery"
                />
              }
              label="Surgery"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue.ayurvedic ? true : false}
                  onChange={handleChange}
                  name="ayurvedic"
                />
              }
              label="Ayurvedic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue.homeopathic ? true : false}
                  onChange={handleChange}
                  name="homeopathic"
                />
              }
              label="Homeopathic"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={getValue.unani ? true : false}
                  onChange={handleChange}
                  name="unani"
                />
              }
              label="Unani"
            />
          </FormGroup>
          <StyledTextField
            id="filled-multiline-static"
            placeholder="Remark"
            multiline
            rows={2}
            variant="outlined"
            value={getValue.remark ? getValue.remark : ''}
            onChange={handleChange}
            name="remark"
          />
        </div>
      </StyledPaper>
    </StyledDiv>
  );
};

export default PatientHistory;
