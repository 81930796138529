import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../Header';
import LeftSideBar from '../SideBar/left';
import RightSideBar from '../SideBar/right';
import Main from '../Main';
import { ViewAllOrders, PatientOrders } from '../Orders';
import { navigation } from '../../Stores/Selectors';
import Constants from '../../Constants';
import { ViewAllPatients } from '../Patients';
import Reports from '../Reports';
import { ViewAllCallbacks } from '../PatientCallbacks';

const StyledRoot = styled.div`
  display: flex;
`;

export default function Dashboard(): JSX.Element {
  const [getNav, setNav] = useState<string>(Constants.Config.Navigation.DIAGNOSIS);
  const nav = useSelector(navigation);
  useEffect(() => {
    if (nav) setNav(nav);
  }, [nav]);
  //
  return (
    <StyledRoot>
      <Header />
      <LeftSideBar />
      {/* set navigation */}
      {(() => {
        switch (getNav) {
          case Constants.Config.Navigation.DIAGNOSIS:
            return <Main />;
          case Constants.Config.Navigation.ORDERS:
            return <PatientOrders />;
          case Constants.Config.Navigation.ORDERS_LIST:
            return <ViewAllOrders />;
          case Constants.Config.Navigation.PATIENT_LIST:
            return <ViewAllPatients />;
          case Constants.Config.Navigation.TEST_REPORTS:
            return <Reports />;
          case Constants.Config.Navigation.CALLBACK_REQUEST:
            return <ViewAllCallbacks />
          default:
            return null;
        }
      })()}
      <RightSideBar />
    </StyledRoot>
  );
}
