import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getPatientAddress } from '../../Stores/Actions';
import { getAddressDetails } from '../../Stores/Selectors';
import AddAddress from './AddAddress';
import AddressList from './AddressList';
import { PatientAddressData } from '../../Stores/Types/patientAddress';

const StyledDivider = styled(Divider)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;
// interfaces
interface Props {
  patient_id: number;
  user_id: number;
  selectedAddress: (address: PatientAddressData) => void | undefined;
}
// Custom Components
export default function Address(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const patientAddressList = useSelector(getAddressDetails);
  const [addAdress, setAddAddress] = useState<boolean>(false);
  useEffect(() => {
    dispatch(getPatientAddress(props.patient_id));
  }, [props.patient_id]);
  return (
    <React.Fragment>
      <AddressList addressList={patientAddressList.data} selectedAddress={props.selectedAddress} />
      <StyledDivider />
      <Button variant="outlined" color="primary" onClick={() => setAddAddress(!addAdress)}>
        {'Add New Address'}
      </Button>
      {addAdress ? <AddAddress patient_id={props.patient_id} user_id={props.user_id} /> : null}
    </React.Fragment>
  );
}
