import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { TextField, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Constants from '../../Constants';
import { savePatientAddress } from '../../Stores/Actions';
// Custom Components
const StyledTextField = styled(TextField)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 100% !important;
  & > div > input {
    padding: 20px 12px 5px !important;
  }
  & > div > div {
    padding: 20px 12px 5px !important;
  }
  & > div > input[type=number]{
    '-moz-appearance': 'textfield';
  }
  & > div > input[type=number]::-webkit-outer-spin-button {
      '-webkit-appearance': 'none';
      margin: 0;
  }
  & > div > input[type=number]::-webkit-inner-spin-button {
      '-webkit-appearance': 'none';
      margin: 0;
  }
`;

interface LocalState {
  name?: string;
  pin_code?: string;
  landmark?: string;
  state?: string;
  district?: string;
  address?: string;
  country_code: string;
  mobile?: string;
  mobile2?: string;
  tag: string;
}

interface Props {
  patient_id: number;
  user_id: number;
}

export default function AddAddress(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const [addressValues, setValues] = useState<LocalState>({
    country_code: 'IN',
    tag: Constants.Config.AddressTags.HOME,
  });
  // handle input changes
  const handleChange = (prop: keyof LocalState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // validate input value
    const value = event.target.value;
    // set values
    setValues({ ...addressValues, [prop]: value });
  };
  const saveAddress = () => {
    let isOk = true;

    _.each(addressValues, (value, key) => {
      if (key !== 'mobile2' && (!value || value === '')) {
        // show error
        isOk = false;
      }
    });
    if (
      isOk &&
      addressValues.name &&
      addressValues.pin_code &&
      addressValues.pin_code &&
      addressValues.landmark &&
      addressValues.state &&
      addressValues.district &&
      addressValues.address &&
      addressValues.mobile
    ) {
      const mobile = [addressValues.mobile];
      if (addressValues.mobile2) mobile.push(addressValues.mobile2);
      const addressObj = {
        patient_id: props.patient_id,
        user_id: props.user_id,
        name: addressValues.name,
        pin_code: addressValues.pin_code,
        landmark: addressValues.landmark,
        state: addressValues.state,
        district: addressValues.district,
        address: addressValues.address,
        country_code: addressValues.country_code,
        mobile: mobile,
        tag: addressValues.tag,
      };
      dispatch(savePatientAddress(addressObj));
    }
  };
  return (
    <React.Fragment>
      <StyledTextField
        label="Name"
        id="filled-size-small-name-1"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.name ? addressValues.name : ''}
        onChange={handleChange('name')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <StyledTextField
        label="Mobile"
        id="filled-size-small-mobile-1"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.mobile ? addressValues.mobile : ''}
        onChange={handleChange('mobile')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <StyledTextField
        label="Mobile"
        id="filled-size-small-mobile-2"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.mobile2 ? addressValues.mobile2 : ''}
        onChange={handleChange('mobile2')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <StyledTextField
        label="Pincode"
        id="filled-size-small-pin_code-1"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.pin_code ? addressValues.pin_code : ''}
        onChange={handleChange('pin_code')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <StyledTextField
        label="Address"
        id="filled-size-small-Address-1"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.address ? addressValues.address : ''}
        onChange={handleChange('address')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <StyledTextField
        label="Landmark"
        id="filled-size-small-landmark-1"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.landmark ? addressValues.landmark : ''}
        onChange={handleChange('landmark')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <StyledTextField
        label="District"
        id="filled-size-small-district-1"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.district ? addressValues.district : ''}
        onChange={handleChange('district')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <StyledTextField
        label="State"
        id="filled-size-small-state-1"
        variant="filled"
        autoComplete="nope"
        value={addressValues && addressValues.state ? addressValues.state : ''}
        onChange={handleChange('state')}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <Button variant="contained" color="primary" onClick={saveAddress}>
        Save Address
      </Button>
    </React.Fragment>
  );
}
