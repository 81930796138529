import _ from 'lodash';
import { firebaseGetIdToken } from '../Services/firebase';
import { GeneralDetail } from '../Stores/Types/patientDetails';

export const BMI = (weight: number, ft: number, inch: number) => {
  /**
   * weight in KG
   * ft is Feet
   * inch is Inch
   */
  // convert height in meter
  const height = (ft * 12 + inch) * 0.0254; // 1 inch = 0.0254 m
  // calculate BMI
  let bmi = weight / (height * height); // .toFixed(2);
  bmi = _.round(bmi, 2);
  // console.log(bmi);
  if (bmi < 18.5) {
    return { bmi: bmi, text: 'Underweight' };
  } else if (bmi < 25) {
    return { bmi: bmi, text: 'Normal' };
  } else if (bmi < 30) {
    return { bmi: bmi, text: 'Overweight' };
  } else {
    return { bmi: bmi, text: 'Obese' };
  }
};

//
export const getLastItem = (array: GeneralDetail[]) => {
  return array[array.length - 1];
};

export const stringToBoolean = (str: string): boolean => {
  return str === 'true' ? true : false;
};

export const findDiffObject = (sourceObj: any, targetObj: any): any => {
  const updatedObj: any = {};
  _.forEach(sourceObj, (value, key) => {
    if (targetObj[key] !== value) {
      updatedObj[key] = value;
    }
  });
  return updatedObj;
};

export const localTokenSet = (token: string) => {
  sessionStorage.setItem('tkn', token);
  const parsedToken = parseJwt(token);
  if (parsedToken && parsedToken.exp) {
    const tokenTime = new Date(parsedToken.exp * 1000);
    const nowTime = new Date();
    const diff = tokenTime.getTime() - nowTime.getTime();
    const timeOutTime = diff - 300000; // all in MS
    setTimeout(localTokenGet, timeOutTime); // generate new token before 5 minto expire.
  }
};
export const localTokenGet = async () => {
  let token = sessionStorage.getItem('tkn');
  if (!token) {
    const newToken = await firebaseGetIdToken();
    if (!newToken) return null;
    localTokenSet(newToken);
    token = newToken;
  }
  const parsedToken = parseJwt(token);
  if (parsedToken && parsedToken.exp) {
    let time = parsedToken.exp * 1000 - Date.now();
    time = time / 1000 / 60;
    if (time <= 6) {
      const newToken = await firebaseGetIdToken();
      if (!newToken) return null;
      localTokenSet(newToken);
      token = newToken;
      console.log('NewToken');
    }
  }
  return token;
};
export const localTokenClear = () => {
  return sessionStorage.removeItem('tkn');
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const randomId = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const getFileExtension = (filename: string) => {
  try {
    const reg = /(?:\.([^.]+))?$/;
    const regX = reg.exec(filename);
    return regX ? regX[1] : undefined;
  } catch (error) {
    return undefined;
  }
};
