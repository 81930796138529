import Constants from '../../Constants';
import { NAVIGATION } from '../Actions';

//
const initialState: string = Constants.Config.Navigation.DASHBOARD; // default is dignosis

export default (state = initialState, action: any) => {
  switch (action.type) {
    case NAVIGATION:
      return action?.value;
    default:
      // console.log(state);
      return state;
  }
};

// export default loggedReducer;
