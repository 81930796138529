import React, { useState, useEffect } from 'react';
import {
  Typography,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
} from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import { StyledMainContent, MuiFunction } from '../../Css';
import { getData, updateData } from '../../Services/server';
import { localTokenGet } from '../../Utils';
import { StyledComponentHeader, StyledDiv, StyledPaper } from '../Helpers';

const StyledTableRow = styled(TableRow)`
  & > :hover {
    background-color: #dfdfdf;
    cursor: pointer;
  }
`;

export interface Result {
    data: Callback[]
  }
  
  export interface Callback {
    patient_callback_id: number
    user_id: number
    patient_id: number
    remarks?: string
    callback_datetime: string
    is_active: boolean
    is_deleted: boolean
    created_datetime: string
    updated_datetime: string
    patient: Patient
  }
  
  export interface Patient {
    patient_id: number
    panchyog_id: string
    name: string
    mobile: string[]
    gender: string
  }
  

export default function ViewAllCallbacks(): JSX.Element {
  const [getCurrentPage, setCurrentPage] = useState<number>(1);
  const [getResultCount, setResultCount] = useState<number>(0);
  const [getCallbackResult, setCallbackResult] = useState<Callback[]>([]);
  const [getPerPage, setPerPage] = useState<number>(0);
  
  const getCallbackData = async () => {
    const token = await localTokenGet();
    getData(`${process.env.REACT_APP_API_BASEURL}/api/v1/patient/callbacks/fetch`, token)
      .then((res: Result) => {
        setPerPage(res.data.length)
        setCallbackResult(res.data);
      })
      .catch((_err: any) => {
        setCurrentPage(1);
        setResultCount(0);
        setCallbackResult([]);
      });
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const saveStatus = async (callbackId: number) => { 
    const payload = {
        call_responded: true
    }
    const token = await localTokenGet();
    updateData(`${process.env.REACT_APP_API_BASEURL}/api/v1/patient/callbacks/update/${callbackId}`, payload, token)
      .then((res: any) => {
        getCallbackData();
        console.log('updated')
      })
      .catch((_err: any) => {
        console.log('Error')
      });
  }

  useEffect(() => {
    getCallbackData();
  }, []);


  return (
    <StyledMainContent>
      <MuiFunction.Paper>
        {/* add table */}
        <StyledDiv>
        <StyledComponentHeader variant="outlined" position="relative">
          <Toolbar style={{ minHeight: '35px' }} variant="dense">
            <Typography component="h2" style={{ flex: 1 }}>
              Callback Reminders
            </Typography>
          </Toolbar>
        </StyledComponentHeader> 
        <StyledPaper variant="outlined">
          <div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table callback">
                {/* Table head data */}
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>PatientID</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Mobile</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Date</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Time</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Remarks</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Action</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* table body data */}
                <TableBody>
                  {getCallbackResult.map((item) => (
                    <StyledTableRow key={item.patient_callback_id}>
                      <TableCell component="th" scope="row">
                        {item.patient.panchyog_id}
                      </TableCell>
                      <TableCell align="left">{item.patient?.name}</TableCell>
                      <TableCell align="left">{item.patient?.mobile.toString()}</TableCell>
                      <TableCell align="left">{moment(item.callback_datetime).local().format('DD-MM-YYYY')}</TableCell>
                      <TableCell align="left">{moment(item.callback_datetime).local().format('hh:mm A')}</TableCell>
                      <TableCell align="left">{item.remarks}</TableCell>
                      <TableCell align="left">
                        <MuiFunction.Button variant='contained' color='secondary' onClick={()=>saveStatus(item.patient_callback_id)}>
                            {'Done'}
                        </MuiFunction.Button>
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </StyledPaper>
      </StyledDiv>
        {/*  */}
        <MuiFunction.Pagination
          count={getResultCount}
          page={getCurrentPage}
          onChange={handlePageChange}
        />
      </MuiFunction.Paper>
    </StyledMainContent>
  );
}
