import { createSelector } from 'reselect';
import { RootState } from '../Types';
import { GeneralDetail } from '../Types/patientDetails';
import _ from 'lodash';
import { getLastItem } from '../../Utils';

// import { LoginI } from "../Types/login";
export const navigation = (state: RootState) => state.navigation;
// export user details
export const loginState = (state: RootState) => state.auth;
export const isLoadingLogin = (state: RootState) => state.auth.isLoading;
export const isLoggedIn = (state: RootState) => state.auth.isLoggedIn;

// export static data
export const getProductsList = (state: RootState) => state.products.data;

// create login error selectors
export const loginError = createSelector([loginState], (data) => {
  if (_.isArray(data.errorData)) {
    //
    return {
      param: data.errorData[0].param,
      errorText: data.errorData[0].msg,
    };
  }
  //
  if (_.isObject(data.errorData)) {
    return {
      param: null,
      errorText: 'Something went wrong. Try Again.',
    };
  }
  //
  return {
    params: null,
    errorText: null,
  };
});

// user display name
export const getLoginUserData = createSelector([loginState], (data) => {
  if (data) {
    return data.userData;
  } else {
    return null;
  }
});

//
// export patient Info details
export const patientDetails = (state: RootState) => state.patientDetails.data;
export const isLoadingPatientDetails = (state: RootState) => state.patientDetails.isLoading;
//
export const patientDetailsForm = createSelector([patientDetails], (data) => {
  if (!_.isEmpty(data)) {
    // return null;
    console.log('data=>', data);
    const general_details: GeneralDetail = getLastItem(data.general_details);

    return {
      mobile: data.mobile[0],
      mobile2: data.mobile.length > 1 ? data.mobile[1] : '',
      mobile3: data.mobile.length > 2 ? data.mobile[2] : '',
      name: data.name,
      gender: data.gender,
      email: data.email ? data.email : '',
      mobile_code: data.mobile_code,
      bp: general_details.bp,
      sugar: general_details.sugar,
      thyroid: general_details.thyroid,
      is_married: general_details.is_married,
      age: general_details.age,
      weight: general_details.weight,
      height: general_details.height,
      bmi: general_details.bmi,
      care_of: data.care_of ? data.care_of : '',
      source: data.source ? data.source : '',
      location_name: data.location_name ? data.location_name : '',
      patient_id: data.patient_id,
      panchyog_id: data.panchyog_id,
    };
  } else {
    return null;
  }
});

// export patient problem
export const getPatientProblem = (state: RootState) => state.patientProblem;
// export patient complaints
export const getPatientComplaints = (state: RootState) => state.patientComplaints;
// export patient history
export const getPatientHistory = (state: RootState) => state.patientHistory;
// export patient diagnosis
export const getPatientDiagnosis = (state: RootState) => state.patientDiagnosis;
// export  Patient TreatmentLine data
export const getPatientTreatmentLine = (state: RootState) => state.patientTreatmentLine;
// export notes
export const getNotes = (state: RootState) => state.patientNotes;
// export order info
export const getOrderInfoDetails = (state: RootState) => state.orderInfo;
// export Address
export const getAddressDetails = (state: RootState) => state.patientAddress;
// export Patient Order data
export const getPatientOrderDetails = (state: RootState) => state.patientOrders;
