//
import { postData, getData, updateData, deleteData } from '../../Services/server';
import { signInWithFirebase } from '../../Services/firebase';
import { userLogin } from '../Actions';
import { localTokenGet, localTokenSet } from '../../Utils';

//
export const API_CALL = 'API_CALL';
export const POST_METHOD = 'POST_METHOD';
export const GET_METHOD = 'GET_METHOD';
export const PUT_METHOD = 'PUT_METHOD';
export const DELETE_METHOD = 'DELETE_METHOD';
export const FIREBASE_LOGIN = 'FIREBASE_LOGIN';

// add pending request
interface pendingRequestI {
  [key: string]: boolean;
}
//
const pendingRequest: pendingRequestI = {};

//
export default (store: any) => (next: any) => async (action: any) => {
  //
  const apiCall = action[API_CALL];
  // check action is an api call
  if (!apiCall || action.type !== API_CALL) return next(action);

  // get store here.
  // const state = store.getState();
  //
  const firebaseAccessToken = await localTokenGet()
  // base url
  const baseUrl = process.env.REACT_APP_API_BASEURL;

  // Fallback Error
  const fallbackError = 'Something went wrong';
  //
  const { method, types, endpoint, data } = apiCall;
  //
  // vailidate request
  if (typeof method !== 'string') {
    throw new Error('Method is not string.');
  }
  if (typeof endpoint !== 'string') {
    throw new Error('Endpoint is not string.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Types must be an array with three actions.');
  }

  // get types here.
  const [requestType, successType, failureType] = types;

  // check pending request
  if (pendingRequest[endpoint]) {
    // Abort request
    return;
  } else {
    pendingRequest[endpoint] = true;
  }

  //
  const createAction = (data: any) => {
    const finalAction = { ...action, ...data };
    delete finalAction[API_CALL];
    return finalAction;
  };
  //
  next(createAction({ type: requestType }));

  //
  switch (method) {
    case FIREBASE_LOGIN:
      return signInWithFirebase(data?.email, data?.password)
        .then((response: any) => {
          console.log('signInWithFirebase=>', response);
          if (response?.data) {
            // invoke our login API after successful login with Firebase.
            localTokenSet(response?.data.accessToken);
            store.dispatch(userLogin(response?.data.accessToken));
            //
          } else {
            //
            next(createAction({ type: failureType, error: response?.error || fallbackError }));
          }
        })
        .catch((error) => {
          console.log('signInWithFirebase Error=>', error);
          next(createAction({ type: failureType, error: error }));
        });
    case POST_METHOD:
      return postData(baseUrl + endpoint, data, firebaseAccessToken)
        .then(
          (response) => {
            pendingRequest[endpoint] = false;
            if (response.error || !response.data) {
              //
              next(createAction({ type: failureType, error: response.error || fallbackError }));
            } else {
              //
              next(createAction({ type: successType, data: response.data }));
            }
          },
          (error) => {
            pendingRequest[endpoint] = false;
            next(createAction({ type: failureType, error: error || fallbackError }));
          },
        )
        .catch((error) => {
          pendingRequest[endpoint] = false;
          next(createAction({ type: failureType, error: error || fallbackError }));
        });
    case GET_METHOD:
      return getData(baseUrl + endpoint, firebaseAccessToken)
        .then(
          (response) => {
            pendingRequest[endpoint] = false;
            if (response.error || !response.data) {
              //
              next(createAction({ type: failureType, error: response.error || fallbackError }));
            } else {
              //
              next(createAction({ type: successType, data: response.data }));
            }
          },
          (error) => {
            pendingRequest[endpoint] = false;
            next(createAction({ type: failureType, error: error || fallbackError }));
          },
        )
        .catch((error) => {
          pendingRequest[endpoint] = false;
          next(createAction({ type: failureType, error: error || fallbackError }));
        });
    case PUT_METHOD:
      return updateData(baseUrl + endpoint, data, firebaseAccessToken)
        .then(
          (response) => {
            pendingRequest[endpoint] = false;
            if (response.error || !response.data) {
              //
              next(createAction({ type: failureType, error: response.error || fallbackError }));
            } else {
              //
              next(createAction({ type: successType, data: response.data }));
            }
          },
          (error) => {
            pendingRequest[endpoint] = false;
            next(createAction({ type: failureType, error: error || fallbackError }));
          },
        )
        .catch((error) => {
          pendingRequest[endpoint] = false;
          next(createAction({ type: failureType, error: error || fallbackError }));
        });
    case DELETE_METHOD:
      return deleteData(baseUrl + endpoint, data, firebaseAccessToken)
        .then(
          (response) => {
            pendingRequest[endpoint] = false;
            if (response.error || !response.data) {
              //
              next(createAction({ type: failureType, error: response.error || fallbackError }));
            } else {
              //
              next(createAction({ type: successType, data: endpoint }));
            }
          },
          (error) => {
            pendingRequest[endpoint] = false;
            next(createAction({ type: failureType, error: error || fallbackError }));
          },
        )
        .catch((error) => {
          pendingRequest[endpoint] = false;
          next(createAction({ type: failureType, error: error || fallbackError }));
        });
    default:
      next(createAction({ type: failureType, error: 'Method is not valid' }));
  }
};
