import React from 'react';
import { Button } from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { useDispatch } from 'react-redux';
import { setNavigation } from '../../Stores/Actions';
import Constants from '../../Constants';

export default function CreateNew(): JSX.Element {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setNavigation(Constants.Config.Navigation.ORDERS));
  };
  return (
    <div style={{ flex: 1 }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClick}
        startIcon={<LocalMallIcon />}
      >
        Order
      </Button>
    </div>
  );
}
