import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import {CallSharp as CallIcon} from '@material-ui/icons';
import { CreatePatientCallbacks } from '../PatientCallbacks';

export default function CreateNew(): JSX.Element {
  const [open, setState] = useState(false);
  const onClickOpen = () => {
    setState(!open);
  };
  return (
    <div style={{ flex: 1 }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={onClickOpen}
        startIcon={<CallIcon />}
      >
        Callback
      </Button>
      <CreatePatientCallbacks
        openDialog={open}
        onCloseDialog={() => {
          onClickOpen();
        }}
      />
    </div>
  );
}
