import React, { useState, useEffect } from 'react';
import {
  Typography,
  Toolbar,
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from '@material-ui/core';
import { StyledComponentHeader, StyledDiv, StyledPaper } from '../Helpers';
import Constants from '../../Constants';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPatientTreatmentLine,
  getLoginUserData,
  patientDetailsForm,
  getPatientProblem,
} from '../../Stores/Selectors';
import {
  createPatientTreatmentLine,
  deletePatientTreatmentLine,
  fetchPatientTreatmentLine,
} from '../../Stores/Actions';
const TreatmentLine: React.FC = () => {
  const chunkSize = Math.ceil(Constants.TreatmentLine.length / 5);
  const TreatmentLineListData = _.chunk(Constants.TreatmentLine, chunkSize);
  // selectors and dispatches
  const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);
  const patientProblem = useSelector(getPatientProblem);
  const patientTreatmentLine = useSelector(getPatientTreatmentLine);
  // state
  const [getValue, setValue] = useState<string[]>([]);
  // functions
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const value = event.target.value ? event.target.value : event.target.checked;
    if (getValue.includes(event.target.name)) {
      // if it is persent then remove it.
      setValue((prevState) => prevState.filter((value) => value !== event.target.name));
      if (patientTreatmentLine && patientTreatmentLine.data) {
        const removedTreatmentLine = patientTreatmentLine.data.filter(
          (value) => value.name === event.target.name,
        );
        if (removedTreatmentLine.length && removedTreatmentLine[0].patient_treatment_line_id) {
          dispatch(deletePatientTreatmentLine(removedTreatmentLine[0].patient_treatment_line_id));
        }
      }
    } else {
      // add
      setValue([...getValue, event.target.name]);
      // add to server
      if (
        loginUserData &&
        loginUserData.user_id &&
        patientInfo &&
        patientInfo.patient_id &&
        patientProblem.data &&
        patientProblem.data.patient_problem_id
      ) {
        dispatch(
          createPatientTreatmentLine({
            patient_problem_id: patientProblem.data.patient_problem_id,
            patient_id: patientInfo.patient_id,
            user_id: loginUserData.user_id,
            name: event.target.name,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (patientProblem.data && patientProblem.data?.patient_problem_id) {
      dispatch(fetchPatientTreatmentLine(patientProblem.data.patient_problem_id));
    }
  }, [patientProblem.data]);

  useEffect(() => {
    console.log('patientTreatmentLine.data', patientTreatmentLine.data);
    const treatmentLineList: string[] = _.map(patientTreatmentLine.data, (item) => item.name);
    // const treatmentLineList: string[] = patientTreatmentLine.data.map((item) => item.name);
    setValue(treatmentLineList);
  }, [patientTreatmentLine.data]);
  //
  return (
    <StyledDiv>
      <StyledComponentHeader variant="outlined" position="relative">
        <Toolbar style={{ minHeight: '35px' }} variant="dense">
          <Typography component="h2" style={{ flex: 1 }}>
            Treatment Line
          </Typography>
        </Toolbar>
      </StyledComponentHeader>
      <StyledPaper variant="outlined">
        <div>
          <Grid container spacing={1}>
            {TreatmentLineListData.map((listItem, index) => {
              return (
                <FormControl component="fieldset" key={`kk#${index}`}>
                  <FormGroup>
                    {listItem.map((item, indx) => {
                      return (
                        <FormControlLabel
                          key={`kk##${indx}`}
                          control={
                            <Checkbox
                              checked={getValue.includes(item)}
                              onChange={handleChange}
                              name={item}
                            />
                          }
                          label={item}
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              );
            })}
          </Grid>
        </div>
      </StyledPaper>
    </StyledDiv>
  );
};

export default TreatmentLine;
