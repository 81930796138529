import _ from 'lodash';
import {
  ORDER_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_SUCCESS,
  ORDER_ERROR,
  UPDATE_ORDER_SUCCESS,
  NEW_ORDER_REMOVE_ACTION,
  ADD_NEW,
} from '../Actions';

import { PatientOrders } from '../Types/patientOrders';

const initialState: PatientOrders = {
  isLoading: false,
  errorData: null,
  newOrderId: null,
  data: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        newOrderId: null,
        data: [],
      };
    case ORDER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORDER_CREATE_SUCCESS:
      const data = [...state.data, action.data];
      return {
        ...state,
        isLoading: false,
        newOrderId: action.data.orders.panchyog_order_id,
        errorData: null,
        data,
      };
    case UPDATE_ORDER_SUCCESS:
      const orderData = _.map(state.data, (item) => {
        if (item.orders && action.data && item.orders.order_id === action.data.order_id) {
          item.orders = action.data;
          return item;
        } else {
          return item;
        }
      });
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: orderData,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        newOrderId: null,
        data: action.data,
      };
    case ORDER_ERROR:
      return {
        ...state,
        isLoading: false,
        newOrderId: null,
        errorData: action.error,
      };
    case NEW_ORDER_REMOVE_ACTION:
      return {
        ...state,
        newOrderId: null,
      };
    default:
      return state;
  }
};
