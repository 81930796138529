import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { StyledComponentHeader, StyledDiv, StyledPaper } from '../Helpers';
import { Orders } from '../../Stores/Types/patientOrders';
import { MuiFunction, TableSearchDiv, TableSearchIconDiv, TableSearchInputBase } from '../../Css';

interface StatusColor {
  [key: string]: string;
}
const statusColor: StatusColor = {
  PROCESSING: '#F39C12',
  DISPATCHED: '#3498DB',
  IN_TRANSIT: '#8E44AD',
  DELIVERED: '#239B56',
  RETURNED: '#E74C3C',
};

const StyledChip = styled(Chip)`
  font-size: 10px !important;
  font-weight: 600;
  font-style: inherit;
  background-color: ${(props) => {
    return props.theme.bg ? statusColor[props.theme.bg] : props.theme.bg;
  }} !important;
`;

const StyledTableRow = styled(TableRow)`
  & > :hover {
    background-color: #dfdfdf;
    cursor: pointer;
  }
`;

export interface Props {
  orderDataList: Orders[];
  showDetails: (orderData: Orders) => void;
  filterData: (searchKey: string|null) => void;
}

export default function OrderHistory(props: Props): JSX.Element {
  const [getOrderList, setOrderList] = useState<Orders[]>([]);
  const [getSearchKeyword, setSearchKeyword] = useState<string|null>(null);
  console.log('props.orderDataList', props.orderDataList);

  const handleClick = (orderData: Orders) => {
    console.log('OrderData', orderData);
    props.showDetails(orderData);
  };

  const filterData = () => props.filterData(getSearchKeyword);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      return filterData();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  useEffect(() => {
    const data = _.orderBy(props.orderDataList, (item) => item.order_id, ['desc']);
    setOrderList(data);
  }, [props.orderDataList]);

  return (
    <React.Fragment>
      <StyledDiv>
        <StyledComponentHeader variant="outlined" position="relative">
          <Toolbar style={{ minHeight: '35px' }} variant="dense">
            <Typography component="h2" style={{ flex: 1 }}>
              Order History
            </Typography>
            <TableSearchDiv>
              <TableSearchInputBase 
                id='TableSearchInputBase'
                value={getSearchKeyword || ''}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                placeholder="ODR005 (or) PA04657"
                inputProps={{ 'aria-label': 'search' }}
              />
              <TableSearchIconDiv onClick={() => filterData()}>
                <MuiFunction.SearchIcon />
              </TableSearchIconDiv>
            </TableSearchDiv>
          </Toolbar>
        </StyledComponentHeader> 
        <StyledPaper variant="outlined">
          <div>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                {/* Table head data */}
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>OrderID</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>PatientID</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Days</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>DeliveryType</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Status</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>TotalAmount</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>OrderDate</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {/* table body data */}
                <TableBody>
                  {getOrderList.map((orderItem) => (
                    <StyledTableRow
                      key={orderItem.panchyog_order_id}
                      onClick={() => handleClick(orderItem)}
                    >
                      <TableCell component="th" scope="row">
                        {orderItem.panchyog_order_id}
                      </TableCell>
                      <TableCell align="left">{orderItem.patient?.panchyog_id}</TableCell>
                      <TableCell align="left">{orderItem.patient?.name}</TableCell>
                      <TableCell align="left">{orderItem.days}</TableCell>
                      <TableCell align="left">{orderItem.delivery_type}</TableCell>
                      <TableCell align="left">
                        <StyledChip
                          size="small"
                          label={orderItem.status}
                          theme={{ bg: orderItem.status }}
                        />
                      </TableCell>
                      <TableCell align="left">{orderItem.total_amount}</TableCell>
                      <TableCell align="left">
                        {moment(orderItem.created_datetime).format('DD/MM/YYYY')}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </StyledPaper>
      </StyledDiv>
    </React.Fragment>
  );
}
