import CryptoJS from 'crypto-js';
const keySize = 256;
const ivSize = 128;
const iterations = 100;
const keyPass = 'bc78afe5NcRfU80e62f794bf36c808619e';
// const iv = CryptoJS.enc.Utf8.parse('5011c865f1a80e62');
export const loadFromSessionStorage = () => {
  try {
    const serializedState = sessionStorage.getItem('std');
    if (serializedState === null) {
      return undefined;
    }
    const bytesData = decrypt(serializedState, keyPass);
    const decryptedData = JSON.parse(bytesData.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (e) {
    return undefined;
  }
};

export const saveToSessionStorage = (state: any) => {
  try {
    const stateData = JSON.stringify(state);
    if (stateData) {
      const encryptedDate = encrypt(stateData, keyPass);
      sessionStorage.setItem('std', encryptedDate);
    }
  } catch (e) {
    console.log(e);
  }
};

const encrypt = (msg:string, pass: string) => {
  const salt = CryptoJS.lib.WordArray.random(ivSize / 8);

  const key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  const iv = CryptoJS.lib.WordArray.random(ivSize / 8);

  const encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  return salt.toString() + iv.toString() + encrypted.toString();
}

const decrypt = (transitMessage: string, pass: string) => {
  const salt = CryptoJS.enc.Hex.parse(transitMessage.substr(0, 32));
  const iv = CryptoJS.enc.Hex.parse(transitMessage.substr(32, 32));
  const encrypted = transitMessage.substring(64);

  const key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations,
  });

  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  return decrypted;
}
