import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { PatientAddressData } from '../../Stores/Types/patientAddress';
// Custom Components
interface Props {
  addressList: PatientAddressData[];
  selectedAddress: (address: PatientAddressData) => void;
}

export default function AddressList(props: Props): JSX.Element {
  const [checked, setChecked] = useState(0);

  const handleToggle = (value: number) => () => {
    console.log('handleToggle', value);
    setChecked(value);
  };
  useEffect(() => {
    if (props.addressList.length) {
      setChecked(props.addressList[0].patient_address_id);
    }
  }, [props.addressList]);

  useEffect(() => {
    if (checked && props.addressList.length) {
      const address = props.addressList.filter((item) => {
        if (checked === item.patient_address_id) return item;
      });
      if (address.length) {
        props.selectedAddress(address[0]);
      }
    }
  }, [checked]);
  return (
    <React.Fragment>
      {props.addressList.length ? (
        <List>
          {props.addressList.map((value) => {
            const labelId = `checkbox-list-label-${value.patient_address_id}`;
            return (
              <ListItem
                key={value.patient_address_id}
                role={undefined}
                dense
                button
                onClick={handleToggle(value.patient_address_id)}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={value.patient_address_id === checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={`
                    ${value.name}, 
                    ${value.address}, 
                    ${value.landmark}, 
                    ${value.district}, 
                    ${value.state}, 
                    PinCode: ${value.pin_code},
                    MobileNo: ${value.mobile.toString()}
                  `}
                />
              </ListItem>
            );
          })}
        </List>
      ) : null}
    </React.Fragment>
  );
}
