// Import the functions you need from the SDKs you need
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

/**
 *
 * @param email Login with firebase.
 * @param password
 * @returns
 */
export const signInWithFirebase = async (email: string, password: string) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      return {
        data: { ...user },
      };
      // ...
    })
    .catch((error) => {
      if (error.code === 'auth/invalid-email') {
        return {
          error: [
            {
              param: 'email',
              msg: 'Invalid Email. Try Again.',
            },
          ],
        };
      } else if (error.code === 'auth/wrong-password') {
        return {
          error: [
            {
              param: 'password',
              msg: 'Invalid Password. Try Again.',
            },
          ],
        };
      } else {
        return {
          error: error,
        };
      }
    });
};

interface CheckState {
  firebase_uid: string | null;
}

/**
 * Check Firebase user auth state.
 * @returns
 */

export const firebaseLogOut = async () => {
  const auth = getAuth();
  return await signOut(auth);
};

export const firebaseGetIdToken = async () => {
  return await getAuth().currentUser?.getIdToken(true);
}
