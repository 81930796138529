import React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import styled from 'styled-components';

const StyledDialog = styled(Dialog)`
  text-align: center;
`;
const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)`
  width: 120px !important;
  height: 120px !important;
  color: #138f11;
`;
const StyledDiv = styled.div`
  display: block;
  padding: 20px;
`;

export interface Props {
  open: boolean;
  order_id: number | null;
  onClose: () => void;
}

export default function SuccessDialog(props: Props): JSX.Element {
  return (
    <StyledDialog
      maxWidth={'xs'}
      fullWidth={true}
      aria-labelledby="simple-dialog-title"
      open={props.open}
    >
      <DialogTitle id="simple-dialog-title">{'Order Created'}</DialogTitle>
      <StyledDiv>
        <div>
          <StyledCheckCircleOutlineIcon />
        </div>
        <p>
          <strong>{`OrderID: ${props.order_id}`}</strong>
        </p>
        <Button variant="contained" color="secondary" onClick={props.onClose}>
          {'OK'}
        </Button>
      </StyledDiv>
    </StyledDialog>
  );
}
