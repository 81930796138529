export const Status = {
  CLOSED: 'CLOSED',
  OPEN: 'OPEN',
};

export const Gender = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
};

export const Time = {
  DAY: 'DAY',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const Reasons = [
  { key: 'RESOLVED', value: 'Resolved' },
  { key: 'MEDICINE_CHANGED', value: 'Medicine Changed' },
  { key: 'DOCTOR_CHANGED', value: 'Doctor Changed' },
  { key: 'OTHER', value: 'Other' },
];

export const Navigation = {
  DASHBOARD: 'DASHBOARD',
  DIAGNOSIS: 'DIAGNOSIS',
  ORDERS: 'ORDERS',
  ORDERS_LIST: 'ORDERS_LIST',
  PATIENT_LIST: 'PATIENT_LIST',
  LOG_OUT: 'LOG_OUT',
  TEST_REPORTS: 'TEST_REPORTS',
  CALLBACK_REQUEST: 'CALLBACK_REQUEST',
};

export const ProductCategory = {
  CHURNA: 'CHURNA',
  TABLET: 'TABLET',
  OIL: 'OIL',
  SYRUP: 'SYRUP',
  CREAM: 'CREAM',
  DRY_KWATH: 'DRY_KWATH',
};
export const ProductType = {
  MEDICINE: 'MEDICINE',
  PRODUCT: 'PRODUCT',
};
export const AddressTags = {
  HOME: 'HOME',
  OFFICE: 'OFFICE',
}
export const DeliveryType = {
  PICKED_UP: 'PICKED_UP',
  COURIER: 'COURIER',
}
export const UnitTypes = {
  GRAMS: 'GRAMS',
  TABLET: 'TABLET',
}

export const DeliveryStatus = {
  PROCESSING: 'PROCESSING',
  DISPATCHED: 'DISPATCHED',
  IN_TRANSIT: 'IN_TRANSIT',
  DELIVERED: 'DELIVERED',
  RETURNED: 'RETURNED',
}
export const CourierNames = {
 DELHIVERY : 'Delhivery',
 INDIA_POST : 'IndiaPost',
 DHL_EXPRESS : 'DHLExpress',
 BLUE_DART : 'BlueDart',
 FIRST_FLIGHT : 'FirstFlight',
 FEDEX_INDIA : 'FedExIndia',
 PROFESSIONAL : 'Professional',
 DTDC : 'DTDC',
 GATI : 'Gati',
}
