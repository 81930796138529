import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Toolbar, Grid, TextField, Divider, IconButton } from '@material-ui/core';
import ReactSelect, { OnChangeValue, components, OptionProps } from 'react-select';
//
import { StyledComponentHeader, StyledDiv, StyledPaper } from '../Helpers';
import Constants from '../../Constants';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { SaveButton, MuiFunction, CancelIconDiv } from '../../Css';
import { addOrderInfo, getOrderInfo, createNewOrder, setNavigation } from '../../Stores/Actions';
import {
  getLoginUserData,
  patientDetailsForm,
  getPatientProblem,
  getProductsList,
  getOrderInfoDetails,
} from '../../Stores/Selectors';
// PatientComplaintData
import { ProductsData } from '../../Stores/Types/products';
import { PatientAddressData } from '../../Stores/Types/patientAddress';
import { useSnackbar } from 'notistack';
import ConfirmOrderDialog from './ConfirmOrder';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

const StyledOptionDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledOptionTagSpan = styled.span`
  font-size: 9px;
  background-color: #e0e0e0;
  padding: 2px;
  text-align: right;
  border-radius: 4px;
  font-weight: 500;
`;

const StyledShowProductGrid = styled(Grid)`
  border: 1px solid #d3d0d0;
  border-radius: 5px;
  margin: 5px !important;
  padding: 10px !important;
`;
const StyledTextField = styled(TextField)`
  z-index: 0;
  & > div > input {
    padding: 5px !important;
  }
`;
const StyledDivider = styled(Divider)`
  margin-top: 10px !important;
  margin-bottom: 10px !important;
`;

interface ProductItemI {
  product_id: number;
  name: string;
  unit: number;
  unit_price: number;
  discount: number;
  payable_amount: number;
  hsn_code: string;
}

interface AyurvedicMixerI {
  product_id: number;
  name: string;
  quantity: number;
}

interface ProductsDataI extends ProductsData {
  qty?: number;
  price?: number;
  gm?: number;
}

export interface FinalOrderData {
  orderData: ProductsDataI[];
  ayurvedicAushadhiAmount: number;
  selectedAddress: PatientAddressData;
  days: number;
  totalAmount: number;
  payableAmount: number;
  deliveryType: string;
}

interface Items {
  product_id: number;
  name: string;
  unit: number;
  unit_price: number;
  payable_amount: number;
}
interface AyurvedicItems {
  product_id: number;
  name: string;
  quantity: number;
  category: string;
  unit_type: string;
}

const CreateOrder: React.FC = (props): JSX.Element => {
  const { Config } = Constants;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);
  const patientProblem = useSelector(getPatientProblem);
  const productsList = useSelector(getProductsList);
  const getOrderInfoData = useSelector(getOrderInfoDetails);
  // local state
  const [getChurna, setChurna] = useState<ProductsDataI[]>([]);
  const [getTablet, setTablet] = useState<ProductsDataI[]>([]);
  const [getProducts, setProducts] = useState<ProductsDataI[]>([]);
  // selected items
  const [getSelectedData, setSelectedData] = useState<ProductsDataI[]>([]);
  const [showChurnaWeight, setChurnaWeight] = useState<number>(0);
  const [openConfirmOrderDialog, setConfirmOrderDialog] = useState<boolean>(false);
  const [getAushadhiAmount, setAushadhiAmount] = useState('');
  const [getTotalProductAmount, setTotalProductAmount] = useState<number>(0);
  const [getTotalAmount, setTotalAmount] = useState<number>(0);

  const OrderNext = () => {
    // check if all item has respective qty/price/gm
    let isNext = true;
    if (!getSelectedData.length) {
      // return error
      isNext = false;
      return enqueueSnackbar(`Please add some medicine or product`, {
        variant: 'error',
      });
    }
    _.forEach(getSelectedData, (item) => {
      if (item.type === Config.ProductType.MEDICINE) {
        // return error
        if (!item?.gm && !item?.qty) {
          isNext = false;
          return enqueueSnackbar(`Please add quantity for ${item.name}`, {
            variant: 'error',
          });
        }
        if (!getAushadhiAmount || getAushadhiAmount === '') {
          isNext = false;
          enqueueSnackbar(`Please add Amount for Ayurvedic Aushadhi`, {
            variant: 'error',
          });
          return false; // break loop here.
        }
      }
      if (item.type === Config.ProductType.PRODUCT) {
        if (!item?.qty) {
          isNext = false;
          // send qty error
          return enqueueSnackbar(`Please add quantity for ${item.name}`, {
            variant: 'error',
          });
        }
        if (!item.price) {
          isNext = false;
          // send price error
          return enqueueSnackbar(`Please add price for ${item.name}`, {
            variant: 'error',
          });
        }
      }
    });
    setConfirmOrderDialog(isNext);
    // add order info
    if (isNext && patientInfo && patientInfo.patient_id) {
      const orderInfo = {
        order_data: {
          items_details: getSelectedData,
          ayurvedic_mixer_amount: getAushadhiAmount,
        },
        order_info_id:
          getOrderInfoData && getOrderInfoData.data && getOrderInfoData.data.order_info_id
            ? getOrderInfoData.data.order_info_id
            : null,
        patient_id: patientInfo.patient_id,
      };
      dispatch(addOrderInfo(orderInfo));
    }
    console.log('getSelectedDat=>', getSelectedData);
  };
  // check if product already added
  const productExists = (valueList: ProductsDataI[], product_id: number) => {
    return valueList.some(function (elm) {
      return elm.product_id === product_id;
    });
  };
  //
  const handleSelectedItems = (selectedItem: OnChangeValue<ProductsDataI, false>) => {
    console.log(selectedItem);
    if (selectedItem) {
      // add item in order
      setSelectedData((currentValues) => {
        if (!productExists(currentValues, selectedItem.product_id)) {
          currentValues = [...currentValues, selectedItem];
        }
        return currentValues;
      });
    }
  };

  // handle input changes
  const handleChange = (key: string, productId: number) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      console.log(key, productId, event.target.value);
      const val = event.target.value.trim();
      const value = !val || val === '' ? 0 : parseInt(val);
      setSelectedData((selectedItems) => {
        const newItems = selectedItems.map((item) => {
          if (item.product_id === productId) {
            if (key === 'gm') item['gm'] = value;
            if (key === 'qty') item['qty'] = value;
            if (key === 'price') item['price'] = value;
          }
          return item;
        });
        // return
        return newItems;
      });
    } catch (error) {
      // show error : 'Enter a valid input'
    }
  };

  // final order data
  const handleFinalOrder = (finalOrderData: FinalOrderData) => {
    console.log('finalOrderData=>', finalOrderData);
    if (loginUserData && patientInfo && patientProblem && patientProblem.data) {
      const orderItem: Items[] = [];
      const ayurvedicItems: AyurvedicItems[] = [];
      _.forEach(finalOrderData.orderData, (item) => {
        if (item.type === Config.ProductType.PRODUCT && item.price && item.qty) {
          orderItem.push({
            product_id: item.product_id,
            name: item.name,
            unit: item.qty,
            unit_price: item.price,
            payable_amount: item.price,
          });
        }
        if (
          item.type === Config.ProductType.MEDICINE &&
          item.category === Config.ProductCategory.TABLET &&
          item.qty
        ) {
          ayurvedicItems.push({
            product_id: item.product_id,
            name: item.name,
            quantity: item.qty,
            category: Config.ProductCategory.TABLET,
            unit_type: Config.UnitTypes.TABLET,
          });
        }
        if (
          item.type === Config.ProductType.MEDICINE &&
          item.category === Config.ProductCategory.CHURNA &&
          item.gm
        ) {
          ayurvedicItems.push({
            product_id: item.product_id,
            name: item.name,
            quantity: item.gm,
            category: Config.ProductCategory.CHURNA,
            unit_type: Config.UnitTypes.GRAMS,
          });
        }
      });
      // final order payload data.
      const orderObj = {
        orders: {
          user_id: loginUserData.user_id,
          patient_id: patientInfo.patient_id,
          patient_problem_id: patientProblem.data.patient_problem_id,
          clinic_location_id: loginUserData.clinic_location_id,
          patient_address_id: finalOrderData.selectedAddress.patient_address_id,
          full_address: `${finalOrderData.selectedAddress.name}, ${
            finalOrderData.selectedAddress.address
          }, ${finalOrderData.selectedAddress.landmark}, ${
            finalOrderData.selectedAddress.district
          }, ${finalOrderData.selectedAddress.state}, PinCode: ${
            finalOrderData.selectedAddress.pin_code
          }, MobileNo: ${finalOrderData.selectedAddress.mobile.toString()}`,
          mobile: `${finalOrderData.selectedAddress.mobile.toString()}`,
          ayurvedic_mixer_amount: finalOrderData.ayurvedicAushadhiAmount,
          total_amount: finalOrderData.totalAmount,
          payable_amount: finalOrderData.payableAmount,
          delivery_type: finalOrderData.deliveryType,
          days: finalOrderData.days,
        },
        order_products: orderItem,
        order_ayurvedic_mixers: ayurvedicItems,
      };
      console.log('orderObj =>', orderObj);
      // dispatch
      dispatch(createNewOrder(orderObj));
      // close popup
      setConfirmOrderDialog(false);
    }
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAushadhiAmount(event.target.value);
  };
  //
  useEffect(() => {
    // check and set churna weight
    let weight = 0;
    _.forEach(getSelectedData, (item) => {
      if (
        item.type === Config.ProductType.MEDICINE &&
        item.category === Config.ProductCategory.CHURNA &&
        item?.gm
      ) {
        weight = weight + item.gm;
      }
    });
    setChurnaWeight(weight);
  }, [getSelectedData]);

  // set Product
  useEffect(() => {
    _.forEach(productsList, (item) => {
      if (
        item.category === Config.ProductCategory.CHURNA &&
        item.type === Config.ProductType.MEDICINE
      ) {
        setChurna((prevState) => [...prevState, item]);
      } else if (
        item.category === Config.ProductCategory.TABLET &&
        item.type === Config.ProductType.MEDICINE
      ) {
        setTablet((prevState) => [...prevState, item]);
      } else {
        setProducts((prevState) => [...prevState, item]);
      }
    });
  }, [productsList]);

  // calculate price
  useEffect(() => {
    let productsAmount = 0;
    _.forEach(getSelectedData, (item) => {
      if (item.type === Config.ProductType.PRODUCT && item.price && item.qty) {
        productsAmount = productsAmount + item.price * item.qty;
      }
    });
    setTotalProductAmount(productsAmount);
  }, [getSelectedData]);

  useEffect(() => {
    const aushadhiPrice = getAushadhiAmount === '' ? 0 : parseInt(getAushadhiAmount);
    setTotalAmount(getTotalProductAmount + aushadhiPrice);
  }, [getTotalProductAmount, getAushadhiAmount]);
  // get latest order info
  useEffect(() => {
    if (patientInfo && patientInfo.patient_id) {
      dispatch(getOrderInfo(patientInfo.patient_id));
    }
  }, [patientInfo]);
  // pre filled all column with latest order info
  useEffect(() => {
    if (
      getOrderInfoData &&
      getOrderInfoData.data &&
      getOrderInfoData.data.order_data &&
      getOrderInfoData.data.order_data.items_details
    ) {
      setSelectedData(getOrderInfoData.data.order_data.items_details);
      if (getOrderInfoData.data.order_data.ayurvedic_mixer_amount) {
        setAushadhiAmount(getOrderInfoData.data.order_data.ayurvedic_mixer_amount);
      }
    }
  }, [getOrderInfoData]);

  const OptionComponent = (props: OptionProps<ProductsDataI, false>) => {
    return (
      <components.Option {...props}>
        <StyledOptionDiv>
          <span>{props.data.name}</span>
          <StyledOptionTagSpan>{props.data.category}</StyledOptionTagSpan>
        </StyledOptionDiv>
      </components.Option>
    );
  };

  const handleRemove = (product_id: number) => {
    setSelectedData((prevState) => 
      prevState.filter((item) => item.product_id !== product_id)
    );
  }

  return (
    <StyledDiv>
      {patientInfo && patientInfo.patient_id && loginUserData && loginUserData?.user_id ? (
        <ConfirmOrderDialog
          openDialog={openConfirmOrderDialog}
          orderData={getSelectedData}
          ayurvedicAushadhiAmount={getAushadhiAmount}
          patient_id={patientInfo.patient_id}
          user_id={loginUserData?.user_id}
          onCloseDialog={() => {
            setConfirmOrderDialog(false);
          }}
          handleFinalOrder={handleFinalOrder}
        />
      ) : null}
      <StyledComponentHeader variant="outlined" position="relative">
        <Toolbar style={{ minHeight: '35px' }} variant="dense">
          <IconButton aria-label="delete" size="medium" onClick={()=>dispatch(setNavigation(Config.Navigation.DIAGNOSIS))}>
            <ArrowBackOutlinedIcon fontSize="inherit" />
          </IconButton>
          <Typography component="h2" style={{ flex: 1 }}>
            Create New Order (1 Month)
          </Typography>
          <SaveButton
            variant="outlined"
            color="primary"
            size="small"
            // startIcon={<SaveIcon />}
            onClick={OrderNext}
          >
            {'Next'}
          </SaveButton>
        </Toolbar>
      </StyledComponentHeader>
      <StyledPaper variant="outlined">
        <div>
          <Grid container direction="row" spacing={1}>
            <Grid item xs>
              <ReactSelect
                key={'react-select-churna'}
                isClearable
                // value={defaultSelection}
                placeholder={'Churna...'}
                getOptionLabel={(option) => option.name}
                onChange={handleSelectedItems}
                options={getChurna}
              />
            </Grid>
            <Grid item xs>
              <ReactSelect
                key={'react-select-tablet'}
                isClearable
                placeholder={'Tablet...'}
                getOptionLabel={(option) => option.name}
                onChange={handleSelectedItems}
                options={getTablet}
              />
            </Grid>
            <Grid item xs>
              <ReactSelect
                isClearable
                key={'react-select-products'}
                placeholder={'Products...'}
                getOptionLabel={(option) => option.name}
                onChange={handleSelectedItems}
                options={getProducts}
                components={{ Option: OptionComponent }}
              />
            </Grid>
          </Grid>
          {/* Show selected medicine */}
          <Grid container direction="row" spacing={1}>
            <StyledShowProductGrid item xs>
              {getSelectedData.map((item, index) => {
                if (
                  item.category === Config.ProductCategory.CHURNA &&
                  item.type === Config.ProductType.MEDICINE
                ) {
                  return (
                    <React.Fragment key={`odr-ch-${index}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          {item.name}
                        </Grid>
                        <Grid item xs={3}>
                          <StyledTextField
                            label="Gm"
                            id="outlined-start-adornment"
                            variant="outlined"
                            value={item?.gm}
                            onChange={handleChange('gm', item.product_id)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <CancelIconDiv onClick={()=> handleRemove(item.product_id)}>
                            <MuiFunction.CancelIcon />
                          </CancelIconDiv>
                        </Grid>
                      </Grid>
                      <StyledDivider />
                    </React.Fragment>
                  );
                }
              })}
              <Grid container spacing={1} key={`odr-ch-total`}>
                <Grid item xs={9}>
                  {'Total Weight'}
                </Grid>
                <Grid item xs={3}>
                  <strong>
                    {showChurnaWeight >= 160 && showChurnaWeight <= 200 ? (
                      <span style={{ color: '#4caf50' }}>{showChurnaWeight}</span>
                    ) : (
                      <span style={{ color: '#ff5722' }}>{showChurnaWeight}</span>
                    )}
                  </strong>
                </Grid>
              </Grid>
            </StyledShowProductGrid>
            <StyledShowProductGrid item xs>
              {getSelectedData.map((item, index) => {
                if (
                  item.category === Config.ProductCategory.TABLET &&
                  item.type === Config.ProductType.MEDICINE
                ) {
                  return (
                    <React.Fragment key={`odr-tb-${index}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={8}>
                          {item.name}
                        </Grid>
                        <Grid item xs={3}>
                          <StyledTextField
                            label="Qty"
                            id="outlined-start-adornment"
                            variant="outlined"
                            value={item?.qty}
                            onChange={handleChange('qty', item.product_id)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <CancelIconDiv onClick={()=> handleRemove(item.product_id)}>
                            <MuiFunction.CancelIcon />
                          </CancelIconDiv>
                        </Grid>
                      </Grid>
                      <StyledDivider />
                    </React.Fragment>
                  );
                }
              })}
            </StyledShowProductGrid>
            <StyledShowProductGrid item xs>
              {getSelectedData.map((item, index) => {
                if (item.type === Config.ProductType.PRODUCT) {
                  return (
                    <React.Fragment key={`odr-pd-${index}`}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          {item.name}
                        </Grid>
                        <Grid item xs={3}>
                          <StyledTextField
                            label="MRP"
                            id="outlined-start-adornment"
                            variant="outlined"
                            value={item?.price}
                            onChange={handleChange('price', item.product_id)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <StyledTextField
                            label="Qty"
                            id="outlined-start-adornment"
                            variant="outlined"
                            value={item?.qty}
                            onChange={handleChange('qty', item.product_id)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <CancelIconDiv onClick={()=> handleRemove(item.product_id)}>
                            <MuiFunction.CancelIcon />
                          </CancelIconDiv>
                        </Grid>
                      </Grid>
                      <StyledDivider />
                    </React.Fragment>
                  );
                }
              })}
            </StyledShowProductGrid>
          </Grid>
          {/* Add & Show total amount */}
          <Grid container direction="row" spacing={1}>
            <StyledShowProductGrid item xs={6}>
              <Grid container key={`odr-ch-total`}>
                <Grid item xs={9}>
                  {'Amount for Ayurvedic Aushadhi'}
                </Grid>
                <Grid item xs={3}>
                  <StyledTextField
                    label="Amount"
                    id="outlined-start-adornment"
                    variant="outlined"
                    name={'AyurvedicAushadhi'}
                    value={getAushadhiAmount}
                    onChange={handlePriceChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </StyledShowProductGrid>
            <StyledShowProductGrid item xs={6}>
              <Grid container key={`odr-ch-total`}>
                <Grid item xs={9}>
                  {'Amount for Products'}
                </Grid>
                <Grid item xs={3}>
                  <StyledTextField
                    disabled={true}
                    label="Amount"
                    id="outlined-start-adornment"
                    variant="outlined"
                    name={'ProductsAmount'}
                    value={getTotalProductAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </StyledShowProductGrid>
            <StyledShowProductGrid item xs={6}>
              <Grid container key={`odr-ch-total`}>
                <Grid item xs={9}>
                  {'Total Amount (1 Month Medicine)'}
                </Grid>
                <Grid item xs={3}>
                  <StyledTextField
                    disabled={true}
                    label="Total Amount"
                    id="outlined-start-adornment"
                    variant="outlined"
                    name={'TotalAmount'}
                    value={getTotalAmount}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </StyledShowProductGrid>
          </Grid>
        </div>
      </StyledPaper>
    </StyledDiv>
  );
};

export default CreateOrder;
