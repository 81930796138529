import React, { useState, useEffect } from 'react';
import { StyledMainContent, MuiFunction } from '../../Css';
import { getData, postData } from '../../Services/server';
import { Orders, OrderData } from '../../Stores/Types/patientOrders';
import { localTokenGet } from '../../Utils';
import OrderHistory from './OrderHistory';
import ViewOrder from './ViewOrder';

interface OrderResult {
  count: number;
  orders: Orders[];
}
interface OrderRes {
  data: OrderResult;
}
interface OrderDataDetails {
  data: OrderData;
}

export default function ViewAllOrders(): JSX.Element {
  const [getCurrentPage, setCurrentPage] = useState<number>(1);
  const [getResultCount, setResultCount] = useState<number>(0);
  const [getOrderResult, setOrderResult] = useState<Orders[]>([]);
  const [getPerPage, setPerPage] = useState<number>(20);
  const [showOrderDetails, setShowOrderDetails] = useState<OrderData | null>(null);
  const [getSearchKeyword, setSearchKeyword] = useState<string | null>(null);

  const getOrderData = async () => {
    const token = await localTokenGet();
    const dataPayload = {
      page: getCurrentPage,
      per_page: getPerPage,
      search_key: getSearchKeyword, 
      order_by: {
        created_datetime: null,
        status: null,
        order_id: 'DESC',
      },
    };
    postData(`${process.env.REACT_APP_API_BASEURL}/api/v1/order/fetch`, dataPayload, token)
      .then((res: OrderRes) => {
        setResultCount(Math.ceil(res.data.count / getPerPage));
        setOrderResult(res.data.orders);
      })
      .catch((_err: any) => {
        setCurrentPage(1);
        setResultCount(0);
        setOrderResult([]);
      });
  };
  const getOrderByOrderId = async (panchyog_order_id: string) => {
    const token = await localTokenGet();
    getData(`${process.env.REACT_APP_API_BASEURL}/api/v1/order/details/${panchyog_order_id}`, token)
      .then((res: OrderDataDetails) => {
        setShowOrderDetails(res.data);
      })
      .catch((_err: any) => {
        console.log(_err);
      });
  };
  const handleShowOrder = (orderData: Orders) => {
    console.log('orderData=>', orderData);
    getOrderByOrderId(orderData.panchyog_order_id);
  };
  const handleDetailsClose = () => {
    setShowOrderDetails(null);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getOrderData();
  }, [getCurrentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setTimeout(()=> getOrderData());
  }, [getSearchKeyword]);

  return (
    <StyledMainContent>
      {showOrderDetails ? (
        <ViewOrder orderData={showOrderDetails} handleDetailsClose={handleDetailsClose} />
      ) : null}
      <MuiFunction.Paper>
        <OrderHistory 
          orderDataList={getOrderResult} 
          showDetails={handleShowOrder}
          filterData = {setSearchKeyword}
        />
        <MuiFunction.Pagination
          count={getResultCount}
          page={getCurrentPage}
          onChange={handlePageChange}
        />
      </MuiFunction.Paper>
    </StyledMainContent>
  );
}
