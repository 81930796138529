import React, { useState, useEffect } from 'react';
import { styled, MuiFunction } from '../../Css';

const StyledDialogContent = styled(MuiFunction.MuiDialogContent)`
  padding: 10px !important;
`;
const StyledDialogActions = styled(MuiFunction.MuiDialogActions)`
  margin: 0px !important;
  padding: 10px !important;
`;

const StyledMuiDialogTitle = styled(MuiFunction.MuiDialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledDialog = styled(MuiFunction.Dialog)`
  & > div > div {
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
`;
const StyledDivider = styled(MuiFunction.Divider)`
  margin: 10px 0 10px 0 !important;
`;
const StyledGrid = styled(MuiFunction.Grid)`
  width: 100% !important;
`;

export interface Props {
  open: boolean;
  url: string;
  onClose: () => void;
}

interface DialogTitleI {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

interface Value {
  numPages: number;
}

const DialogTitle = (props: DialogTitleI): JSX.Element => {
  return (
    <StyledMuiDialogTitle disableTypography>
      <MuiFunction.Typography variant="h6">{props.children}</MuiFunction.Typography>
      {props.onClose ? (
        <MuiFunction.IconButton aria-label="close" onClick={props.onClose}>
          <MuiFunction.CloseIcon />
        </MuiFunction.IconButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};

const ViewReports = (props: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    return props.onClose();
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props]);

  return (
    <StyledDialog
      onClose={handleClose}
      maxWidth={'md'}
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="paper"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {'View Report'}
      </DialogTitle>
      <StyledDialogContent dividers>
        <MuiFunction.Grid container direction="row" spacing={3}>
          <MuiFunction.Grid item xs>
            <iframe src={props.url} width={900} height={700}></iframe>
          </MuiFunction.Grid>
        </MuiFunction.Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <MuiFunction.Button onClick={handleClose} variant="contained" color="primary">
          Close
        </MuiFunction.Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ViewReports;
