import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import CreateOrder from './CreateOrder';
// Custom Components
import { StyledMainContent } from '../../Css';
import { getPatientOrders, removeNewOrderIdAction } from '../../Stores/Actions';
import { patientDetailsForm, getPatientOrderDetails } from '../../Stores/Selectors';
import SuccessDialog from './SuccessDialog';
import OrderHistory from './OrderHistory';
import { Orders, OrderData } from '../../Stores/Types/patientOrders';
import ViewOrder from './ViewOrder';
import ViewAllOrders from './ViewAllOrders';

function PatientOrders(): JSX.Element {
  const dispatch = useDispatch();
  const patientInfo = useSelector(patientDetailsForm);
  const patientOrdersDetails = useSelector(getPatientOrderDetails);
  const [getOrderData, setOrderData] = useState<Orders[]>([]);
  const [showOrderDetails, setShowOrderDetails] = useState<OrderData | null>(null);
  const onCloseSuccessDialog = () => {
    // dispatch and make null for newOrderId
    dispatch(removeNewOrderIdAction());
  };

  const handleDetailsClose = () => {
    setShowOrderDetails(null);
  };
  const handleShowOrder = (orderData: Orders) => {
    if (patientOrdersDetails && patientOrdersDetails.data) {
      const data = patientOrdersDetails.data.filter(item=>{
        if(item.orders.order_id === orderData.order_id) return item;
      });
      if (data.length) setShowOrderDetails(data[0]);
    }
  }
  // useEffect
  useEffect(() => {
    if (patientInfo && patientInfo.patient_id) {
      dispatch(getPatientOrders(patientInfo.patient_id));
    }
  }, [patientInfo]);

  useEffect(() => {
    const data = _.map(patientOrdersDetails.data, item=>{
      return item.orders;
    });
    setOrderData(data);
  }, [patientOrdersDetails.data]);

  return (
    <StyledMainContent>
      {showOrderDetails? <ViewOrder orderData={showOrderDetails} handleDetailsClose={handleDetailsClose} />: null}
      <CreateOrder />
      <SuccessDialog
        onClose={onCloseSuccessDialog}
        open={patientOrdersDetails && patientOrdersDetails.newOrderId ? true : false}
        order_id={
          patientOrdersDetails && patientOrdersDetails.newOrderId
            ? patientOrdersDetails.newOrderId
            : null
        }
      />
    </StyledMainContent>
  );
}

export {PatientOrders, ViewAllOrders};
