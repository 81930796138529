import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Paper,
  Input,
  InputAdornment,
  FormControl,
  IconButton,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import {
  SendSharp,
  AccountCircleSharp,
  LockSharp,
  Visibility,
  VisibilityOff,
  LoopSharp,
} from '@material-ui/icons';
//
import { loginWithFirebase } from '../../Stores/Actions';
import { loginError, isLoadingLogin } from '../../Stores/Selectors';

// styeld
const StyledPaper = styled(Paper)`
  margin-top: 100px;
  padding: 30px;
`;

const StyledDiv = styled.div`
  margin: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledRootDiv = styled.div`
  height: 100vh;
  background: rgb(9, 0, 163);
  background: linear-gradient(
    60deg,
    rgba(9, 0, 163, 1) 0%,
    rgba(213, 0, 250, 1) 0%,
    rgba(1, 171, 205, 1) 100%
  );
`;

const StyledErrorTypography = styled(Typography)`
  color: #f44336;
`;

// interface
interface LoginTempI {
  email: string;
  password: string;
  errorMessage: string;
  emailError: boolean;
  passwordError: boolean;
  showPassword: boolean;
}

//
export default function Login(): JSX.Element {
  //
  const dispatch = useDispatch();

  const loginErrorData = useSelector(loginError);
  const isLoading = useSelector(isLoadingLogin);
  // const isLoggedInUser = useSelector(isLoggedIn);

  const [values, setValues] = React.useState<LoginTempI>({
    email: '',
    password: '',
    errorMessage: '',
    emailError: false,
    passwordError: false,
    showPassword: false,
  });

  const handleChange = (prop: keyof LoginTempI) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorMessage: '',
      emailError: false,
      passwordError: false,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const submitLogin = () => {
    console.log(values.email, values.password);
    if (!values.email && !values.password) {
      setValues({
        ...values,
        errorMessage: "Email & Password can't be empty",
        emailError: true,
        passwordError: true,
      });
      return;
    } else if (!values.email) {
      setValues({
        ...values,
        errorMessage: "Email can't be empty",
        emailError: true,
        passwordError: false,
      });
      return;
    } else if (!values.password) {
      setValues({
        ...values,
        errorMessage: "Password can't be empty",
        emailError: false,
        passwordError: true,
      });
      return;
    } else {
      // dispatch login api
      dispatch(loginWithFirebase(values.email, values.password));
    }
  };
  //
  return (
    <StyledRootDiv>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <StyledPaper>
            <div style={{ textAlign: 'center' }}>
              <h3 style={{ textTransform: 'uppercase' }}>Niramaya</h3>
              <h4 style={{ fontWeight: 500 }}>Panchyog Ayurveda</h4>
            </div>
            <form>
              <StyledDiv>
                <FormControl>
                  <Input
                    error={values.emailError || loginErrorData.param === 'email'}
                    id="input-email"
                    type={'email'}
                    value={values.email}
                    onChange={handleChange('email')}
                    placeholder={'Email'}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircleSharp />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton style={{ visibility: 'hidden' }}>
                          <VisibilityOff />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledDiv>
              <StyledDiv>
                <FormControl>
                  <Input
                    error={values.passwordError || loginErrorData.param === 'password'}
                    id="input-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange('password')}
                    placeholder={'Password'}
                    startAdornment={
                      <InputAdornment position="start">
                        <LockSharp />
                      </InputAdornment>
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </StyledDiv>
              <StyledDiv>
                <StyledButton
                  variant="contained"
                  color="primary"
                  endIcon={isLoading ? <LoopSharp /> : <SendSharp />}
                  onClick={submitLogin}
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? 'Loading' : 'Login'}
                </StyledButton>
              </StyledDiv>
            </form>
            {loginErrorData.errorText || values.errorMessage ? (
              <StyledDiv>
                <StyledErrorTypography variant="body1">
                  {loginErrorData.errorText || values.errorMessage}
                </StyledErrorTypography>
              </StyledDiv>
            ) : null}
          </StyledPaper>
        </Grid>
      </Grid>
    </StyledRootDiv>
  );
}
