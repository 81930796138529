import React, { useState, useEffect } from 'react';
import { StyledComponentHeader, StyledDiv, StyledPaper } from '../Helpers';
import { useSelector } from 'react-redux';
import { getLoginUserData, patientDetailsForm } from '../../Stores/Selectors';
import { MuiFunction } from '../../Css';
import Address from '../Address';

const AddressList: React.FC = () => {
  const [userId, setUserId] = useState<number>();
  const [patientId, setPatientId] = useState<number>();
  // selectors and dispatches
  // const dispatch = useDispatch();
  const loginUserData = useSelector(getLoginUserData);
  const patientInfo = useSelector(patientDetailsForm);

  const selectAddr = () => {};

  useEffect(() => {
    if (patientInfo && patientInfo.patient_id) {
      setPatientId(patientInfo.patient_id);
    }
  }, [patientInfo]);
  useEffect(() => {
    if (loginUserData && loginUserData.user_id) {
      setUserId(loginUserData.user_id);
    }
  }, [loginUserData]);
  //
  return (
    <StyledDiv>
      <StyledComponentHeader variant="outlined" position="relative">
        <MuiFunction.Toolbar style={{ minHeight: '35px' }} variant="dense">
          <MuiFunction.Typography component="h2" style={{ flex: 1 }}>
            Add Address
          </MuiFunction.Typography>
        </MuiFunction.Toolbar>
      </StyledComponentHeader>
      <StyledPaper variant="outlined">
        {userId && patientId ? (
          <Address patient_id={patientId} user_id={userId} selectedAddress={selectAddr} />
        ) : null}
      </StyledPaper>
    </StyledDiv>
  );
};

export default AddressList;
