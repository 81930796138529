import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from '../Actions';
// import types
import { Login } from '../Types/login';

//
const initialState: Login = {
  isLoggedIn: false,
  isLoading: false,
  errorData: null,
};

export default (state = initialState, action: any) => {
  // console.log(state, action);

  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGIN_SUCCESS:
      // console.log(action.data);
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        errorData: null,
        userData: action.data,
      };
    case LOGIN_ERROR:
      // console.log(action.error);
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      // console.log(state);
      return state;
  }
};

// export default loggedReducer;
