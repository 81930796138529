import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MuiFunction } from '../../Css';
import { firebaseLogOut } from '../../Services/firebase';
import { logout } from '../../Stores/Actions';
import { localTokenClear } from '../../Utils';

interface Props {
  open: boolean;
  handleLogout: () => void;
}

export default function LogoutDialog(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleClose = (val: boolean) => {
    if (val) {
      dispatch(logout());
      firebaseLogOut();
      localTokenClear();
    };
    props.handleLogout();
    setOpen(false);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props]);

  return (
    <MuiFunction.Dialog
      open={open}
      maxWidth={'xs'}
      fullWidth={true}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <MuiFunction.MuiDialogTitle id="alert-dialog-title">{'Logout'}</MuiFunction.MuiDialogTitle>
      <MuiFunction.MuiDialogContent>
        <MuiFunction.MuiDialogContentText id="alert-dialog-description">
          Are you sure?
        </MuiFunction.MuiDialogContentText>
      </MuiFunction.MuiDialogContent>
      <MuiFunction.MuiDialogActions>
        <MuiFunction.Button onClick={() => handleClose(false)}>{'No'}</MuiFunction.Button>
        <MuiFunction.Button onClick={() => handleClose(true)} autoFocus>
          {'Yes'}
        </MuiFunction.Button>
      </MuiFunction.MuiDialogActions>
    </MuiFunction.Dialog>
  );
}
