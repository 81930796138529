import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import {
  Typography,
  Toolbar,
  Paper,
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { StyledComponentHeader } from '../Helpers';
import { SaveButton } from '../../Css';
// utils
import { BMI, findDiffObject, stringToBoolean } from '../../Utils';
// selectores
import {
  isLoadingPatientDetails,
  getLoginUserData,
} from '../../Stores/Selectors';
// actions
import { createPatient, updatePatientInfo } from '../../Stores/Actions';
import { BasePatientData } from '../../Stores/Types/patientDetails';
import Constants from '../../Constants';
import { useSnackbar } from 'notistack';
// Styled Components
const StyledDiv = styled.div`
  margin: 10px 0;
`;
//
const StyledPaper = styled(Paper)`
  padding: 10px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
`;
//
const StyledTextField = styled(TextField)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 100% !important;
  & > div > input {
    padding: 20px 12px 5px !important;
  }
  & > div > div {
    padding: 20px 12px 5px !important;
  }
  & > div > input[type=number]{
    '-moz-appearance': 'textfield';
  }
  & > div > input[type=number]::-webkit-outer-spin-button {
      '-webkit-appearance': 'none';
      margin: 0;
  }
  & > div > input[type=number]::-webkit-inner-spin-button {
      '-webkit-appearance': 'none';
      margin: 0;
  }
`;

// interfaces
interface LocalState {
  mobile: string;
  mobile2: string;
  mobile3: string;
  name: string;
  gender: string;
  email: string;
  mobile_code: string;
  bp: boolean;
  sugar: boolean;
  thyroid: boolean;
  is_married: boolean;
  age: number | any;
  weight: number | any;
  height: number | any;
  bmi: number;
  care_of: string;
  source: string;
  location_name: string;
  patient_id: string;
  panchyog_id: string;
}

//
const refData = [
  {
    _id: 'justdial',
    name: 'Justdial',
  },
  {
    _id: 'google',
    name: 'Google',
  },
  {
    _id: 'faceboook',
    name: 'Facebook',
  },
  {
    _id: 'twitter',
    name: 'Twitter',
  },
  {
    _id: 'word_of_mouth',
    name: 'Word of mouth',
  },
  {
    _id: 'ref_id',
    name: 'Reference',
  },
  {
    _id: 'other',
    name: 'Other',
  },
];

const locations = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu',
  'Jharkhand',
  'Karnataka',
  'Kashmir',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
];

const initialState = {
  mobile: '',
  mobile2: '',
  mobile3: '',
  name: '',
  gender: '',
  email: '',
  mobile_code: '+91',
  bp: false,
  sugar: false,
  thyroid: false,
  is_married: false,
  age: '',
  weight: '',
  height: '',
  bmi: 0,
  care_of: '',
  source: '',
  location_name: '',
  patient_id: '',
  panchyog_id: '',
};

interface Props {
  ptDetails: LocalState | null;
}

// Default export function
const PatientInfo: React.FC<Props> = (props: Props) => {
  const { Config } = Constants;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // selectors
  const isLoading = useSelector(isLoadingPatientDetails);
  const loginUserData = useSelector(getLoginUserData);
  // set bmi
  const [bmiValue, setBmiValue] = React.useState(0);
  // local values
  const [values, setValues] = React.useState<LocalState>(initialState);

  // handle input changes
  const handleChange = (prop: keyof LocalState) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (values) {
      // validate input value
      let value: any = event.target.value;
      if (['bp', 'sugar', 'thyroid', 'is_married'].indexOf(prop) > -1) {
        value = stringToBoolean(value);
      } else if (['age', 'height', 'weight'].indexOf(prop) > -1 && value) {
        value = prop === 'height' ? parseFloat(value) : parseInt(value);
      }
      // set values
      setValues({ ...values, [prop]: value });
    }
  };

  const getBmi = () => {
    if (values) {
      const weight = values.weight;
      const height = values.height;
      if (weight && height) {
        const ht = (height + '').split('.');
        const ft = ht[0];
        const inch = ht[1] ? parseInt(ht[1]) : 0;
        const bmi = BMI(weight, parseInt(ft), inch);
        setBmiValue(bmi.bmi);
      } else {
        setBmiValue(0);
      }
    }
  };

  // save info button
  const saveInfo = (_e: any) => {
    if (
      values &&
      values.name &&
      values.mobile &&
      values.age &&
      values.height &&
      values.weight &&
      values.gender &&
      values.source &&
      values.location_name &&
      loginUserData
    ) {
      // check is new or old.
      if (
        _.isEmpty(props.ptDetails) &&
        loginUserData?.user_id &&
        loginUserData?.clinic_location_id
      ) {
        // new patient
        const mobile = [];
        if (values.mobile) mobile.push(values.mobile);
        if (values.mobile2) mobile.push(values.mobile2);
        if (values.mobile3) mobile.push(values.mobile3);
        // craete new patient. Trigger action
        const payload: BasePatientData = {
          user_id: loginUserData?.user_id, // id of doctor or creator
          clinic_location_id: loginUserData?.clinic_location_id,
          name: values.name,
          gender: values.gender,
          email: values.email,
          mobile: mobile,
          mobile_country_code: values.mobile_code,
          care_of: values.care_of,
          location_name: values.location_name,
          source: values.source,
          general_details: {
            age: values.age,
            weight: values.weight,
            height: values.height,
            bmi: bmiValue,
            bp: values.bp,
            sugar: values.sugar,
            thyroid: values.thyroid,
            is_married: values.is_married,
          },
        };
        console.log('createPatient', payload);
        dispatch(createPatient(payload));
      } else {
        // old patient
        // update patient information. Trigger action
        const mobile = [];
        if (values.mobile) mobile.push(values.mobile);
        if (values.mobile2) mobile.push(values.mobile2);
        if (values.mobile3) mobile.push(values.mobile3);
        const updatedPatientDetails = {
          name: values.name,
          mobile: mobile,
          gender: values.gender,
          email: values.email,
          care_of: values.care_of,
          location_name: values.location_name,
          source: values.source,
        };
        // find updated keys.
        const updatedPatientDiff = findDiffObject(updatedPatientDetails, props.ptDetails);

        // Add new general details
        const updatedGeneralDetails = {
          age: values.age,
          weight: values.weight,
          height: values.height,
          bmi: bmiValue,
          bp: values.bp,
          sugar: values.sugar,
          thyroid: values.thyroid,
          is_married: values.is_married,
        };
        // find updated keys.
        const updatedGeneralDetailsDiff = findDiffObject(updatedGeneralDetails, props.ptDetails);
        if (!_.isEmpty(updatedGeneralDetailsDiff)) {
          updatedPatientDiff.general_details = updatedGeneralDetails;
        }
        //
        if (!_.isEmpty(updatedPatientDiff)) {
          // update patient info
          dispatch(
            updatePatientInfo(
              updatedPatientDiff,
              parseInt(values.patient_id),
              loginUserData?.user_id,
            ),
          );
        }
      }
    } else {
      return enqueueSnackbar('Please add complete information.', {
        variant: 'error',
      });
    }
  };
  // update local state with server response data
  useEffect(() => {
    console.log('ptDetails=>>', props.ptDetails);
    if (props.ptDetails && !_.isEmpty(props.ptDetails)) {
      setValues(props.ptDetails);
    } else {
      setValues(initialState);
    }
  }, [props.ptDetails]);

  // once any changes made in values. this function will run.
  useEffect(() => {
    getBmi();
  }, [values]);

  return (
    <StyledDiv>
      <StyledComponentHeader variant="outlined" position="relative">
        <Toolbar style={{ minHeight: '35px' }} variant="dense">
          <Typography component="h2" style={{ flex: 1 }}>
            Patient Info {' | ' + values?.panchyog_id}
          </Typography>
          <SaveButton
            variant="outlined"
            color="primary"
            size="small"
            startIcon={isLoading ? '' : <SaveIcon />}
            onClick={saveInfo}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </SaveButton>
        </Toolbar>
      </StyledComponentHeader>
      <StyledPaper variant="outlined">
        <div>
          <Grid container spacing={1}>
            <Grid item xs>
              <StyledTextField
                label="Name"
                id="filled-size-small-name-1"
                variant="filled"
                autoComplete="nope"
                value={values ? values.name : ''}
                onChange={handleChange('name')}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <StyledTextField
                label="Mobile 1"
                id="filled-size-small-2"
                variant="filled"
                autoComplete="nope"
                value={values ? values.mobile : ''}
                onChange={handleChange('mobile')}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <StyledTextField
                label="Mobile 2"
                id="filled-size-smallm-2"
                variant="filled"
                autoComplete="nope"
                value={values ? values.mobile2 : ''}
                onChange={handleChange('mobile2')}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <StyledTextField
                label="Mobile 3"
                id="filled-size-smallm-2"
                variant="filled"
                autoComplete="nope"
                value={values ? values.mobile3 : ''}
                onChange={handleChange('mobile3')}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <StyledTextField
                id="filled-size-small-3"
                variant="filled"
                autoComplete="nope"
                label="Age"
                type="tel"
                value={values ? values.age : ''}
                onChange={handleChange('age')}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <InputAdornment position="end">Years</InputAdornment>,
                }}
              />
              <StyledTextField
                label="Weight"
                id="filled-size-small-4"
                variant="filled"
                autoComplete="nope"
                type="tel"
                value={values ? values.weight : ''}
                onChange={handleChange('weight')}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs>
              <StyledTextField
                label="Height"
                id="filled-size-small-5"
                variant="filled"
                autoComplete="nope"
                type="number"
                value={values ? values.height : ''}
                onChange={handleChange('height')}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: <InputAdornment position="end">Feet</InputAdornment>,
                }}
              />
              <StyledTextField
                id="filled-label-gender"
                variant="filled"
                label="Gender"
                autoComplete="nope"
                value={values ? values.gender : ''}
                onChange={handleChange('gender')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem key={Config.Gender.MALE} value={Config.Gender.MALE}>
                  Male
                </MenuItem>
                <MenuItem key={Config.Gender.FEMALE} value={Config.Gender.FEMALE}>
                  Female
                </MenuItem>
                <MenuItem key={Config.Gender.OTHER} value={Config.Gender.OTHER}>
                  Other
                </MenuItem>
              </StyledTextField>
              <StyledTextField
                id="filled-label-bp"
                variant="filled"
                label="BP"
                autoComplete="nope"
                value={values ? values.bp : ''}
                onChange={handleChange('bp')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem key={'bp_false'} value={'false'}>
                  NO
                </MenuItem>
                <MenuItem key={'bp_true'} value={'true'}>
                  YES
                </MenuItem>
              </StyledTextField>
              <StyledTextField
                id="filled-label-suger"
                variant="filled"
                label="Suger"
                autoComplete="nope"
                value={values ? values.sugar : ''}
                onChange={handleChange('sugar')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem key={'sugar_false'} value={'false'}>
                  NO
                </MenuItem>
                <MenuItem key={'sugar_true'} value={'true'}>
                  YES
                </MenuItem>
              </StyledTextField>
              <StyledTextField
                id="filled-label-thyroid"
                variant="filled"
                label="Thyroid"
                autoComplete="nope"
                value={values ? values.thyroid : ''}
                onChange={handleChange('thyroid')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem key={'thyroid_false'} value={'false'}>
                  NO
                </MenuItem>
                <MenuItem key={'thyroid_true'} value={'true'}>
                  YES
                </MenuItem>
              </StyledTextField>
              <StyledTextField
                id="filled-label-is_married"
                variant="filled"
                label="Marital Status"
                autoComplete="nope"
                value={values ? values.is_married : ''}
                onChange={handleChange('is_married')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                <MenuItem key={'married_false'} value={'false'}>
                  Unmarried
                </MenuItem>
                <MenuItem key={'married_true'} value={'true'}>
                  Married
                </MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item xs>
              <StyledTextField
                id="filled-label-source"
                variant="filled"
                label="Source"
                autoComplete="nope"
                value={values ? values.source : ''}
                onChange={handleChange('source')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                {refData.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.name}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                id="filled-label-state"
                variant="filled"
                label="Location"
                autoComplete="nope"
                value={values ? values.location_name : ''}
                onChange={handleChange('location_name')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                {locations.map((v, i) => (
                  <MenuItem key={'location_' + i} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </StyledTextField>
              <StyledTextField
                label="Care Of"
                id="filled-size-small-care-of-1"
                variant="filled"
                autoComplete="nope"
                value={values ? values.care_of : ''}
                onChange={handleChange('care_of')}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <StyledTextField
                label="Email"
                id="filled-size-small-3"
                variant="filled"
                autoComplete="nope"
                value={values ? values.email : ''}
                onChange={handleChange('email')}
                InputProps={{
                  disableUnderline: true,
                }}
              />
              <StyledTextField
                label="BMI"
                id="filled-size-small-4"
                variant="filled"
                autoComplete="nope"
                value={bmiValue}
                onChange={handleChange('bmi')}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </StyledPaper>
    </StyledDiv>
  );
};

export default PatientInfo;
