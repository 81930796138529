import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { OrderData } from '../../Stores/Types/patientOrders';
import Constants from '../../Constants';
import { useSnackbar } from 'notistack';
import { styled, MuiFunction, StyledTextField } from '../../Css';
import { updatePatientOrders } from '../../Stores/Actions';

const StyledDialogContent = styled(MuiFunction.MuiDialogContent)`
  padding: 10px !important;
`;
const StyledDialogActions = styled(MuiFunction.MuiDialogActions)`
  margin: 0px !important;
  padding: 10px !important;
`;

const StyledMuiDialogTitle = styled(MuiFunction.MuiDialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledDialog = styled(MuiFunction.Dialog)`
  & > div > div {
    position: absolute;
    top: 50px;
    bottom: 30px;
  }
`;
const StyledDivider = styled(MuiFunction.Divider)`
  margin: 10px 0 10px 0 !important;
`;
const StyledGrid = styled(MuiFunction.Grid)`
  width: 100% !important;
`;
const StyledTextFieldLocal = styled(MuiFunction.TextField)`
  z-index: 0;
  & > div > input {
    padding: 5px !important;
  }
`;

const StyledStrong = styled.strong`
  font-size: 13px;
`;

export interface DialogTitleI {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = (props: DialogTitleI): JSX.Element => {
  return (
    <StyledMuiDialogTitle disableTypography>
      <MuiFunction.Typography variant="h6">{props.children}</MuiFunction.Typography>
      {props.onClose ? (
        <MuiFunction.IconButton aria-label="close" onClick={props.onClose}>
          <MuiFunction.CloseIcon />
        </MuiFunction.IconButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};

interface Props {
  orderData: OrderData;
  handleDetailsClose: () => void;
}

interface PayloadState {
  courier_name: string;
  tracking_id: string;
  courier_amount: string;
  status: string;
}

const ViewOrderDetails = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { Config } = Constants;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [getPayload, setPayload] = useState<PayloadState>({
    courier_name: '',
    tracking_id: '',
    courier_amount: '',
    status: '',
  });
  const handleClose = () => {
    return props.handleDetailsClose();
  };

  const handleChange = (prop: keyof PayloadState) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPayload({ ...getPayload, [prop]: event.target.value });
  };

  const handleSave = () => {
    // for courier update
    if (
      props.orderData.orders &&
      props.orderData.orders.delivery_type === Config.DeliveryType.COURIER
    ) {
      if (getPayload.courier_amount && getPayload.courier_name && getPayload.tracking_id) {
        dispatch(updatePatientOrders(props.orderData.orders.panchyog_order_id, getPayload));
      } else if (!getPayload.courier_amount) {
        return enqueueSnackbar(`Please add courier amount.`, {
          variant: 'error',
        });
      } else if (!getPayload.courier_name) {
        return enqueueSnackbar(`Please add courier name.`, {
          variant: 'error',
        });
      } else if (!getPayload.tracking_id) {
        return enqueueSnackbar(`Please add trackingID.`, {
          variant: 'error',
        });
      }
    }
    // for store pickup update
    if (
      props.orderData.orders &&
      props.orderData.orders.delivery_type === Config.DeliveryType.PICKED_UP
    ) {
      dispatch(
        updatePatientOrders(props.orderData.orders.panchyog_order_id, {
          status: getPayload.status,
        }),
      );
    }
  };

  // set open dialog
  useEffect(() => {
    //  set default days
    if (props.orderData) {
      setOpen(true);
      setPayload({
        courier_name: props.orderData.orders.courier_name
          ? props.orderData.orders.courier_name
          : '',
        tracking_id: props.orderData.orders.tracking_id ? props.orderData.orders.tracking_id : '',
        courier_amount: props.orderData.orders.courier_amount
          ? props.orderData.orders.courier_amount.toString()
          : '',
        status: props.orderData.orders.status ? props.orderData.orders.status : '',
      });
    } else {
      setOpen(false);
    }
  }, [props.orderData]);

  return (
    <StyledDialog
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        View Order Details
      </DialogTitle>
      <StyledDialogContent dividers>
        <MuiFunction.Grid container direction="row" spacing={3}>
          <MuiFunction.Grid item xs>
            <p>
              <StyledStrong>OrderID</StyledStrong>
            </p>
            <p>{props.orderData.orders.panchyog_order_id}</p>
          </MuiFunction.Grid>
          <MuiFunction.Grid item xs>
            <p>
              <StyledStrong>Date</StyledStrong>
            </p>
            <p>{moment(props.orderData.orders.created_datetime).format('DD/MM/YYYY')}</p>
          </MuiFunction.Grid>
          <MuiFunction.Grid item xs>
            <p>
              <StyledStrong>Days</StyledStrong>
            </p>
            <p>{props.orderData.orders.days}</p>
          </MuiFunction.Grid>
        </MuiFunction.Grid>
        <MuiFunction.Grid container direction="row" spacing={3}>
          <MuiFunction.Grid item xs>
            <p>
              <StyledStrong>Status</StyledStrong>
            </p>
            <StyledTextField
              id="filled-label-status"
              variant="filled"
              label="Status"
              autoComplete="nope"
              value={getPayload.status}
              onChange={handleChange('status')}
              InputProps={{
                disableUnderline: true,
              }}
              select
            >
              {_.values(Config.DeliveryStatus).map((item) => {
                return (
                  <MuiFunction.MenuItem key={item} value={item}>
                    {item}
                  </MuiFunction.MenuItem>
                );
              })}
            </StyledTextField>
          </MuiFunction.Grid>
          <MuiFunction.Grid item xs>
            <p>
              <StyledStrong>DeliveryType</StyledStrong>
            </p>
            {props.orderData.orders.delivery_type === Config.DeliveryType.COURIER ? (
              <StyledTextField
                id="filled-label-courier_name"
                variant="filled"
                label="CourierName"
                autoComplete="nope"
                value={getPayload.courier_name}
                onChange={handleChange('courier_name')}
                InputProps={{
                  disableUnderline: true,
                }}
                select
              >
                {_.values(Config.CourierNames).map((item) => {
                  return (
                    <MuiFunction.MenuItem key={item} value={item}>
                      {item}
                    </MuiFunction.MenuItem>
                  );
                })}
              </StyledTextField>
            ) : (
              <p>{'Picked up'}</p>
            )}
          </MuiFunction.Grid>
          <MuiFunction.Grid item xs>
            {props.orderData.orders.delivery_type === Config.DeliveryType.COURIER ? (
              <React.Fragment>
                <p>
                  <StyledStrong>TrackingID</StyledStrong>
                </p>
                <StyledTextField
                  label="TrackingID"
                  id="filled-size-small-name-1"
                  variant="filled"
                  autoComplete="nope"
                  value={getPayload.tracking_id}
                  onChange={handleChange('tracking_id')}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </React.Fragment>
            ) : null}
          </MuiFunction.Grid>
          <MuiFunction.Grid item xs>
            {props.orderData.orders.delivery_type === Config.DeliveryType.COURIER ? (
              <React.Fragment>
                <p>
                  <StyledStrong>{`CourierCharge(₹)`}</StyledStrong>
                </p>
                <StyledTextField
                  label="Courier Charge"
                  id="filled-size-small-name-courier_amount"
                  variant="filled"
                  autoComplete="nope"
                  value={getPayload.courier_amount}
                  onChange={handleChange('courier_amount')}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </React.Fragment>
            ) : null}
          </MuiFunction.Grid>
        </MuiFunction.Grid>
        <StyledDivider />
        <MuiFunction.Grid container direction="row" spacing={3}>
          <StyledGrid item md={12}>
            <MuiFunction.Typography variant="h6">{'Ayurvedic Medicine'}</MuiFunction.Typography>
            <StyledDivider />
            {props.orderData.order_ayurvedic_mixers.map((item) => {
              if (item.category === Config.ProductCategory.CHURNA && item.quantity) {
                return (
                  <React.Fragment key={`odr-ch-${item.product_id}`}>
                    <MuiFunction.Grid container spacing={1}>
                      <MuiFunction.Grid item xs={9}>
                        {item.name}
                      </MuiFunction.Grid>
                      <MuiFunction.Grid item xs={3}>
                        <StyledTextFieldLocal
                          label="Gm"
                          id="outlined-start-adornment"
                          variant="outlined"
                          value={item.quantity}
                          disabled={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiFunction.Grid>
                    </MuiFunction.Grid>
                    <StyledDivider />
                  </React.Fragment>
                );
              }
            })}
            {props.orderData.order_ayurvedic_mixers.map((item) => {
              if (item.category === Config.ProductCategory.TABLET && item.quantity) {
                return (
                  <React.Fragment key={`odr-tb-${item.product_id}`}>
                    <MuiFunction.Grid container spacing={1}>
                      <MuiFunction.Grid item xs={9}>
                        {item.name}
                      </MuiFunction.Grid>
                      <MuiFunction.Grid item xs={3}>
                        <StyledTextFieldLocal
                          label="Qty"
                          id="outlined-start-adornment"
                          variant="outlined"
                          value={item.quantity}
                          disabled={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </MuiFunction.Grid>
                    </MuiFunction.Grid>
                    <StyledDivider />
                  </React.Fragment>
                );
              }
            })}
          </StyledGrid>
        </MuiFunction.Grid>
        <StyledDivider />
        <MuiFunction.Grid container direction="row" spacing={3}>
          <StyledGrid item md>
            <MuiFunction.Typography variant="h6">{'Products'}</MuiFunction.Typography>
            <StyledDivider />
            {props.orderData.order_products.map((item, index) => {
              return (
                <React.Fragment key={`odr-pd-${index}`}>
                  <MuiFunction.Grid container spacing={1}>
                    <MuiFunction.Grid item xs={6}>
                      {item.name}
                    </MuiFunction.Grid>
                    <MuiFunction.Grid item xs={3}>
                      <StyledTextFieldLocal
                        label="MRP"
                        id="outlined-start-adornment"
                        variant="outlined"
                        value={item.unit_price}
                        disabled={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </MuiFunction.Grid>
                    <MuiFunction.Grid item xs={3}>
                      <StyledTextFieldLocal
                        label="Qty"
                        id="outlined-start-adornment"
                        variant="outlined"
                        value={item.unit}
                        disabled={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </MuiFunction.Grid>
                  </MuiFunction.Grid>
                  <StyledDivider />
                </React.Fragment>
              );
            })}
          </StyledGrid>
        </MuiFunction.Grid>
        <StyledDivider />
        <MuiFunction.Grid container direction="row" spacing={3}>
          <StyledGrid item md>
            <MuiFunction.Typography variant="h6">{'Amount (₹)'}</MuiFunction.Typography>
            <StyledDivider />
            <MuiFunction.Grid container spacing={1}>
              <MuiFunction.Grid item xs={9}>
                {'Amount for Ayurvedic Aushadhi'}
              </MuiFunction.Grid>
              <MuiFunction.Grid item xs={3}>
                <StyledTextFieldLocal
                  label="Amount (₹)"
                  id="outlined-start-adornment"
                  variant="outlined"
                  value={props.orderData.orders.ayurvedic_mixer_amount}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiFunction.Grid>
            </MuiFunction.Grid>
            <StyledDivider />
            <MuiFunction.Grid container spacing={1}>
              <MuiFunction.Grid item xs={9}>
                {'Amount for Products'}
              </MuiFunction.Grid>
              <MuiFunction.Grid item xs={3}>
                <StyledTextFieldLocal
                  label="Amount (₹)"
                  id="outlined-start-adornment"
                  variant="outlined"
                  value={
                    props.orderData.orders.payable_amount -
                    props.orderData.orders.ayurvedic_mixer_amount
                  }
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiFunction.Grid>
            </MuiFunction.Grid>
            <StyledDivider />
            <MuiFunction.Grid container spacing={1}>
              <MuiFunction.Grid item xs={9}>
                <strong>{'Total Payable Amount'}</strong>
              </MuiFunction.Grid>
              <MuiFunction.Grid item xs={3}>
                <StyledTextFieldLocal
                  label="Amount (₹)"
                  id="outlined-start-adornment"
                  variant="outlined"
                  value={props.orderData.orders.payable_amount}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiFunction.Grid>
            </MuiFunction.Grid>
          </StyledGrid>
        </MuiFunction.Grid>
        <StyledDivider />
        <MuiFunction.Grid container direction="row" spacing={3}>
          <StyledGrid item md>
            <MuiFunction.Typography variant="h6">{'Address'}</MuiFunction.Typography>
            <StyledDivider />
            <p>{props.orderData.orders.full_address}</p>
          </StyledGrid>
        </MuiFunction.Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <MuiFunction.Button onClick={handleClose} variant="contained" color="primary">
          Close
        </MuiFunction.Button>
        <MuiFunction.Button onClick={handleSave} variant="contained" color="secondary">
          Update
        </MuiFunction.Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ViewOrderDetails;
