import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import store, { history } from './Stores';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { saveToSessionStorage } from './Stores/encyptStore';

if (process.env.NODE_ENV !== 'development') {
  console.log = function no_console() {
    return;
  };
}

interface FirebaseConfig {
  [key: string]: string;
}
const firebaseConfig: FirebaseConfig = {};
if (process.env && process.env.REACT_APP_FIREBASE_API_KEY) {
  firebaseConfig.apiKey = process.env.REACT_APP_FIREBASE_API_KEY as string;
  firebaseConfig.authDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string;
  firebaseConfig.projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID as string;
  firebaseConfig.storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string;
  firebaseConfig.messagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string;
  firebaseConfig.appId = process.env.REACT_APP_FIREBASE_APP_ID as string;
}
// intialize firebase.
const intializeFirebaseApp = initializeApp(firebaseConfig);
getStorage(intializeFirebaseApp);
initializeAppCheck(intializeFirebaseApp, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY as string),
  isTokenAutoRefreshEnabled: true,
});

const theme = {
  brandBlue: '#028ce1',
};

//
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <App />
        </SnackbarProvider>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// store state to session
store.subscribe(() => saveToSessionStorage(store.getState()));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
