import {
  ORDER_INFO_REQUEST,
  ORDER_INFO_SUCCESS,
  ORDER_INFO_ERROR,
  ADD_NEW,
} from '../Actions';

import { OrderInfo } from '../Types/orderInfo';

const initialState: OrderInfo = {
  isLoading: false,
  errorData: null,
  data: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: {},
      };
    case ORDER_INFO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case ORDER_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: action.data,
      };
    case ORDER_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
