import {
  CREATE_PATIENT_PROBLEM_REQUEST,
  CREATE_PATIENT_PROBLEM_SUCCESS,
  CREATE_PATIENT_PROBLEM_ERROR,
  ADD_NEW,
} from '../Actions';
import { PatientProblem } from '../Types/patientProblems';

const initialState: PatientProblem = {
  isLoading: false,
  errorData: null,
  data: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: {},
      };
    case CREATE_PATIENT_PROBLEM_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PATIENT_PROBLEM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: action.data,
      };
    case CREATE_PATIENT_PROBLEM_ERROR:
      // console.log(action.error);
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
