import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ViewPatientList from './ViewPatientList';
import { Patient } from '../../Stores/Types/patient';
import { MuiFunction, StyledMainContent } from '../../Css';
import { postData } from '../../Services/server';
import Constants from '../../Constants';
import { addNewPatient, getPatientInfo, setNavigation } from '../../Stores/Actions';
import { localTokenGet } from '../../Utils';

interface PatientsResult {
  count: number;
  patients: Patient[];
}
interface PatientRes {
  data: PatientsResult;
}

export default function ViewAllPatients(): JSX.Element {
  const dispatch = useDispatch();
  const [getCurrentPage, setCurrentPage] = useState<number>(1);
  const [getResultCount, setResultCount] = useState<number>(0);
  const [getPatientResult, setPatientResult] = useState<Patient[]>([]);
  const [getPerPage, setPerPage] = useState<number>(20);
  const [getSearchKeyword, setSearchKeyword] = useState<string | null>(null);

  const getPatientData = async () => {
    const token = await localTokenGet()
    const dataPayload = {
      page: getCurrentPage,
      per_page: getPerPage,
      search_key: getSearchKeyword,
      order_by: {
        created_datetime: null,
        patient_id: 'DESC',
      },
    };
    postData(`${process.env.REACT_APP_API_BASEURL}/api/v1/patient/fetch`, dataPayload, token)
      .then((res: PatientRes) => {
        setResultCount(Math.ceil(res.data.count / getPerPage));
        setPatientResult(res.data.patients);
      })
      .catch((_err: any) => {
        setCurrentPage(1);
        setResultCount(0);
        setPatientResult([]);
      });
  };
  const handleShowPatient = (patientData: Patient) => {
    console.log('patientData=>', patientData);
    // reset state
    dispatch(addNewPatient());
    // dispatch action to find patient
    dispatch(getPatientInfo(patientData.panchyog_id));
    dispatch(setNavigation(Constants.Config.Navigation.DIAGNOSIS));
  };
  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    getPatientData();
  }, [getCurrentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setTimeout(()=> getPatientData());
  }, [getSearchKeyword]);

  return (
    <StyledMainContent>
      <MuiFunction.Paper>
        <ViewPatientList 
          patientDataList={getPatientResult} 
          showDetails={handleShowPatient} 
          filterData = {setSearchKeyword} 
        />
        <MuiFunction.Pagination
          count={getResultCount}
          page={getCurrentPage}
          onChange={handlePageChange}
        />
      </MuiFunction.Paper>
    </StyledMainContent>
  );
}
