export const TreatmentLine: string[] = [
  'Deepan',
  'Lekhan',
  'Alepa / Lepan',
  'Grahi',
  'Anulomana',
  'Rechan',
  'Langhan',
  'Shukra Stambhan',
  'Bhedan / Sarak',
  'Pachan',
  'Snehana',
  'Brumhana',
  'Rasayana',
  'Sthambana',
  'Daha Prashamana',
  'Stroto Shodhan',
  'Swedana',
  'Medhya',
  'Vajikarana',
  'Vata Shaman',
  'Rukshana',
  'Vedna Sthapak',
  'Pitta Shaman',
  'Upanaha',
  'Bhringan',
  'Kapha Shaman',
];
