import React from 'react';
import { useSelector } from 'react-redux';
// Custom Components
import PatientInfo from './patientInfo';
import Complaints from './complaints';
import PatientHistory from './patientHistory';
import PatientDiagnosis from './patientDiagnosis';
import TreatmentLine from './treatmentLine';
import PatientProblem from './patientProblem';
import { StyledMainContent, MarginDiv } from '../../Css';
// selectores
import {
  patientDetailsForm,
} from '../../Stores/Selectors';
import Address from './Address';

export default function Main(): JSX.Element {
  const ptDetails = useSelector(patientDetailsForm);
  // const loginUserData = useSelector(getLoginUserData);
  return (
    <StyledMainContent>
      <PatientInfo 
        ptDetails={ptDetails}
        // loginUserData={loginUserData}
      />
      <PatientProblem />
      <Complaints />
      <PatientHistory />
      <PatientDiagnosis />
      <TreatmentLine />
      <Address />
      {/* keep margin div as it is. it just to give margin from internal footer */}
      <MarginDiv /> 
    </StyledMainContent>
  );
}
