import {
  API_CALL,
  POST_METHOD,
  GET_METHOD,
  PUT_METHOD,
  DELETE_METHOD,
  FIREBASE_LOGIN,
} from '../Middleware/api';
import { BasePatientData } from '../Types/patientDetails';
// logout
export const RESSET_STATE = 'RESSET_STATE';
export const logout = () => ({
  type: RESSET_STATE,
})
// user login request
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const loginWithFirebase = (email: string, password: string) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR],
    method: FIREBASE_LOGIN,
    endpoint: '',
    data: {
      email,
      password,
    },
  },
});

export const userLogin = (firebaseIdToken: string) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/user/login',
    data: {
      firebaseIdToken,
    },
  },
});

// add new
export const ADD_NEW = 'ADD_NEW';
export const addNewPatient = () => ({
  type: ADD_NEW,
});
// set internal navigation
export const NAVIGATION = 'NAVIGATION';
export const setNavigation = (navigationValue: string) => ({
  type: NAVIGATION,
  value: navigationValue,
});

// create new patient
export const CREATE_PATIENT_REQUEST = 'CREATE_PATIENT_REQUEST';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_ERROR = 'CREATE_PATIENT_ERROR';

export const createPatient = (payload: BasePatientData) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [CREATE_PATIENT_REQUEST, CREATE_PATIENT_SUCCESS, CREATE_PATIENT_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/create',
    data: payload,
  },
});

// update Patient info
export const UPDATE_PATIENT_REQUEST = 'UPDATE_PATIENT_REQUEST';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_ERROR = 'UPDATE_PATIENT_ERROR';
export const updatePatientInfo = (payload: any, patient_id: number, user_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [UPDATE_PATIENT_REQUEST, UPDATE_PATIENT_SUCCESS, UPDATE_PATIENT_ERROR],
    method: PUT_METHOD,
    endpoint: `/api/v1/patient/update/${patient_id}/updatedByuserId/${user_id}`,
    data: payload,
  },
});

// add/create general details
// GD: General Details
export const CREATE_PATIENT_GD_REQUEST = 'CREATE_PATIENT_GD_REQUEST';
export const CREATE_PATIENT_GD_SUCCESS = 'CREATE_PATIENT_GD_SUCCESS';
export const CREATE_PATIENT_GD_ERROR = 'CREATE_PATIENT_GD_ERROR';
export const createGeneralDetails = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [CREATE_PATIENT_GD_REQUEST, CREATE_PATIENT_GD_SUCCESS, CREATE_PATIENT_GD_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/general/details/create',
    data: payload,
  },
});

// Get patient info
export const getPatientInfo = (panchyog_id: string) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [CREATE_PATIENT_REQUEST, CREATE_PATIENT_SUCCESS, CREATE_PATIENT_ERROR],
    method: GET_METHOD,
    endpoint: `/api/v1/patient/${panchyog_id}`,
  },
});

// create Patient problem
export const CREATE_PATIENT_PROBLEM_REQUEST = 'CREATE_PATIENT_PROBLEM_REQUEST';
export const CREATE_PATIENT_PROBLEM_SUCCESS = 'CREATE_PATIENT_PROBLEM_SUCCESS';
export const CREATE_PATIENT_PROBLEM_ERROR = 'CREATE_PATIENT_PROBLEM_ERROR';
export const createPatientProblem = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_PROBLEM_REQUEST,
      CREATE_PATIENT_PROBLEM_SUCCESS,
      CREATE_PATIENT_PROBLEM_ERROR,
    ],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/problem/create',
    data: payload,
  },
});
export const CREATE_PATIENT_PROBLEM_UPDATED = 'CREATE_PATIENT_PROBLEM_UPDATED';
export const updatePatientProblem = (patientProblemId: number, payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_PROBLEM_REQUEST,
      CREATE_PATIENT_PROBLEM_SUCCESS,
      CREATE_PATIENT_PROBLEM_ERROR,
    ],
    method: PUT_METHOD,
    endpoint: '/api/v1/patient/problem/update/' + patientProblemId,
    data: payload,
  },
});
export const fetchPatientProblem = (patient_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_PROBLEM_REQUEST,
      CREATE_PATIENT_PROBLEM_SUCCESS,
      CREATE_PATIENT_PROBLEM_ERROR,
    ],
    method: GET_METHOD,
    endpoint: '/api/v1/patient/problem/' + patient_id,
  },
});

// create patient complaints
export const CREATE_PATIENT_COMPLAINT_REQUEST = 'CREATE_PATIENT_COMPLAINT_REQUEST';
export const CREATE_PATIENT_COMPLAINT_SUCCESS = 'CREATE_PATIENT_COMPLAINT_SUCCESS';
export const CREATE_PATIENT_COMPLAINT_ERROR = 'CREATE_PATIENT_COMPLAINT_ERROR';
export const createPatientComplaints = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_COMPLAINT_REQUEST,
      CREATE_PATIENT_COMPLAINT_SUCCESS,
      CREATE_PATIENT_COMPLAINT_ERROR,
    ],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/complaint/create',
    data: payload,
  },
});

export const CREATE_PATIENT_COMPLAINT_DELETED = 'CREATE_PATIENT_COMPLAINT_DELETED';
export const deletePatientComplaints = (complaint_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_COMPLAINT_REQUEST,
      CREATE_PATIENT_COMPLAINT_DELETED,
      CREATE_PATIENT_COMPLAINT_ERROR,
    ],
    method: DELETE_METHOD,
    endpoint: '/api/v1/patient/complaint/delete/' + complaint_id,
  },
});
// fetch complaint by problem id
export const fetchPatientComplaints = (patient_problem_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_COMPLAINT_REQUEST,
      CREATE_PATIENT_COMPLAINT_SUCCESS,
      CREATE_PATIENT_COMPLAINT_ERROR,
    ],
    method: GET_METHOD,
    endpoint: '/api/v1/patient/complaint/problemId/' + patient_problem_id,
  },
});

// create patient history
export const CREATE_PATIENT_HISTORY_REQUEST = 'CREATE_PATIENT_HISTORY_REQUEST';
export const CREATE_PATIENT_HISTORY_SUCCESS = 'CREATE_PATIENT_HISTORY_SUCCESS';
export const CREATE_PATIENT_HISTORY_ERROR = 'CREATE_PATIENT_HISTORY_ERROR';
export const createPatientHistory = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_HISTORY_REQUEST,
      CREATE_PATIENT_HISTORY_SUCCESS,
      CREATE_PATIENT_HISTORY_ERROR,
    ],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/history/create',
    data: payload,
  },
});

export const CREATE_PATIENT_HISTORY_UPDATED = 'CREATE_PATIENT_HISTORY_UPDATED';
export const updatePatientHistory = (history_id: number, payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_HISTORY_REQUEST,
      CREATE_PATIENT_HISTORY_UPDATED,
      CREATE_PATIENT_HISTORY_ERROR,
    ],
    method: PUT_METHOD,
    endpoint: '/api/v1/patient/history/update/' + history_id,
    data: payload,
  },
});
// fetch history by problem id
export const fetchPatientHistory = (patient_problem_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_HISTORY_REQUEST,
      CREATE_PATIENT_HISTORY_SUCCESS,
      CREATE_PATIENT_HISTORY_ERROR,
    ],
    method: GET_METHOD,
    endpoint: '/api/v1/patient/history/problemId/' + patient_problem_id,
  },
});

// create patient Diagnosis
export const CREATE_PATIENT_DIAGNOSIS_REQUEST = 'CREATE_PATIENT_DIAGNOSIS_REQUEST';
export const CREATE_PATIENT_DIAGNOSIS_SUCCESS = 'CREATE_PATIENT_DIAGNOSIS_SUCCESS';
export const CREATE_PATIENT_DIAGNOSIS_ERROR = 'CREATE_PATIENT_DIAGNOSIS_ERROR';
export const createPatientDiagnosis = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_DIAGNOSIS_REQUEST,
      CREATE_PATIENT_DIAGNOSIS_SUCCESS,
      CREATE_PATIENT_DIAGNOSIS_ERROR,
    ],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/diagnosis/create',
    data: payload,
  },
});

export const CREATE_PATIENT_DIAGNOSIS_DELETED = 'CREATE_PATIENT_DIAGNOSIS_DELETED';
export const deletePatientDiagnosis = (diagnosis_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_DIAGNOSIS_REQUEST,
      CREATE_PATIENT_DIAGNOSIS_DELETED,
      CREATE_PATIENT_DIAGNOSIS_ERROR,
    ],
    method: DELETE_METHOD,
    endpoint: '/api/v1/patient/diagnosis/delete/' + diagnosis_id,
  },
});
// fetch Diagnosis by problem id
export const fetchPatientDiagnosis = (patient_problem_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_DIAGNOSIS_REQUEST,
      CREATE_PATIENT_DIAGNOSIS_SUCCESS,
      CREATE_PATIENT_DIAGNOSIS_ERROR,
    ],
    method: GET_METHOD,
    endpoint: '/api/v1/patient/diagnosis/problemId/' + patient_problem_id,
  },
});

// patient treatment line
export const CREATE_PATIENT_TREATMENT_LINE_REQUEST = 'CREATE_PATIENT_TREATMENT_LINE_REQUEST';
export const CREATE_PATIENT_TREATMENT_LINE_SUCCESS = 'CREATE_PATIENT_TREATMENT_LINE_SUCCESS';
export const CREATE_PATIENT_TREATMENT_LINE_ERROR = 'CREATE_PATIENT_TREATMENT_LINE_ERROR';
export const createPatientTreatmentLine = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_TREATMENT_LINE_REQUEST,
      CREATE_PATIENT_TREATMENT_LINE_SUCCESS,
      CREATE_PATIENT_TREATMENT_LINE_ERROR,
    ],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/treatmentLine/create',
    data: payload,
  },
});

export const CREATE_PATIENT_TREATMENT_LINE_DELETED = 'CREATE_PATIENT_TREATMENT_LINE_DELETED';
export const deletePatientTreatmentLine = (treatmentLineId: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_TREATMENT_LINE_REQUEST,
      CREATE_PATIENT_TREATMENT_LINE_DELETED,
      CREATE_PATIENT_TREATMENT_LINE_ERROR,
    ],
    method: DELETE_METHOD,
    endpoint: '/api/v1/patient/treatmentLine/delete/' + treatmentLineId,
  },
});
// fetch TreatmentLine by problem id
export const fetchPatientTreatmentLine = (patientProblemId: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [
      CREATE_PATIENT_TREATMENT_LINE_REQUEST,
      CREATE_PATIENT_TREATMENT_LINE_SUCCESS,
      CREATE_PATIENT_TREATMENT_LINE_ERROR,
    ],
    method: GET_METHOD,
    endpoint: '/api/v1/patient/treatmentLine/problemId/' + patientProblemId,
  },
});

// Notes
// create notes
export const CREATE_NOTES_REQUEST = 'CREATE_NOTES_REQUEST';
export const CREATE_NOTES_SUCCESS = 'CREATE_NOTES_SUCCESS';
export const CREATE_NOTES_ERROR = 'CREATE_NOTES_ERROR';
export const createNotes = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [CREATE_NOTES_REQUEST, CREATE_NOTES_SUCCESS, CREATE_NOTES_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/notes/create',
    data: payload,
  },
});
// fetch Notes by problem id
export const fetchNotes = (patientProblemId: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [CREATE_NOTES_REQUEST, CREATE_NOTES_SUCCESS, CREATE_NOTES_ERROR],
    method: GET_METHOD,
    endpoint: '/api/v1/patient/notes/problemId/' + patientProblemId,
  },
});

// Products
export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
// fetch all medicine
export const fetchProducts = () => ({
  type: API_CALL,
  [API_CALL]: {
    types: [GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_ERROR],
    method: GET_METHOD,
    endpoint: '/api/v1/products/all',
  },
});

// add order info
export const ORDER_INFO_REQUEST = 'ORDER_INFO_REQUEST';
export const ORDER_INFO_SUCCESS = 'ORDER_INFO_SUCCESS';
export const ORDER_INFO_ERROR = 'ORDER_INFO_ERROR';
export const addOrderInfo = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [ORDER_INFO_REQUEST, ORDER_INFO_SUCCESS, ORDER_INFO_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/order/info/create',
    data: payload,
  },
});
// get order info
export const getOrderInfo = (patient_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [ORDER_INFO_REQUEST, ORDER_INFO_SUCCESS, ORDER_INFO_ERROR],
    method: GET_METHOD,
    endpoint: '/api/v1/order/info/fetch/'+patient_id,
  },
});

// Patient Address
// Add Address
export const PATIENT_ADDRESS_REQUEST = 'PATIENT_ADDRESS_REQUEST';
export const PATIENT_ADDRESS_SUCCESS = 'PATIENT_ADDRESS_SUCCESS';
export const PATIENT_ADDRESS_ERROR = 'PATIENT_ADDRESS_ERROR';
export const savePatientAddress = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [PATIENT_ADDRESS_REQUEST, PATIENT_ADDRESS_SUCCESS, PATIENT_ADDRESS_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/address/create',
    data: payload,
  },
});
// get Address
export const getPatientAddress = (patient_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [PATIENT_ADDRESS_REQUEST, PATIENT_ADDRESS_SUCCESS, PATIENT_ADDRESS_ERROR],
    method: GET_METHOD,
    endpoint: '/api/v1/address/fetch/'+patient_id,
  },
});

// Orders
// Create new order
export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_ERROR = 'ORDER_ERROR';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const createNewOrder = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [ORDER_REQUEST, ORDER_CREATE_SUCCESS, ORDER_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/order/create',
    data: payload,
  },
});
// get Patient Orders
export const getPatientOrders = (patient_id: number) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [ORDER_REQUEST, ORDER_SUCCESS, ORDER_ERROR],
    method: GET_METHOD,
    endpoint: '/api/v1/order/fetch/'+patient_id,
  },
});
// update Patient Orders
export const updatePatientOrders = (panchyog_order_id: string, payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [ORDER_REQUEST, UPDATE_ORDER_SUCCESS, ORDER_ERROR],
    method: PUT_METHOD,
    endpoint: '/api/v1/order/update/'+panchyog_order_id,
    data: payload,
  },
});
// remove newOrderId action
export const NEW_ORDER_REMOVE_ACTION = 'NEW_ORDER_REMOVE_ACTION';
export const removeNewOrderIdAction = () => ({
  type: NEW_ORDER_REMOVE_ACTION,
});

// add callback remider
export const CALLBACK_REMINDER_REQUEST = 'CALLBACK_REMINDER_REQUEST';
export const CALLBACK_REMINDER_SUCCESS = 'CALLBACK_REMINDER_SUCCESS';
export const CALLBACK_REMINDER_ERROR = 'CALLBACK_REMINDER_ERROR';
export const addCallbackReminder = (payload: any) => ({
  type: API_CALL,
  [API_CALL]: {
    types: [CALLBACK_REMINDER_REQUEST, CALLBACK_REMINDER_SUCCESS, CALLBACK_REMINDER_ERROR],
    method: POST_METHOD,
    endpoint: '/api/v1/patient/callbacks/create',
    data: payload,
  },
});



