import {
  CREATE_PATIENT_HISTORY_REQUEST,
  CREATE_PATIENT_HISTORY_SUCCESS,
  CREATE_PATIENT_HISTORY_ERROR,
  CREATE_PATIENT_HISTORY_UPDATED,
  ADD_NEW,
} from '../Actions';

import { PatientTreatmentHistory } from '../Types/patientTreatmentHistory';

const initialState: PatientTreatmentHistory = {
  isLoading: false,
  errorData: null,
  data: {},
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: {},
      };
    case CREATE_PATIENT_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PATIENT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: action.data,
      };
    case CREATE_PATIENT_HISTORY_UPDATED:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          errorData: null,
          data: action.data,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          errorData: 'Patient history not deleted',
        };
      }
    case CREATE_PATIENT_HISTORY_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
