import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { MuiFunction, styled } from '../../Css';
import Logo from '../../Assets/Logo/panchyog-logo-blue.svg';
import { setNavigation } from '../../Stores/Actions';
import Constants from '../../Constants';
import LogoutDialog from './logout';

const StyledDrawer = styled(MuiFunction.Drawer)`
  & > div {
    flex-shrink: 0 !important;
    width: 70px !important;
    overflow: hidden !important;
  }
`;
const StyledHeaderLogo = styled.div`
  width: 70px;
  & > img {
    width: 50px;
    display: block;
    text-align: center;
    position: relative;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
const StyledIconDiv = styled.div`
  font-size: 10px;
  font-weight: 600;
`;
const StyledNavListItem = styled(MuiFunction.ListItem)`
  text-align: center !important;
  padding: 10px 0px !important;
  display: block !important;
`;
const StyledAssessmentOutlinedIcon = styled(MuiFunction.AssessmentOutlinedIcon)`
  color: #028ce1;
`;
const StyledShoppingCartOutlinedIcon = styled(MuiFunction.ShoppingCartOutlinedIcon)`
  color: #028ce1;
`;
const StyledGroupOutlinedIcon = styled(MuiFunction.GroupOutlinedIcon)`
  color: #028ce1;
`;
const StyledCallIcon = styled(MuiFunction.CallIcon)`
  color: #028ce1;
`;
const StyledExitToAppOutlinedIcon = styled(MuiFunction.ExitToAppOutlinedIcon)`
  color: #028ce1;
`;

export default function Left(): JSX.Element {
  const dispatch = useDispatch();
  const [openLogout, setOpenLougout] = useState(false);
  const handleClick = (value: string) => {
    switch (value) {
      case Constants.Config.Navigation.DASHBOARD:
        return dispatch(setNavigation(Constants.Config.Navigation.DASHBOARD));
      case Constants.Config.Navigation.ORDERS_LIST:
        return dispatch(setNavigation(Constants.Config.Navigation.ORDERS_LIST));
      case Constants.Config.Navigation.PATIENT_LIST:
        return dispatch(setNavigation(Constants.Config.Navigation.PATIENT_LIST));
      case Constants.Config.Navigation.CALLBACK_REQUEST:
        return dispatch(setNavigation(Constants.Config.Navigation.CALLBACK_REQUEST));
      case Constants.Config.Navigation.LOG_OUT:
        return setOpenLougout(true);
      default:
        break;
    }
  };
  const handleLogout =() => {
    setOpenLougout(false);
  }
  return (
    <React.Fragment>
      <LogoutDialog open={openLogout} handleLogout = {handleLogout}/>
      <StyledDrawer variant="permanent">
        <StyledHeaderLogo>
          <img alt='Panchyog Ayurveda' src={Logo} />
        </StyledHeaderLogo>
        <MuiFunction.List>
          <MuiFunction.Divider />
          <StyledNavListItem
            onClick={() => handleClick(Constants.Config.Navigation.DASHBOARD)}
            button
          >
            <StyledAssessmentOutlinedIcon />
            <StyledIconDiv>{'Dashboard'}</StyledIconDiv>
          </StyledNavListItem>
          <MuiFunction.Divider />
          <StyledNavListItem
            onClick={() => handleClick(Constants.Config.Navigation.ORDERS_LIST)}
            button
          >
            <StyledShoppingCartOutlinedIcon />
            <StyledIconDiv>{'Orders'}</StyledIconDiv>
          </StyledNavListItem>
          <MuiFunction.Divider />
          <StyledNavListItem
            onClick={() => handleClick(Constants.Config.Navigation.PATIENT_LIST)}
            button
          >
            <StyledGroupOutlinedIcon />
            <StyledIconDiv>{'Patients'}</StyledIconDiv>
          </StyledNavListItem>
          <MuiFunction.Divider />
          <StyledNavListItem
            onClick={() => handleClick(Constants.Config.Navigation.CALLBACK_REQUEST)}
            button
          >
            <StyledCallIcon />
            <StyledIconDiv>{'Callbacks'}</StyledIconDiv>
          </StyledNavListItem>
          <MuiFunction.Divider />
          <StyledNavListItem
            onClick={() => handleClick(Constants.Config.Navigation.LOG_OUT)}
            button
          >
            <StyledExitToAppOutlinedIcon />
            <StyledIconDiv>{'Logout'}</StyledIconDiv>
          </StyledNavListItem>
        </MuiFunction.List>
      </StyledDrawer>
    </React.Fragment>
  );
}
