import _ from 'lodash';
import {
  PATIENT_ADDRESS_REQUEST,
  PATIENT_ADDRESS_SUCCESS,
  PATIENT_ADDRESS_ERROR,
  ADD_NEW,
} from '../Actions';

import { PatientAddress } from '../Types/patientAddress';

const initialState: PatientAddress = {
  isLoading: false,
  errorData: null,
  data: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: [],
      };
    case PATIENT_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case PATIENT_ADDRESS_SUCCESS:
      const data = _.isArray(action.data) ? action.data : [...state.data, action.data];
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data,
      };
    case PATIENT_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
