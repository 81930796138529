import {
  CREATE_PATIENT_DIAGNOSIS_REQUEST,
  CREATE_PATIENT_DIAGNOSIS_SUCCESS,
  CREATE_PATIENT_DIAGNOSIS_ERROR,
  CREATE_PATIENT_DIAGNOSIS_DELETED,
  ADD_NEW,
} from '../Actions';

import { PatientDiagnosis } from '../Types/patientDiagnosis';
import _ from 'lodash';

const initialState: PatientDiagnosis = {
  isLoading: false,
  errorData: null,
  data: [],
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_NEW:
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data: [],
      };
    case CREATE_PATIENT_DIAGNOSIS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PATIENT_DIAGNOSIS_SUCCESS:
      const data = _.isArray(action.data) ? action.data : [...state.data, action.data];
      return {
        ...state,
        isLoading: false,
        errorData: null,
        data,
      };
    case CREATE_PATIENT_DIAGNOSIS_DELETED:
      if (action.data) {
        const arr = action.data.split('/');
        const deletedId = arr[arr.length - 1];
        const data = state.data.filter((item) => item.patient_diagnosis_id !== deletedId);
        return {
          ...state,
          isLoading: false,
          errorData: null,
          data,
        };
      } else {
        return {
          ...state,
          isLoading: false,
          errorData: 'Patient complaint not deleted',
        };
      }
    case CREATE_PATIENT_DIAGNOSIS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorData: action.error,
      };
    default:
      return state;
  }
};
