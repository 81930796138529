import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Constants from '../../Constants';
import { patientDetailsForm } from '../../Stores/Selectors';
import { setNavigation } from '../../Stores/Actions';
// Custom Components
import { StyledMainContent, MuiFunction, styled } from '../../Css';
import { StyledComponentHeader, StyledDiv, StyledPaper } from '../Helpers';
import ReportList from './ReportList';
import Upload from './Upload';
import ViewReports from './ViewFiles';

const StyledDivider = styled(MuiFunction.Divider)`
  margin: 10px 0 10px 0 !important;
`;

const Reports: React.FC = (): JSX.Element => {
  const [newfileUrl, setNewFileUrl] = useState<string>();
  const [panchyogPatientId, setPanchyogPatientId] = useState<string>();
  const [getShowReport, setShowReport] = useState(false);
  const [getReportUrl, setReportUrl] = useState<string>();
  const { Config } = Constants;
  const dispatch = useDispatch();
  const patientInfo = useSelector(patientDetailsForm);

  const uploadedUrl = (url: string) => {
    setNewFileUrl(url);
  };

  const showFile = (url: string) => {
    setReportUrl(url);
    setShowReport(true);
  };

  useEffect(() => {
    if (patientInfo && patientInfo.panchyog_id) {
      setPanchyogPatientId(patientInfo.panchyog_id);
      setNewFileUrl(undefined);
    }
  }, [patientInfo]);

  return (
    <StyledMainContent>
      {getShowReport && getReportUrl ? (
        <ViewReports open={getShowReport} url={getReportUrl} onClose={() => setShowReport(false)} />
      ) : null}
      <StyledDiv>
        <StyledComponentHeader variant="outlined" position="relative">
          <MuiFunction.Toolbar style={{ minHeight: '35px' }} variant="dense">
            <MuiFunction.IconButton
              aria-label="delete"
              size="medium"
              onClick={() => dispatch(setNavigation(Config.Navigation.DIAGNOSIS))}
            >
              <MuiFunction.ArrowBackOutlinedIcon fontSize="inherit" />
            </MuiFunction.IconButton>
            <MuiFunction.Typography component="h2" style={{ flex: 1 }}>
              Patient Test Reports
            </MuiFunction.Typography>
          </MuiFunction.Toolbar>
        </StyledComponentHeader>
        <StyledPaper variant="outlined">
          <div>
            {panchyogPatientId ? (
              <>
                <Upload panchyog_patient_id={panchyogPatientId} getUploadedUrl={uploadedUrl} />
                <StyledDivider />
                <ReportList
                  panchyog_patient_id={panchyogPatientId}
                  newfileUrl={newfileUrl}
                  showFile={showFile}
                />
              </>
            ) : null}
          </div>
        </StyledPaper>
      </StyledDiv>
    </StyledMainContent>
  );
};

export default Reports;
