import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
// import { RootState } from "../Types";
import auth from './login';
import patientDetails from './patientDetails';
import patientProblem from './patientProblem';
import patientComplaints from './patientComplaints';
import patientHistory from './patientHistory';
import patientDiagnosis from './patientDiagnosis';
import patientTreatmentLine from './patientTreatmentLine';
import patientNotes from './notes';
import navigation from './navigation';
import products from './products';
import orderInfo from './orderInfo';
import patientAddress from './patientAddress';
import patientOrders from './patientOrders';

// resset state
export const RESSET_STATE = 'RESSET_STATE';
//
const createRootReducers = (history: History<any>) => {
  //
  const appReducer = combineReducers({
    navigation,
    auth,
    patientDetails,
    patientProblem,
    patientComplaints,
    patientHistory,
    patientDiagnosis,
    patientTreatmentLine,
    patientNotes,
    products,
    orderInfo,
    patientAddress,
    patientOrders,
    router: connectRouter(history),
  });
  //
  const rootReducer = (state: any, action: any) => {
    if (action.type === RESSET_STATE) state = undefined;
    return appReducer(state, action);
  };
  //
  return rootReducer;
};

export default createRootReducers;
