import React, { useState } from 'react';
import styled from 'styled-components';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { getData } from '../../Services/server';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { addNewPatient, getPatientInfo, setNavigation } from '../../Stores/Actions';
import Constants from '../../Constants';
import { localTokenGet } from '../../Utils';
const StyledSearchBase = styled.div`
  position: relative;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  &:hover: {
    background-color: rgba(255, 255, 255, 0.25);
  }
  margin-right: 16px;
  margin-left: 24px;
`;

//
const StyledSearchIcon = styled.div`
  width: 56px;
  height: 100%;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
  background: #4e4e4e;
  &:active: {
    background-color: rgba(255, 255, 255, 0.25);
  }
`;
//
const StyledInputBase = styled(InputBase)`
  margin-left: 5px;
  padding-right: 65px;
`;

const StyledSuggestionDiv = styled.div`
  z-index: 1;
  position: absolute;
  list-style: none;
  overflow: auto;
  margin: 0;
  padding: 0;
  background: #279de5;
  max-height: 250px;
  width: 277px;
  margin-left: 24px;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
`;

// interfaces
export interface FindPatient {
  panchyog_id: string;
  name: string;
}

export interface FindPatientRes {
  data: FindPatient[];
}

//
export default function SearchBar(): JSX.Element {
  const dispatch = useDispatch();
  // local state
  const [getKeyword, setKeyword] = useState<string>('');
  const [getPatientList, setPatientList] = useState<FindPatient[]>([]);
  //
  const find = async () => {
    if (!getKeyword) return;
    const token = await localTokenGet();
    getData(`${process.env.REACT_APP_API_BASEURL}/api/v1/patient/find/${getKeyword}`, token)
      .then((res: FindPatientRes) => {
        setPatientList(res.data);
      })
      .catch((_err: any) => {
        setPatientList([]);
      });
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    setPatientList([]);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      find();
    }
  };

  const handleClick = (val: string) => {
    console.log('handleClick=>', val);
    setPatientList([]);
    // reset master state
    dispatch(addNewPatient());
    // dispatch action to find patient
    dispatch(getPatientInfo(val));
    dispatch(setNavigation(Constants.Config.Navigation.DIAGNOSIS));
  };

  return (
    <div>
      <StyledSearchBase>
        <StyledInputBase
          value={getKeyword}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
        />
        <StyledSearchIcon onClick={find}>
          <SearchIcon />
        </StyledSearchIcon>
      </StyledSearchBase>
      <StyledSuggestionDiv>
        {getPatientList?.length > 0 ? (
          <List>
            {getPatientList?.map((val, idx) => {
              return (
                <ListItem key={idx} button onClick={() => handleClick(val.panchyog_id)}>
                  <Divider />
                  <ListItemText primary={val.name} secondary={val.panchyog_id} />
                </ListItem>
              );
            })}
          </List>
        ) : null}
      </StyledSuggestionDiv>
    </div>
  );
}
