interface DynamicDictI {
  [key: string]: string[];
}
// Complaints values
export const Complaints: DynamicDictI = {
  Cardiovascular: [
    'other',
    'Burning, hands/feet',
    'Cyanosis, cental',
    'Cyanosis, peripheral',
    'Discoloration, blackish',
    'Discoloration, brown',
    'Discoloration, pale',
    'Discoloration, white',
    'Discoloration, reddish',
    'Fainting',
    'Fatigue',
    'Fever',
    'Hiccups',
    'Odema, generalized',
    'Odema, localized',
    'Odema, non-pitting',
    'On exheration',
    'Pain, chest, sweat',
    'Pain, chest, without sweat',
    'Palpitation',
    'Pricking type, pain',
    'Radiating type, pain',
    'Shortness of breath',
    'Weight, decreased',
    'Weight, increased',
  ],

  Digestive: [
    'other',
    'Acidity',
    'Acidity, heartburn',
    'Acidity, reflux',
    'Acidity, regulation',
    'Appetite, loss',
    'Aruchi',
    'Belching',
    'Belching, sour',
    'Bleeding, per rectum',
    'Fatigue',
    'Fever',
    'Flatulence',
    'Gas passing, difficult',
    'Gas passing, easy',
    'Hematemesis',
    'Motion, bleeding',
    'Motion, bleeding, after',
    'Motion, bleeding, before',
    'Motion, constipation',
    'Motion, constipation, feeling',
    'Motion, loose',
    'Motion, mucus',
    'Motion, occult blood',
    'Motion, painful defecation',
    'Motion, with spasm',
    'Nausea',
    'Pain, abdomen, after meals',
    'Pain, abdomen, around umbilical',
    'Pain, abdomen, before meals',
    'Pain, abdomen, colic type',
    'Pain, abdomen, epigastria region',
    'Pain, abdomen, middle',
    'Pain, abdomen, needle type',
    'Pain, abdomen, pelvic, left',
    'Pain, abdomen, pelvic, right',
    'Pain, abdomen, spasm type',
    'Pain, abdomen, upper',
    'Swallowing, difficult',
    'Ulcers, duodenum',
    'Ulcers, gastric',
    'Ulcers, mouth/tongue',
    'Vomiting',
    'Vomiting, blood',
    'Weakness',
    'Weight, decreased',
    'Weight, increased',
    'Worm infestation',
    'Worm infestation, with rash',
  ],

  Endocrine: [
    'other',
    'Acne/ pimples',
    'Anxiety',
    'Cramps, legs',
    'Crops of boils',
    'Dryness, skin',
    'Emotional, disturbance',
    'Fatigue',
    'Hair loss',
    'Headache',
    'Height, short',
    'Hunger, anorexia',
    'Hunger, polyphagia',
    'Impotency',
    'Indigestion',
    'Infertility',
    'Itching, vulva',
    'Loss of libido',
    'Menses, amenorrhea',
    'Menses, Irregular',
    'Menses, menopause',
    'Menses, oligomenorrhoea',
    'Odema, non-pitting',
    'Odema, pitting',
    'Palpitation',
    'Restlessness',
    'Sensitivity, cold',
    'Sensitivity, heat',
    'Speech, slurring',
    'Sugar, decrease',
    'Sweat, excessive',
    'Sweat, loss',
    'Swelling, thyroid region',
    'Theist, intense',
    'Thirst, intense, nocturia',
    'Tremors',
    'Urine, intense, nocturia',
    'Weakness, body',
    'Weakness, bones',
    'Weight, decreased',
    'Weight, increased',
  ],

  Gynaec: [
    'other',
    'Abortion, habitual',
    'Breast, tenderness',
    'Cervix, thinning of skin',
    'Cyst',
    'Decline in libido',
    'Fullness, lower abdomen',
    'Hair, thinning/detriment',
    'Headache',
    'Hot flushes',
    'Memory problem',
    'Menstruation, amenorrhea',
    'Menstruation, changes',
    'Menstruation, dysmenorrhea',
    'Menstruation, menorrhagia',
    'Mood swings',
    'Mood, concentration problem',
    'Mood, depression/ anxiety',
    'Mood, irritable',
    'Nausea',
    'Night sweats',
    'Osteoporosis',
    'Pain, lower back',
    'Pain, pelvic',
    'Pregnancy, 1st trisem',
    'Pregnancy, 2nd trisem',
    'Pregnancy, 3rd trisem',
    'Sleep disorder',
    'Something coming out',
    'Strain, calf region',
    'Tubal blockage',
    'Urinary problems',
    'Vagina, itchy',
    'Vaginal, discharge, purple',
    'Vaginal, discharge, thin',
    'Vaginal, discharge, white',
    'Vaginal, discharge, yellow',
    'Vaginal, dryness',
    'Vaginal, ulcers',
    'Vomiting',
    'Weakness / fatigue',
    'Weight, decreased',
    'Weight, increased',
  ],

  Misc: [
    'other',
    'Addiction, alcohol',
    'Addiction, coffee',
    'Addiction, drugs',
    'Addiction, sex',
    'Addiction, smoke',
    'Addiction, tea',
    'Addiction, tobacco',
    'Bite, dog',
    'Bite, insect',
    'Bite, snake',
    'Fever',
    'Fever, chills',
    'Fever, evening',
    'Fever, morning',
  ],

  Nervous: [
    'other',
    'Behavior, anger',
    'Behavior, fear',
    'Behavior, irritable',
    'Body ache, generalized',
    'Body ache, localized',
    'Convulsions, everyday',
    'Convulsions, with time interval',
    'Dizziness',
    'Emotional disturbance',
    'Eyes, disturbance movement',
    'Eyes, pain',
    'Fatigue',
    'Fever',
    'Headache',
    'Headache, burning sensation',
    'Headache, burning, face',
    'Headache, decreases at evening',
    'Headache, decreases after vomiting',
    'Headache, frontal region',
    'Headache, increases at night',
    'Headache, increases sometime',
    'Headache, left',
    'Headache, occipital',
    'Headache, photophobia',
    'Headache, right',
    'Headache, throbbing type',
    'Hyperactivity',
    'Involuntary movement',
    'Lethargy',
    'Memory, poor',
    'Muscle, wasting',
    'Nausea',
    'Numbness, generalized',
    'Numbness, localized',
    'Pain, generalized',
    'Pain, localized',
    'Rigidity, generalized',
    'Rigidity, localized',
    'Stress',
    'Tremors',
    'Tremors, hand',
    'Tremors, neck',
    'Twitching',
    'Vertigo',
    'Vomiting',
  ],

  Respiratory: [
    'other',
    'Breathlessness, at usual dust',
    'Breathlessness, cold',
    'Breathlessness, food allergy',
    'Breathlessness, climb',
    'Breathlessness, lying down',
    'Breathlessness, on exertion',
    'Chest, burning',
    'Chest, choked up',
    'Chest, congestion',
    'Chest, sound',
    'Cough, dry',
    'Cough, wet',
    'Epistaxis, nasal bleeding',
    'Nasal, allergy',
    'Nasal, blockage, mucous',
    'Nasal, burning',
    'Nasal, sneezing',
    'Nasal, watery rhinorrhea',
    'Sputum, amount large',
    'Sputum, amount scanty',
    'Sputum, color green',
    'Sputum, color, rusty',
    'Sputum, color, white',
    'Sputum, color, yellowish',
    'Sputum, color, black',
    'Sputum, comes out, difficulty',
    'Sputum, comes out, easy',
    'Sputum, hemoptysis',
    'Sputum, mucous',
    'Sputum, odors foul',
    'Sputum, purulent',
    'Sputum, evening',
    'Sputum, morning',
    'Sputum, watery',
    'Throat, difficult speech',
    'Throat, difficult swallowing',
    'Throat, hoarseness, speech',
    'Throat, irritation',
    'Throat, redness',
    'Throat, sore',
    'Vomiting',
  ],

  Sexual: [
    'other',
    'Desire for same sex',
    'Hirsutism',
    'Immature gonads',
    'Masturbation High',
    'Masturbation Weakness',
    'Penis early ejaculation',
    'Penis no ejaculation',
    'Penis painful ejaculation',
    'Penis poor erection',
    'Penis short',
    'Penis Soft',
    'Penis thin',
    'Prostate enlargement',
    'Semen acidic pH',
    'Semen blood',
    'Semen foul',
    'Semen pus cells',
    'Sex aversion',
    'Sex fear towards',
    'Sex painful',
    'Sperm less count',
    'Sperm no motility',
    'Sperm with blood',
    'Sperm zero count',
    'Testicular swelling',
  ],

  Skeletal: [
    'other',
    'Bone weakness',
    'Fatigue',
    'Fever',
    'Headache',
    'Joint deformity',
    'Joint dislocation',
    'Joint nodes',
    'Joint water accumulation',
    'Joint inflammation',
    'Malaise',
    'Muscles wasting',
    'Muscles weakness',
    'Osteoporosis',
    'Pain ankle',
    'Pain bicchu vedana',
    'Pain cervical',
    'Pain cervical radiating',
    'Pain elbow',
    'Pain fingers',
    'Pain hip',
    'Pain knees',
    'Pain lower back',
    'Pain lumbar',
    'Pain lumber radiating',
    'Pain movement',
    'Pain shoulders',
    'Pain slip disc',
    'Pain thoraisic',
    'Pain toes',
    'Pain wrist',
    'Relieved rest',
    'Sensitivity cold',
    'Sensitivity heat',
    'Sound knee',
    'Stiffness',
    'Stiffness early morning',
    'Stiffness restricted movement',
    'Vertigo',
    'Vomiting',
  ],

  Skin: [
    'other',
    'Discharge foul smell',
    'Discharge thick',
    'Discharge watery',
    'Discharge black',
    'Discharge brown',
    'Discharge pink',
    'Discharge red',
    'Discharge white',
    'Discharge yellow',
    'Drug reaction on skin',
    'Dryness',
    'Eruption acute',
    'Eruptions chronic',
    'Fissure/maceration fingers',
    'Fissure/maceration mouth',
    'Fungal infection generalize',
    'Fungal infection localized',
    'Fungal infection nails',
    'Furuncles boil hair follicle',
    'Furuncles boil skin',
    'Furuncles boil with pus',
    'Gangrene dry',
    'Gangrene wet',
    'Hair baldness',
    'Hair dandruff',
    'Hair fall',
    'Hair premature greying',
    'Hair thinning',
    'Itching',
    'Itching bleeding',
    'Moles',
    'Oiliness',
    'Patches black',
    'Patches brown',
    'Patches pink',
    'Patches white',
    'Pigmentation',
    'Pimple',
    'Rashes generalized',
    'Rashes localized',
    'Rashes Urticarial',
    'Scaling',
    'Scalp itchy',
    'Scar',
    'Scar delivery',
    'Scar disease',
    'Scar injury',
    'Scar surgery',
    'Sunburn',
    'Swelling',
    'Tiny pustules',
    'Ulcers',
    'Warts',
  ],
  Urinary: [
    'other',
    'Bed wetting',
    'Color dusty white',
    'Color reddish',
    'Color yellow',
    'Hypertension',
    'Micturition burning',
    'Micturition foamy',
    'Micturition incontinence',
    'Micturition painful',
    'Odema',
    'Odema feet',
    'Odema under eye',
    'Odour bad',
    'Pain left renal angle',
    'Pain loin',
    'Pain lower back',
    'Pain pelvic',
    'Pain redacting type',
    'Pain right renal angle',
    'Pelvic heaviness',
    'Urine blood',
    'Urine dribbling',
    'Urine mucus',
    'Urine pus cell',
    'Urine residual',
    'Urine retention',
    'Urine semen',
    'Water retention',
  ],
};
// Diagnosis values
export const Diagnoses: DynamicDictI = {
  Cardiovascular: [
    'other',
    'Angina Pectoris',
    'Cardiomegaly',
    'Cardiomyopathy',
    'Congenital Heart Disease',
    'Deep venous Thrombosis',
    'Heart Block',
    'Hrid Rog',
    'Hridya Bhighat',
    'Hrit Shool',
    'Hypercholesterolemia',
    'Hyperlipidemia',
    'Hypertension',
    'Myocardial Infarction',
    'Myocarditis',
    'Paroxysmal Atrial Tachycardia',
    'Paroxysmal Dyspnea',
    'Pulmonary Hypertension',
    'Rheumatic Fever',
    'Sinus Tachycardia',
    'Varicose Veins',
  ],
  Digestive: [
    'other',
    'Aaddhmana',
    'Aatopa',
    'Agni mandhya',
    'Ajirna',
    'Amajirna',
    'Amal pitta',
    'Anemia',
    'Appendicitis',
    'Aruchi',
    'Ascites',
    'Atisar',
    'Chhardi',
    'Cirrhosis of the liver',
    'Coeliac Disease',
    'Cohnanis Disease',
    'Diverculitis',
    'Dyspepsia',
    'Dysphagina',
    'Esophageal ulcers',
    'Fissure',
    'Fistula',
    'Gall stone cholecystities',
    'Gastric ulcer',
    'Gastritis',
    'GERD',
    'Grahanij',
    'Helicobacter pylori',
    'Hepatitis B',
    'Hepatitis C',
    'Hepatomegaly',
    'Hiccup',
    'Inguinal hernia',
    'Jaundice',
    'jalodar',
    'Kamla',
    'Kaphaj grahani',
    'Kershayata',
    'Kumbh kamla',
    'Liver abscess',
    'Liver failure',
    'Pancreatitis',
    'Peptic ulcer',
    'Piles',
    'Pittaj grahani',
    'Pravahika',
    'Rakta pitta',
    'Rassheshajirna',
    'Rectal prolapse',
    'Spleenornegaly',
    'Stomatitis',
    'Thirst',
    'Trishna',
    'Ulcerative colitis',
    'Umblical hernia',
    'Vataj grahani',
    'Vibandh',
    'Vidangdhajirna',
    'Worm infestation',
  ],
  Ears_and_Eyes: [
    'other',
    'Blephritis',
    'Cataract',
    'Conjunctivitis',
    'Ear perforation',
    'Glaucoma',
    'Hypermetropia',
    'Myopia',
    'Night blindness',
    'Retinitis pigmentosa',
    'Sanile deafness',
    'Stye',
    'Suppurative otitis media',
    'Tinnitus',
  ],
  Endocrine: [
    'other',
    'Atikrish',
    'Diabetes insipidus',
    'Diabetes mellitus',
    'Diabetic nephropathy',
    'Diabetic neuropathy',
    'Diabetic retinopathy',
    'Goiter',
    'Height short',
    'Hyperpituarism',
    'Hyperthyroidism',
    'Hypopituarism',
    'Hypothyroidism',
    'Obesity',
    'Peripheral neuropathy',
  ],
  Gynaec: [
    'other',
    'Abortion',
    'Acharana',
    'Aclenomyosis uteri',
    'Amenorrhea',
    'Anemia',
    'Antarmukhi',
    'Arajaska',
    'Aticharana',
    'Atrophic ovary',
    'Bartholinitis',
    'Carcinoma cervical',
    'Carcinoma endometrial',
    'Carcinoma ovarian',
    'Carcinoma uterine tube',
    'Carcinoma vaginal',
    'Carcinoma vulvar',
    'Cervicitis',
    'Contraception',
    'Cystocele',
    'Dysfunctional uterine bleeding',
    'Dysmenorrhea',
    'Ectopic pregnancy',
    'Endometriosis',
    'Fibroid uterus',
    'Habitual abortion',
    'Hymen imperforate',
    'Infertility',
    'Kaphaja',
    'Kardini',
    'Karni yoni vyapad',
    'Krichra maithunta',
    'Mahayoni',
    'Menorrhagia',
    'Missed abortion',
    'Ovary absence',
    'Ovary duplication',
    'Ovary single',
    'Paripluta',
    'Pelvic inflammatory disease',
    'Pittaja',
    'Polyps',
    'Post-menopausal syndrome',
    'Prakcharana',
    'Premenstrual tension',
    'Primary amenorrhea',
    'Primary infertility',
    'Putraghni',
    'Rakta yoni',
    'Rectocele',
    'Sannipatja',
    'Secondary amenorrhea',
    'Secondary infertility',
    'Shanda yoni',
    'Spontaneous abortion',
    'Suchimukhi',
    'Sushka yoni',
    'Sveda pradar',
    'Tumor of the trophoblast',
    'Udavarthini',
    'Upapluta',
    'Urine incontinence',
    'Uterus absence',
    'Uterus duplication',
    'Uterus enlarged',
    'Uterus myomas',
    'Uterus prolapse',
    'Uterus retroflexion',
    'Uterus retroversion',
    'Uterus shrieked',
    'Vaginitis',
    'Vaginitis (Yonishotha)',
    'Vamini',
    'Vataja',
    'Yoni arsh',
  ],
  Misc: [
    'other',
    'Adenoids',
    'Bed wetting',
    'Benign cysts tumors',
    'Cancer',
    'Chikungunya fever',
    'Chronic fatigue syndrome',
    'Dengoo fever',
    'Fibromyalgia',
    'Lipoma',
    'Malaria fever',
    'Malignant cysts tumors',
    'Medaj granthi',
    'Multiple sclerosis',
    'Napkin rash',
    'Typhoid fever',
    'Tonsillitis',
  ],
  Nervous: [
    'other',
    'Aerophobia',
    'Alzheimer disease',
    'Anxiety neurosis',
    'Ardhang vaat',
    'Cerebral palsy',
    'CVA',
    'Delirium',
    'Dementia',
    'Ekangvaat',
    'Epilepsies',
    'Facial paralysis',
    'Fatigue',
    'Gridhrasi vataj',
    'Gridhrasivata kaphaj',
    'Headache',
    'Major depressive illness',
    'Migraine',
    'Musulardystrophy',
    'OCD',
    'Paralysis',
    'Parkinson disease',
    'Sarvang vaat',
    'Schizophrenia',
    'Sciatica',
    'Shankak',
    'Suryavarta',
    'Suryavata vipraya',
    'Trigeminal neuralgia',
    'Unmaad',
    'Vishad',
    'Yoshaparmar',
  ],
  Respiratory: [
    'other',
    'Allergic asthma',
    'Allergic rhinitis',
    'Bhranshthu',
    'Bronchial asthma',
    'Bronchitis',
    'Bronchiectasis',
    'COPD',
    'Coryza',
    'DNA',
    'Emphysema',
    'Hicca rog',
    'Kas rog',
    'Laryngitis',
    'Nasal polyps',
    'Parshav shool',
    'Pharyngitis',
    'Pinas',
    'Pittaj pratishya',
    'Pleurial effusion',
    'Pleurisy',
    'Pneurnonias',
    'Tuberculosis',
    'Pratishya',
    'Sarcoidosis',
    'Senile dementia',
    'Swar bheda',
    'Swas rog',
    'Swathu',
    'Tamak swas',
    'Tuberculosis',
  ],
  Sexual: [
    'other',
    'Azoospermia',
    'Benign prostatic hypertrophy',
    'Dhatu rog',
    'Erectile dysfunction',
    'Fragility',
    'Genital warts',
    'Gonorrhea',
    'Herpes simplex',
    'HIV / AIDS',
    'Non-specific urethritis',
    'Oligospermia',
    'Permaha',
    'Premature ejaculation',
    'Syphilis',
  ],
  Skeletal: [
    'other',
    'Amavata',
    'Ankylosing spondylitis',
    'Arthritis',
    'Avbahuk',
    'Carpal tunnel syndrome',
    'Cervical spondylitis',
    'DDD (degenerative disc disease)',
    'Frozen shoulder',
    'Gout',
    'Hanustambh',
    'Herniated disc',
    'Lumbar spondylitis',
    'Osteoarthritis',
    'Psoriatic arthritis',
    'Rheumatoid arthritis',
    'Sandivata',
    'Systemic lupus erythematous',
    'Tennis elbow',
    'Vata rakta',
    'Vatakantak',
    'Vishvachi',
    'Writer92s dystonia',
  ],
  Skin: [
    'other',
    'Acne vulgaris',
    'Crack heel',
    'Herpes simplex',
    'Herpes zoster',
    'Hypomelanosis',
    'Indralopta',
    'Khalitya',
    'Kashudra kushtha',
    'Lichen planus',
    'Lichen simplex',
    'Maha kushtha',
    'Moles',
    'Moniliasis',
    'Paliyta',
    'Pediculosis',
    'Pemphigus',
    'Pityriasis (Tenia) Vesicular',
    'Pityriasis rosea',
    'Pruritus',
    'Psoriasis',
    'Pyodermas',
    'Ringworm',
    'Scabies',
    'Shwet kushtha',
    'Skin allergy',
    'Urticarial',
    'Vitiligo',
    'Yuvana pidaka',
  ],
  Urinary: [
    'other',
    'Ashmari',
    'Hematuria',
    'Hydronephrosis',
    'Kidney cyst',
    'Mutraghat',
    'Mutrakrisha',
    'Nephrotic syndrome',
    'Pulmonary hypertension',
    'Renal failure',
    'Urinary calculi',
    'Urinary tract infection',
  ],
  Other: ['Other'],
};
// History values
export const History: DynamicDictI = {
  Cardiovascular: [
    'other',
    'Angina Pectoris',
    'Cardiomegaly',
    'Cardiomyopathy',
    'Congenital Heart Disease',
    'Deep venous Thrombosis',
    'Heart Block',
    'Hrid Rog',
    'Hridya Bhighat',
    'Hrit Shool',
    'Hypercholesterolemia',
    'Hyperlipidemia',
    'Hypertension',
    'Myocardial Infarction',
    'Myocarditis',
    'Paroxysmal Atrial Tachycardia',
    'Paroxysmal Dyspnea',
    'Pulmonary Hypertension',
    'Rheumatic Fever',
    'Sinus Tachycardia',
    'Varicose Veins',
  ],
  Digestive: [
    'other',
    'Aaddhmana',
    'Aatopa',
    'Agni mandhya',
    'Ajirna',
    'Amajirna',
    'Amal pitta',
    'Anemia',
    'Appendicitis',
    'Aruchi',
    'Ascites',
    'Atisar',
    'Chhardi',
    'Cirrhosis of the liver',
    'Coeliac Disease',
    'Cohnanis Disease',
    'Diverculitis',
    'Dyspepsia',
    'Dysphagina',
    'Esophageal ulcers',
    'Fissure',
    'Fistula',
    'Gall stone cholecystities',
    'Gastric ulcer',
    'Gastritis',
    'GERD',
    'Grahanij',
    'Helicobacter pylori',
    'Hepatitis B',
    'Hepatitis C',
    'Hepatomegaly',
    'Hiccup',
    'Inguinal hernia',
    'Jaundice',
    'jalodar',
    'Kamla',
    'Kaphaj grahani',
    'Kershayata',
    'Kumbh kamla',
    'Liver abscess',
    'Liver failure',
    'Pancreatitis',
    'Peptic ulcer',
    'Piles',
    'Pittaj grahani',
    'Pravahika',
    'Rakta pitta',
    'Rassheshajirna',
    'Rectal prolapse',
    'Spleenornegaly',
    'Stomatitis',
    'Thirst',
    'Trishna',
    'Ulcerative colitis',
    'Umblical hernia',
    'Vataj grahani',
    'Vibandh',
    'Vidangdhajirna',
    'Worm infestation',
  ],
  Ears_and_Eyes: [
    'other',
    'Blephritis',
    'Cataract',
    'Conjunctivitis',
    'Ear perforation',
    'Glaucoma',
    'Hypermetropia',
    'Myopia',
    'Night blindness',
    'Retinitis pigmentosa',
    'Sanile deafness',
    'Stye',
    'Suppurative otitis media',
    'Tinnitus',
  ],
  Endocrine: [
    'other',
    'Atikrish',
    'Diabetes insipidus',
    'Diabetes mellitus',
    'Diabetic nephropathy',
    'Diabetic neuropathy',
    'Diabetic retinopathy',
    'Goiter',
    'Height short',
    'Hyperpituarism',
    'Hyperthyroidism',
    'Hypopituarism',
    'Hypothyroidism',
    'Obesity',
    'Peripheral neuropathy',
  ],
  Gynaec: [
    'other',
    'Abortion',
    'Acharana',
    'Aclenomyosis uteri',
    'Amenorrhea',
    'Anemia',
    'Antarmukhi',
    'Arajaska',
    'Aticharana',
    'Atrophic ovary',
    'Bartholinitis',
    'Carcinoma cervical',
    'Carcinoma endometrial',
    'Carcinoma ovarian',
    'Carcinoma uterine tube',
    'Carcinoma vaginal',
    'Carcinoma vulvar',
    'Cervicitis',
    'Contraception',
    'Cystocele',
    'Dysfunctional uterine bleeding',
    'Dysmenorrhea',
    'Ectopic pregnancy',
    'Endometriosis',
    'Fibroid uterus',
    'Habitual abortion',
    'Hymen imperforate',
    'Infertility',
    'Kaphaja',
    'Kardini',
    'Karni yoni vyapad',
    'Krichra maithunta',
    'Mahayoni',
    'Menorrhagia',
    'Missed abortion',
    'Ovary absence',
    'Ovary duplication',
    'Ovary single',
    'Paripluta',
    'Pelvic inflammatory disease',
    'Pittaja',
    'Polyps',
    'Post-menopausal syndrome',
    'Prakcharana',
    'Premenstrual tension',
    'Primary amenorrhea',
    'Primary infertility',
    'Putraghni',
    'Rakta yoni',
    'Rectocele',
    'Sannipatja',
    'Secondary amenorrhea',
    'Secondary infertility',
    'Shanda yoni',
    'Spontaneous abortion',
    'Suchimukhi',
    'Sushka yoni',
    'Sveda pradar',
    'Tumor of the trophoblast',
    'Udavarthini',
    'Upapluta',
    'Urine incontinence',
    'Uterus absence',
    'Uterus duplication',
    'Uterus enlarged',
    'Uterus myomas',
    'Uterus prolapse',
    'Uterus retroflexion',
    'Uterus retroversion',
    'Uterus shrieked',
    'Vaginitis',
    'Vaginitis (Yonishotha)',
    'Vamini',
    'Vataja',
    'Yoni arsh',
  ],
  Misc: [
    'other',
    'Adenoids',
    'Bed wetting',
    'Benign cysts tumors',
    'Cancer',
    'Chikungunya fever',
    'Chronic fatigue syndrome',
    'Dengoo fever',
    'Fibromyalgia',
    'Lipoma',
    'Malaria fever',
    'Malignant cysts tumors',
    'Medaj granthi',
    'Multiple sclerosis',
    'Napkin rash',
    'Typhoid fever',
    'Tonsillitis',
  ],
  Nervous: [
    'other',
    'Aerophobia',
    'Alzheimer disease',
    'Anxiety neurosis',
    'Ardhang vaat',
    'Cerebral palsy',
    'CVA',
    'Delirium',
    'Dementia',
    'Ekangvaat',
    'Epilepsies',
    'Facial paralysis',
    'Fatigue',
    'Gridhrasi vataj',
    'Gridhrasivata kaphaj',
    'Headache',
    'Major depressive illness',
    'Migraine',
    'Musulardystrophy',
    'OCD',
    'Paralysis',
    'Parkinson disease',
    'Sarvang vaat',
    'Schizophrenia',
    'Sciatica',
    'Shankak',
    'Suryavarta',
    'Suryavata vipraya',
    'Trigeminal neuralgia',
    'Unmaad',
    'Vishad',
    'Yoshaparmar',
  ],
  Respiratory: [
    'other',
    'Allergic asthma',
    'Allergic rhinitis',
    'Bhranshthu',
    'Bronchial asthma',
    'Bronchitis',
    'Bronchiectasis',
    'COPD',
    'Coryza',
    'DNA',
    'Emphysema',
    'Hicca rog',
    'Kas rog',
    'Laryngitis',
    'Nasal polyps',
    'Parshav shool',
    'Pharyngitis',
    'Pinas',
    'Pittaj pratishya',
    'Pleurial effusion',
    'Pleurisy',
    'Pneurnonias',
    'Tuberculosis',
    'Pratishya',
    'Sarcoidosis',
    'Senile dementia',
    'Swar bheda',
    'Swas rog',
    'Swathu',
    'Tamak swas',
    'Tuberculosis',
  ],
  Sexual: [
    'other',
    'Azoospermia',
    'Benign prostatic hypertrophy',
    'Dhatu rog',
    'Erectile dysfunction',
    'Fragility',
    'Genital warts',
    'Gonorrhea',
    'Herpes simplex',
    'HIV / AIDS',
    'Non-specific urethritis',
    'Oligospermia',
    'Permaha',
    'Premature ejaculation',
    'Syphilis',
  ],
  Skeletal: [
    'other',
    'Amavata',
    'Ankylosing spondylitis',
    'Arthritis',
    'Avbahuk',
    'Carpal tunnel syndrome',
    'Cervical spondylitis',
    'DDD (degenerative disc disease)',
    'Frozen shoulder',
    'Gout',
    'Hanustambh',
    'Herniated disc',
    'Lumbar spondylitis',
    'Osteoarthritis',
    'Psoriatic arthritis',
    'Rheumatoid arthritis',
    'Sandivata',
    'Systemic lupus erythematous',
    'Tennis elbow',
    'Vata rakta',
    'Vatakantak',
    'Vishvachi',
    'Writer92s dystonia',
  ],
  Skin: [
    'other',
    'Acne vulgaris',
    'Crack heel',
    'Herpes simplex',
    'Herpes zoster',
    'Hypomelanosis',
    'Indralopta',
    'Khalitya',
    'Kashudra kushtha',
    'Lichen planus',
    'Lichen simplex',
    'Maha kushtha',
    'Moles',
    'Moniliasis',
    'Paliyta',
    'Pediculosis',
    'Pemphigus',
    'Pityriasis (Tenia) Vesicular',
    'Pityriasis rosea',
    'Pruritus',
    'Psoriasis',
    'Pyodermas',
    'Ringworm',
    'Scabies',
    'Shwet kushtha',
    'Skin allergy',
    'Urticarial',
    'Vitiligo',
    'Yuvana pidaka',
  ],
  Urinary: [
    'other',
    'Ashmari',
    'Hematuria',
    'Hydronephrosis',
    'Kidney cyst',
    'Mutraghat',
    'Mutrakrisha',
    'Nephrotic syndrome',
    'Pulmonary hypertension',
    'Renal failure',
    'Urinary calculi',
    'Urinary tract infection',
  ],
};
