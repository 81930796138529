import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import apiMiddleware from './Middleware/api';
import createRootReducer from './Reducers';
import { loadFromSessionStorage } from './encyptStore';
const persistedState = loadFromSessionStorage();
//
export const history = createBrowserHistory();
//
const composedEnhancers = () => {
  let middleware = [thunk, apiMiddleware, createLogger(), routerMiddleware(history)];
  if (process.env.NODE_ENV === 'production') {
    middleware = [thunk, apiMiddleware, routerMiddleware(history)];
  }
  return applyMiddleware(...middleware);
};
//
const store = createStore(
  createRootReducer(history),
  persistedState ? persistedState : {},
  composedEnhancers(),
);
//
export default store;
