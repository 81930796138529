import React, { useEffect, useState } from 'react';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
// Custom Components
import { MuiFunction, styled } from '../../Css';
import _ from 'lodash';

const StyledThumbDiv = styled.div`
  width: 150px;
  min-height: 150px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #c1c1c1;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
`;

export interface Props {
  panchyog_patient_id: string;
  newfileUrl: string | undefined;
  showFile: (url: string) => void;
}

const ReportList = (props: Props): JSX.Element => {
  const [file, setFile] = useState<string[]>([]);

  const getFiles = () => {
    const storage = getStorage();
    // Create a reference under which you want to list
    const listRef = ref(storage, props.panchyog_patient_id);
    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.items.forEach(async (itemRef) => {
          try {
            const url = await getDownloadURL(ref(storage, itemRef.fullPath));
            setFile((prevState) => {
              return [...prevState, url];
            });
          } catch (error) {
            console.log('ReportList Error=>', error);
          }
        });
      })
      .catch((error) => {
        console.log('error=>', error);
      });
  };

  useEffect(() => {
    getFiles();
  }, [props.panchyog_patient_id]);

  useEffect(() => {
    if (props && props.newfileUrl) {
      setFile((prevState) => {
        return props.newfileUrl ? [...prevState, props.newfileUrl] : prevState;
      });
    }
  }, [props.newfileUrl]);

  return (
    <MuiFunction.Grid container direction="row" spacing={3}>
      {_.chunk(file, 4).map((fileList, index) => {
        return (
          <MuiFunction.Grid item xs key={index}>
            <div style={{ display: 'flex' }}>
              {fileList.map((item, indx) => {
                return (
                  <StyledThumbDiv key={`report_pdf_${indx}`} onClick={()=>props.showFile(item)}>
                    {item.includes('.pdf') ? (
                      <MuiFunction.Typography>{'View PDF file'}</MuiFunction.Typography>
                    ) : (
                      <img alt={'alt'} src={item} width={150} />
                    )}
                  </StyledThumbDiv>
                );
              })}
            </div>
          </MuiFunction.Grid>
        );
      })}
    </MuiFunction.Grid>
  );
};

export default ReportList;
