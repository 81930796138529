import React from 'react';
import styled from 'styled-components';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import SearchBar from './search';
import CreateNew from './add';
import CreateOrder from './order';
import ReportButton from './report';
import CreateNewCallback from './patientCallback';
//
const StyledAppBar = styled(AppBar)`
  width: calc(100% - 370px) !important;
  margin-left: 50px !important;
  margin-right: 320px !important;
  background-color: #028ce1 !important;
`;

//
export default function Header(): JSX.Element {
  return (
    <StyledAppBar position="fixed">
      <Toolbar variant="dense">
        <Typography variant="h6" color="inherit">
          Niramaya
        </Typography>
        <SearchBar />
        <CreateNew />
        <CreateOrder />
        <ReportButton />
        <CreateNewCallback />
      </Toolbar>
    </StyledAppBar>
  );
}
