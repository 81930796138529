import React from 'react';
import { useDispatch } from 'react-redux';
import { setNavigation } from '../../Stores/Actions';
import Constants from '../../Constants';
import { MuiFunction } from '../../Css';

export default function ReportButton(): JSX.Element {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setNavigation(Constants.Config.Navigation.TEST_REPORTS));
  };
  return (
    <div style={{ flex: 1 }}>
      <MuiFunction.Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleClick}
        startIcon={<MuiFunction.FileCopyRoundedIcon />}
      >
        Reports
      </MuiFunction.Button>
    </div>
  );
}
