import styledComponent from 'styled-components';
import {
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
  AssessmentOutlined as AssessmentOutlinedIcon,
  ShoppingCartOutlined as ShoppingCartOutlinedIcon,
  GroupOutlined as GroupOutlinedIcon,
  ExitToAppOutlined as ExitToAppOutlinedIcon,
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  FileCopyRounded as FileCopyRoundedIcon,
  AttachFileRounded as AttachFileRoundedIcon,
  Cancel as CancelIcon,
  Search as SearchIcon,
  CallSharp as CallIcon
} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';

import {
  Divider,
  Grid,
  IconButton,
  Dialog,
  Typography,
  MenuItem,
  Button,
  TextField,
  Drawer,
  List,
  ListItem,
  Paper,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  InputBase,
  FormControl,
} from '@material-ui/core';

//  styled
export const styled = styledComponent;

// Custom export
export const MuiFunction = {
  // Core
  Button,
  TextField,
  Divider,
  Grid,
  IconButton,
  Dialog,
  Typography,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiDialogContentText,
  MenuItem,
  Drawer,
  List,
  ListItem,
  Pagination,
  Paper,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  FormControl,
  // Icons
  CloseIcon,
  ArrowBackIcon,
  AssessmentOutlinedIcon,
  ShoppingCartOutlinedIcon,
  GroupOutlinedIcon,
  ExitToAppOutlinedIcon,
  ArrowBackOutlinedIcon,
  FileCopyRoundedIcon,
  AttachFileRoundedIcon,
  CancelIcon,
  SearchIcon,
  CallIcon,
};

// Styled Components
//
export const StyledMainContent = styled.div`
  width: calc(100% - 380px) !important;
  margin-left: 70px !important;
  margin-right: 320px !important;
  background-color: #f5f5f5 !important;
  margin-top: 52px !important;
  height: calc(100vh - 60px) !important;
  overflow-y: auto !important;
  padding: 10px !important;
`;

export const SaveButton = styled(Button)`
  padding: 2px 5px !important;
  box-shadow: none !important;
  background-color: #607d8b !important;
  color: #fff !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
  letter-spacing: 0.9px !important;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 100% !important;
  & > div > input {
    padding: 20px 12px 5px !important;
  }
  & > div > div {
    padding: 20px 12px 5px !important;
  }
  & > div > input[type=number]{
    '-moz-appearance': 'textfield';
  }
  & > div > input[type=number]::-webkit-outer-spin-button {
      '-webkit-appearance': 'none';
      margin: 0;
  }
  & > div > input[type=number]::-webkit-inner-spin-button {
      '-webkit-appearance': 'none';
      margin: 0;
  }
`;

export const MarginDiv = styled.div`
  margin: 100px 0;
`;
export const CancelIconDiv = styled.div`
  cursor: pointer;
  color: #f50057;
  text-align: center;
  & > svg {
    font-size: 20px;
  }
`;

export const TableSearchDiv = styled.div`
  margin: 5px 0 5px 0 !important;
  border-radius: 4px;
  display: inline-flex;
  background-color: rgba(255, 255, 255, 0.15);
  &:hover: {
    background-color: rgba(255, 255, 255, 0.25);
  }
`;

export const TableSearchInputBase = styled(InputBase)`
  margin-left: 5px;
  border: 1px solid #737373;
  padding: 0 5px;
  border-radius: 4px 0 0 4px;
`;

export const TableSearchIconDiv = styled.div`
  width: 55px;
  position: relative;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  right: 0;
  top: 0;
  border-radius: 0 4px 4px 0;
  background: #4d4d4d;
    &:active: {
      background-color: #3a3a3a;
    }
`;

