import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, TextField } from '@material-ui/core';
import _ from 'lodash';
import { ProductsData } from '../../Stores/Types/products';
import { PatientAddressData } from '../../Stores/Types/patientAddress';
import {
  Divider,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import Address from '../Address';
import Constants from '../../Constants';
import { useSnackbar } from 'notistack';

const StyledDialogContent = styled(MuiDialogContent)`
  padding: 10px !important;
`;
const StyledDialogActions = styled(MuiDialogActions)`
  margin: 0px !important;
  padding: 10px !important;
`;

const StyledMuiDialogTitle = styled(MuiDialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledDialog = styled(Dialog)`
  & > div > div {
    position: absolute;
    top: 50px;
    bottom: 30px;
  }
`;
const StyledDivider = styled(Divider)`
  margin: 10px 0 10px 0 !important;
`;
const StyledGrid = styled(Grid)`
  width: 100% !important;
`;
const StyledTextField = styled(TextField)`
  z-index: 0;
  & > div > input {
    padding: 5px !important;
  }
`;

interface ProductItemI {
  product_id: number;
  name: string;
  unit: number;
  unit_price: number;
  discount: number;
  payable_amount: number;
  hsn_code: string;
}

interface AyurvedicMixerI {
  product_id: number;
  name: string;
  quantity: number;
}

interface ProductsDataI extends ProductsData {
  qty?: number;
  price?: number;
  gm?: number;
}

export interface DialogTitleI {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
export interface FinalOrderData {
  orderData: ProductsDataI[];
  ayurvedicAushadhiAmount: number;
  selectedAddress: PatientAddressData;
  days: number;
  totalAmount: number;
  payableAmount: number;
  deliveryType: string;
}

export interface ConfirmOrderDialogI {
  openDialog: boolean;
  orderData: ProductsDataI[];
  ayurvedicAushadhiAmount: string;
  patient_id: number;
  user_id: number;
  onCloseDialog: () => void;
  handleFinalOrder: (finalOrderData: FinalOrderData) => void;
}

const DialogTitle = (props: DialogTitleI): JSX.Element => {
  return (
    <StyledMuiDialogTitle disableTypography>
      <Typography variant="h6">{props.children}</Typography>
      {props.onClose ? (
        <IconButton aria-label="close" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledMuiDialogTitle>
  );
};

const ConfirmOrderDialog = (props: ConfirmOrderDialogI): JSX.Element => {
  const { Config } = Constants;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [daysValue, setDaysValue] = useState<number>(0);
  const [getOrderItems, setOrderItems] = useState<ProductsDataI[]>([]);
  const [getAushadhiAmount, setAushadhiAmount] = useState<number>(0);
  const [getTotalProductAmount, setTotalProductAmount] = useState<number>(0);
  const [getTotalAmount, setTotalAmount] = useState<number>(0);
  const [getSelectedAddress, setSelectedAddress] = useState<PatientAddressData | undefined>(
    undefined,
  );
  const [getDeliveryType, setDeliveryType] = useState<string | null>(null);

  const handleClose = () => {
    return props.onCloseDialog();
  };

  const handleDaysChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setDaysValue(parseInt(event.target.value));
    }
  };

  // save and close
  const handleSave = () => {
    if (getSelectedAddress && daysValue && getDeliveryType) {
      return props.handleFinalOrder({
        orderData: getOrderItems,
        ayurvedicAushadhiAmount: getAushadhiAmount,
        selectedAddress: getSelectedAddress,
        days: daysValue,
        totalAmount: getTotalAmount,
        payableAmount: getTotalAmount,
        deliveryType: getDeliveryType,
      });
    } else {
      //  select or add address
      if (!getDeliveryType) {
        return enqueueSnackbar(`Please select delivery type [Courier or Store Pickup]`, {
          variant: 'error',
        });
      }
      if (!getSelectedAddress) {
        return enqueueSnackbar(`Please add an address.`, {
          variant: 'error',
        });
      }
    }
  };
  const selectedAddress = (address: PatientAddressData) => {
    setSelectedAddress(address);
  };

  const handleChange = (key: string, productId: number) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    try {
      console.log(key, productId, event.target.value);
      const val = event.target.value.trim();
      const value = !val || val === '' ? 0 : parseInt(val);
      setOrderItems((selectedItems) => {
        const newItems = selectedItems.map((item) => {
          if (item.product_id === productId) {
            if (key === 'gm') item['gm'] = value;
            if (key === 'qty') item['qty'] = value;
            if (key === 'price') item['price'] = value;
          }
          return item;
        });
        // return
        return newItems;
      });
    } catch (error) {
      // show error : 'Enter a valid input'
    }
  };

  const setOrderItemsToState = (days: number) => {
    const orderData = _.cloneDeep(props.orderData); // Never remove
    const aushadhiAmount = props.ayurvedicAushadhiAmount
      ? parseInt(props.ayurvedicAushadhiAmount)
      : 0;
    setOrderItems([]);
    switch (days) {
      case 15:
        _.forEach(orderData, (item) => {
          if (item.qty && item.type === Config.ProductType.MEDICINE) {
            item.qty = Math.ceil(item.qty / 2);
            setOrderItems((prevState) => [...prevState, item]);
          } else if (item.gm  && item.type === Config.ProductType.MEDICINE) {
            item.gm = Math.ceil(item.gm / 2);
            setOrderItems((prevState) => [...prevState, item]);
          } else {
            setOrderItems((prevState) => [...prevState, item]);
          }
        });
        setAushadhiAmount(Math.ceil(aushadhiAmount / 2));
        return;
      case 30:
        setOrderItems(orderData);
        setAushadhiAmount(aushadhiAmount);
        return;
      case 45:
        _.forEach(orderData, (item) => {
          if (item.qty && item.type === Config.ProductType.MEDICINE) {
            item.qty = Math.ceil(item.qty * 1.5);
            setOrderItems((prevState) => [...prevState, item]);
          } else if (item.gm && item.type === Config.ProductType.MEDICINE) {
            item.gm = Math.ceil(item.gm * 1.5);
            setOrderItems((prevState) => [...prevState, item]);
          } else {
            setOrderItems((prevState) => [...prevState, item]);
          }
        });
        setAushadhiAmount(Math.ceil(aushadhiAmount * 1.5));
        return;
      case 60:
        _.forEach(orderData, (item) => {
          if (item.qty && item.type === Config.ProductType.MEDICINE) {
            item.qty = Math.ceil(item.qty * 2);
            setOrderItems((prevState) => [...prevState, item]);
          } else if (item.gm && item.type === Config.ProductType.MEDICINE) {
            item.gm = Math.ceil(item.gm * 2);
            setOrderItems((prevState) => [...prevState, item]);
          } else {
            setOrderItems((prevState) => [...prevState, item]);
          }
        });
        setAushadhiAmount(Math.ceil(aushadhiAmount * 2));
        return;
      case 90:
        _.forEach(orderData, (item) => {
          if (item.qty && item.type === Config.ProductType.MEDICINE) {
            item.qty = Math.ceil(item.qty * 3);
            setOrderItems((prevState) => [...prevState, item]);
          } else if (item.gm && item.type === Config.ProductType.MEDICINE) {
            item.gm = Math.ceil(item.gm * 3);
            setOrderItems((prevState) => [...prevState, item]);
          } else {
            setOrderItems((prevState) => [...prevState, item]);
          }
        });
        setAushadhiAmount(Math.ceil(aushadhiAmount * 3));
        return;
      default:
        return;
    }
  };

  useEffect(() => {
    setOpen(props.openDialog);
    //  set default days
    if (props.openDialog) {
      setDaysValue(30);
    }
  }, [props.openDialog]);
  // set order item
  useEffect(() => {
    setOrderItemsToState(daysValue);
  }, [daysValue]);
  // set amount
  useEffect(() => {
    let productsAmount = 0;
    _.forEach(getOrderItems, (item) => {
      if (item.type === Config.ProductType.PRODUCT && item.price && item.qty) {
        productsAmount = productsAmount + item.price * item.qty;
      }
    });
    setTotalProductAmount(productsAmount);
  }, [getOrderItems]);
  // set final amount
  useEffect(() => {
    setTotalAmount(getTotalProductAmount + getAushadhiAmount);
  }, [getTotalProductAmount, getAushadhiAmount]);

  return (
    <StyledDialog
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        Complete Order
      </DialogTitle>
      <StyledDialogContent dividers>
        <Grid container direction="row" spacing={3}>
          <Grid item md>
            <FormControl component="fieldset">
              <Typography variant="h6">{'Select Days'}</Typography>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={daysValue}
                onChange={handleDaysChange}
              >
                {[15, 30, 45, 60, 90].map((item) => {
                  return (
                    <FormControlLabel
                      key={`days_${item}`}
                      value={item}
                      control={<Radio color="primary"/>}
                      label={`${item} Days`}
                      labelPlacement="top"
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <StyledDivider />
        <Grid container direction="row" spacing={3}>
          <StyledGrid item md={12}>
            <Typography variant="h6">{'Ayurvedic Medicine'}</Typography>
            <StyledDivider />
            {getOrderItems.map((item) => {
              if (item.type === Config.ProductType.MEDICINE && item.gm) {
                return (
                  <React.Fragment key={`odr-ch-${item.product_id}`}>
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        {item.name}
                      </Grid>
                      <Grid item xs={3}>
                        <StyledTextField
                          label="Gm"
                          id="outlined-start-adornment"
                          variant="outlined"
                          value={item?.gm}
                          disabled={true}
                          // onChange={handleChange('gm', item.product_id)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <StyledDivider />
                  </React.Fragment>
                );
              }
            })}
            {getOrderItems.map((item, index) => {
              if (
                item.category === Config.ProductCategory.TABLET &&
                item.type === Config.ProductType.MEDICINE
              ) {
                return (
                  <React.Fragment key={`odr-tb-${index}`}>
                    <Grid container spacing={1}>
                      <Grid item xs={9}>
                        {item.name}
                      </Grid>
                      <Grid item xs={3}>
                        <StyledTextField
                          label="Qty"
                          id="outlined-start-adornment"
                          variant="outlined"
                          value={item?.qty}
                          disabled={true}
                          // onChange={handleChange('qty', item.product_id)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <StyledDivider />
                  </React.Fragment>
                );
              }
            })}
          </StyledGrid>
        </Grid>
        <StyledDivider />
        <Grid container direction="row" spacing={3}>
          <StyledGrid item md>
            <Typography variant="h6">{'Products'}</Typography>
            <StyledDivider />
            {getOrderItems.map((item, index) => {
              if (item.type === Config.ProductType.PRODUCT) {
                return (
                  <React.Fragment key={`odr-pd-${index}`}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        {item.name}
                      </Grid>
                      <Grid item xs={3}>
                        <StyledTextField
                          label="MRP"
                          id="outlined-start-adornment"
                          variant="outlined"
                          value={item?.price}
                          disabled={true}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <StyledTextField
                          label="Qty"
                          id="outlined-start-adornment"
                          variant="outlined"
                          value={item?.qty}
                          onChange={handleChange('qty', item.product_id)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <StyledDivider />
                  </React.Fragment>
                );
              }
            })}
          </StyledGrid>
        </Grid>
        <StyledDivider />
        <Grid container direction="row" spacing={3}>
          <StyledGrid item md>
            <Typography variant="h6">{'Amount (₹)'}</Typography>
            <StyledDivider />
            <Grid container spacing={1}>
              <Grid item xs={9}>
                {'Amount for Ayurvedic Aushadhi'}
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Amount (₹)"
                  id="outlined-start-adornment"
                  variant="outlined"
                  value={getAushadhiAmount}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <StyledDivider />
            <Grid container spacing={1}>
              <Grid item xs={9}>
                {'Amount for Products'}
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Amount (₹)"
                  id="outlined-start-adornment"
                  variant="outlined"
                  value={getTotalProductAmount}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
            <StyledDivider />
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <strong>{'Total Payable Amount'}</strong>
              </Grid>
              <Grid item xs={3}>
                <StyledTextField
                  label="Amount (₹)"
                  id="outlined-start-adornment"
                  variant="outlined"
                  value={getTotalAmount}
                  disabled={true}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </StyledGrid>
        </Grid>
        <StyledDivider />
        <Grid container direction="row" spacing={3}>
          <StyledGrid item md>
            <Typography variant="h6">{'Select Delivery Type'}</Typography>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value={Config.DeliveryType.COURIER}
                  control={<Checkbox color="primary" />}
                  label="Courier"
                  labelPlacement="end"
                  checked={getDeliveryType === Config.DeliveryType.COURIER}
                  onChange={() => setDeliveryType(Config.DeliveryType.COURIER)}
                />
                <FormControlLabel
                  value={Config.DeliveryType.PICKED_UP}
                  control={<Checkbox color="primary" />}
                  label="Store Pickup"
                  labelPlacement="end"
                  checked={getDeliveryType === Config.DeliveryType.PICKED_UP}
                  onChange={() => setDeliveryType(Config.DeliveryType.PICKED_UP)}
                />
              </FormGroup>
            </FormControl>
          </StyledGrid>
        </Grid>
        <StyledDivider />
        <Grid container direction="row" spacing={3}>
          <StyledGrid item md>
            <Typography variant="h6">{'Address'}</Typography>
            <StyledDivider />
            <Address
              patient_id={props.patient_id}
              user_id={props.user_id}
              selectedAddress={selectedAddress}
            />
          </StyledGrid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleSave} variant="contained" color="secondary">
          Create Order
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmOrderDialog;
